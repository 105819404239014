import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import TableView from "../../components/tableView";
import {getFirestampDateString} from "../../utils";
import {financeApi} from "../../config";
import {BanknotesIcon} from '@heroicons/react/24/outline'
import {useTranslation} from "react-i18next";
import {clientSelector, realActiveCidSelector} from "../../store/client";
import CreateNewFinancingRequestOverviewDialog from "../../components/createNewFinancingRequestOverviewDialog";
import {INVOICE_TYPE, PROMISSORY_NOTE_INVOICE_STATUS} from "../../consts";
import {fetchReceived, payableListHasMoreSelector, payableListSelector} from "../../store/payables";
import {TableFilterView, useTableFilters} from "../../components/tableViewHelpers";


const defaultSort = ["date", "desc"]

export default function PayablesTable({previewOnly}) {
    const {t} = useTranslation()

    const dispatch = useDispatch()
    const activeOrganizationId = useSelector(realActiveCidSelector)
    const organization = useSelector(clientSelector(activeOrganizationId))
    const ordered = useSelector(payableListSelector)
    const hasMore = useSelector(payableListHasMoreSelector)
    const [loading, setLoading] = useState(true)
    const [params, setParams] = useState(null)
    const [selectedInvoices, setSelectedInvoices] = useState([])
    const [isExtendDialogOpen, setIsExtendDialogOpen] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const action = {
            label: t('earlyPayment'),
            icon: BanknotesIcon,
        },
        action2 = {
            label: "Extend",
            icon: BanknotesIcon,
        },
        //eligibleForPN = d => d.status === PROMISSORY_NOTE_INVOICE_STATUS.APPROVED,
        tableSelectorFilters = [
            {
                name: "Select eligible for financing",
                filter: eligibleForExtension
            },
        ]

    const rowActions = [
        /*{
            ...action,
            check: eligibleForPN,
            onClick: (item) => {
                setSelectedInvoices([item])
                setIsCreateDialogOpen(true)
            }
        },*/

    ]

    const tableFilters = useTableFilters([
        {
            type: "radio",
            id: "status",
            label: t('status'),
            options: [
                {label: t('all'), value: null},
                {label: t('APPROVED'), value: "APPROVED"},
                {label: t('PAID'), value: "PAID"},
            ],
        }
    ])

    const tableActions = [
        /*{
            ...action,
            check: dArr => {
                return dArr && dArr.length > 0 && dArr.every(eligibleForPN)
            },
            onClick: items => {
                setSelectedInvoices(items)
                setIsCreateDialogOpen(true)
            }
        },*/
        {
            ...action2,
            check: dArr => {
                return dArr && dArr.length > 0 && dArr.every(eligibleForExtension)
            },
            onClick: extendInvoices
        },
    ]

    const tableColumns = [
        {v: d => d.number, label: "#", sort: "number"},
        {
            v: d => d.issuer?.name,
            label: t('partner'),
        },
        {
            v: d => getFirestampDateString(d.date),
            label: t('issueDate'),
            sort: "date"
        },
        {
            v: d => getFirestampDateString(d.dueDate),
            label: t('dueDate'),
            sort: "dueDate"
        },
        {
            v: d => d.extendedPaymentDate ? getFirestampDateString(d.extendedPaymentDate) : eligibleForExtension(d) ?
                <span onClick={extendPaymentDateHandler(d)}
                      className="text-primary-600 hover:text-primary-900 font-medium cursor-pointer text-sm font-medium">Extend</span> : "-",
            label: t('extendedPaymentDate'),
            sort: "extendedPaymentDate"
        },
        {
            v: d => <span className="whitespace-nowrap">{d.amount?.formatted}</span>,
            label: t('amount'),
            sort: "amount.value"
        },
        {v: d => t(d.status), label: t('status')},
    ]

    useEffect(() => {
        loading && setLoading(false)
    }, [ordered])

    function eligibleForExtension({status, extendedPaymentDate}) {
        switch (status) {
            case PROMISSORY_NOTE_INVOICE_STATUS.DRAFT:
            case PROMISSORY_NOTE_INVOICE_STATUS.ADDED:
            case PROMISSORY_NOTE_INVOICE_STATUS.PENDING_APPROVAL:
            case PROMISSORY_NOTE_INVOICE_STATUS.APPROVED:
            case PROMISSORY_NOTE_INVOICE_STATUS.FINANCING_IN_PROGRESS:
            case PROMISSORY_NOTE_INVOICE_STATUS.FINANCED:
                return !extendedPaymentDate && !!organization?.features?.FINANCE_REVERSE_FACTORING_CREATE
        }
        return false
    }

    function extendPaymentDateHandler(d) {
        return function onClick() {
            extendInvoices([d])
        }
    }

    function extendInvoices(invoices) {
        setSelectedInvoices(invoices)
        setIsExtendDialogOpen(true)
    }

    function fetchData(_params) {
        _params = {...params, ..._params}
        setParams(_params)
        setLoading(true)
        dispatch(fetchReceived(_params))
    }

    function onSearch(item) {
        fetchData(item || null)
    }

    function onLoadMore() {
        fetchData({offset: (params.offset || 0) + 10})
    }

    function onSubmit(extendedPaymentDate) {
        setIsSubmitting(true)
        financeApi.post("/promissory-notes/batch", {
            type: "RF",
            organizationId: activeOrganizationId,
            invoiceIds: selectedInvoices.map(inv => inv.id),
            extendedPaymentDate: new Date(extendedPaymentDate)
        })
            .then(res => {
                setIsSubmitting(false)
                setIsExtendDialogOpen(false)
                fetchData(params)
                //history.push(`/u/receivables`)
            })
            .catch(error => {
                setIsSubmitting(false)
                alert("Error: " + (error?.response?.data?.message || "Unknown error"))
                console.log("error", error)
            })
    }

    return (
        <>
            {!previewOnly && (
                <div className="mt-8 flex justify-between items-center">
                    <h2 className=" text-lg leading-6 font-medium text-gray-900">
                        {t('invoices')}
                    </h2>
                    {tableFilters && (
                        <div>
                            <TableFilterView tableFilters={tableFilters}/>
                        </div>
                    )}
                </div>
            )}

            <div className="mt-8 bg-white sm:rounded-xl border border-gray-200 flex flex-col">
                <TableView id="receivables" columns={tableColumns} tableActions={tableActions}
                           data={previewOnly ? ordered.slice(0, 3) : ordered}
                           hasMore={hasMore} defaultSort={defaultSort} tableFilters={tableFilters}
                           onLoadMore={onLoadMore} onSearch={onSearch} showNavigation={!previewOnly}
                           loading={loading} rowActions={rowActions} selectorFilters={tableSelectorFilters}
                />
            </div>
            <CreateNewFinancingRequestOverviewDialog onSubmit={onSubmit} open={isExtendDialogOpen}
                                                     setOpen={setIsExtendDialogOpen}
                                                     invoiceType={INVOICE_TYPE.RECEIVED}
                                                     isSubmitting={isSubmitting}/>
        </>
    )
}
