import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    global: true,
    dialog: false,
};

export const slice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setGlobal: (state, action) => ({global: action.payload}),
        setDialog: (state, action) => ({dialog: action.payload}),
    }
});

// ACTIONS

const {setGlobal, setDialog} = slice.actions;

export const setGlobalLoader = (isLoading = false) => setGlobal(isLoading)
export const setDialogLoader = (isLoading = false) => setDialog(isLoading)

// SELECTORS

export const isGlobalLoaderActive = state => state.loader.global;
export const isDialogLoaderActive = state => state.loader.dialog;

export default slice.reducer;
