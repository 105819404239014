import React from "react";
import Toolbar from "../../components/toolbar";
import {AcademicCapIcon, BanknotesIcon, ClockIcon, ReceiptRefundIcon,} from '@heroicons/react/24/outline'
import {useTranslation} from "react-i18next";
import PayablesTable from "./payablesTable";
import ActionsBar from "../../components/actionsBar";

export default function Payables() {
    const {t} = useTranslation()

    const actions = [

        {
            title: t('addInvoices'),
            href: '/u/payables/import',
            icon: ReceiptRefundIcon,
            iconForeground: 'text-rose-700',
            iconBackground: 'bg-rose-50',
        },
        {
            title: t('payEarly'),
            href: '#',
            icon: BanknotesIcon,
            iconForeground: 'text-yellow-700',
            iconBackground: 'bg-yellow-50',
        },
        {
            title: t('apiConnection'),
            href: '#',
            icon: ClockIcon,
            iconForeground: 'text-teal-700',
            iconBackground: 'bg-teal-50',
        },
        {
            title: t('paymentHistory'),
            href: '#',
            icon: AcademicCapIcon,
            iconForeground: 'text-primary-700',
            iconBackground: 'bg-primary-50',
        },
    ]

    return (
        <>
            <Toolbar/>
            <div className="mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8 space-y-16 py-16 pt-8 xl:space-y-20">
                <div className="px-4 sm:px-6 lg:flex-auto lg:px-0">
                    <ActionsBar actions={actions}/>
                    <PayablesTable/>
                </div>
            </div>
        </>
    )
}
