import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import TableView from "../../components/tableView";
import Toolbar from "../../components/toolbar";
import CashflowChart from "./cashflowChart";
import {classNames, formatMoney, getFirestampDate, getYearBackDate} from "../../utils";
import {receivableListSelector} from "../../store/receivables";
import {payableListSelector} from "../../store/payables";
import {useTranslation} from "react-i18next";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import {cashflowSelector, fetchMonthlyCashflow} from "../../store/cashflow";


const defaultTableColumns = [
    {v: d => d.label || "-", label: "", className: "w-[16rem]"},
]

const data = {
    labels: [],
    datasets: [],
};

const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div.chartjs-tooltip');

    //console.log("tooltipEl", tooltipEl)

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.classList.add('chartjs-tooltip', 'shadow-md', 'rounded-md', 'p-2', 'text-sm', 'text-gray-500', 'bg-white');
        tooltipEl.style.borderRadius = '6px';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-256px, -50%)';
        tooltipEl.style.transition = 'all .1s ease';
        tooltipEl.style.minWidth = '240px';

        const table = document.createElement('table');
        table.style.margin = '0px';

        const arrow = document.createElement('div');
        arrow.classList.add('chartjs-tooltip__arrow');

        tooltipEl.appendChild(table);
        tooltipEl.appendChild(arrow);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
};

const externalTooltipHandler = (context) => {
    // Tooltip Element
    const {chart, tooltip} = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    // Set Text
    if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map(b => b.lines);

        const tableHead = document.createElement('thead');

        titleLines.forEach(title => {
            const tr = document.createElement('tr');
            tr.style.borderWidth = 0;

            const th = document.createElement('th');
            th.style.borderWidth = 0;
            const text = document.createTextNode(title);
            th.classList.add('text-xs', 'font-semibold', 'text-gray-500', 'py-1', 'border-b', 'border-gray-200');

            th.appendChild(text);
            tr.appendChild(th);
            tableHead.appendChild(tr);
        });

        const tableBody = document.createElement('tbody');
        bodyLines.forEach((body, i) => {
            const colors = tooltip.labelColors[i];
            const label = tooltip.dataPoints[i].dataset.labelText
            const labelText = document.createTextNode(label);

            const cellContainer = document.createElement('div');
            cellContainer.classList.add('flex', 'items-center', 'mr-2', 'whitespace-nowrap');
            const span = document.createElement('span');
            span.classList.add('rounded-sm', 'ml-2');
            span.style.background = colors.backgroundColor;
            span.style.borderColor = colors.borderColor;
            span.style.borderWidth = '2px';
            span.style.marginRight = '12px';
            span.style.height = '14px';
            span.style.width = '14px';
            span.style.display = 'inline-block';

            const tr = document.createElement('tr');
            tr.style.backgroundColor = 'inherit';
            tr.style.borderWidth = 0;

            const td = document.createElement('td');
            td.style.borderWidth = 0;
            td.classList.add('text-md', 'font-semibold', 'text-gray-500', 'py-1', 'px-1', 'flex', 'items-center', 'justify-between', 'whitespace-nowrap');

            const text = document.createTextNode(body);
            const valueSpan = document.createElement('span');
            valueSpan.classList.add('whitespace-nowrap');
            valueSpan.appendChild(text);

            cellContainer.appendChild(span);
            cellContainer.appendChild(labelText);
            td.appendChild(cellContainer);
            td.appendChild(valueSpan);
            tr.appendChild(td);
            tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector('table');

        // Remove old children
        while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
    }

    const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

const footer = (tooltipItems) => {
    let sum = 0;

    tooltipItems.forEach(function (tooltipItem) {
        sum += tooltipItem.parsed.y;
    });
    return 'Sum: ' + sum;
};

const publishingOptions = [
    {title: 'Monthly', current: true},
    {title: 'Weekly', current: false},
]

export default function Cashflow({overview = false}) {
    const {t} = useTranslation()
    const containerRef = useRef(null)
    const options = (min, max) => ({
        responsive: true,
        interaction: {
            intersect: false,
            mode: 'index',
        },
        plugins: {
            htmlLegend: {
                // ID of the container to put the legend in
                containerID: 'legend-container',
            },
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    /*title: (xDatapoint) => {
                        //console.log("xDatapoint", xDatapoint)
                        return xDatapoint.raw
                    },*/
                    label: (yDatapoint, at2, at3) => {
                        //console.log("yDatapoint", yDatapoint)
                        let text = "" //" " + yDatapoint?.dataset?.label + ": "
                        if (yDatapoint?.datasetIndex === 4) {
                            // expense
                            text += formatMoney(-yDatapoint.raw, true) + " €"
                        } else text += formatMoney(yDatapoint.raw, true) + " €"
                        return text
                    },
                },
                enabled: false,
                //position: 'nearest',
                external: externalTooltipHandler,
                /*callbacks: {
                    footer: footer,
                }*/
            }
        },
        elements: {
            //pointStyle: 'circle',
        },
        scales: {
            x: {
                border: {
                    display: false
                },
                grid: {
                    display: true,
                    drawOnChartArea: false,
                    drawTicks: true,
                    color: 'rgb(243 244 246)'
                },
                display: true,
                ticks: {
                    display: true,
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                    callback: function (val, index, ticks) {
                        //if (index === 0 || ticks.length - 1 === index) return undefined // hide first and last tick
                        return this.getLabelForValue(val);
                    },
                    color: "rgb(75 85 99)",
                    tickWidth: 400,
                }
            },
            y: {
                ticks: {
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                    callback: function (val, index) {
                        // Hide every 2nd tick label
                        return index % 2 === 0 ? this.getLabelForValue(val) + " €" : '';
                    },
                    color: "rgb(156 163 175)",
                    tickWidth: 400,
                },
                afterFit(scale) {
                    const containerWidth = containerRef?.current?.offsetWidth,
                        v = (containerWidth || 1081) - 256,
                        v2 = v / 6,
                        v3 = 256 - v2 + 15; // +15 is for padding
                    //console.log("afterFit", v3)
                    scale.width = v3;
                },
                border: {
                    display: false,
                },
                grid: {
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: 'rgb(243 244 246)'
                },
                display: true,
                suggestedMin: min,
                suggestedMax: max
            }
        }
    })

    const [selected, setSelected] = useState(publishingOptions[0])

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const orderedIssued = useSelector(receivableListSelector)
    const orderedReceived = useSelector(payableListSelector)
    const cashflowDatasets = useSelector(cashflowSelector)

    const [cashflow, setCashflow] = useState(data)
    const [cashflowOptions, setCashflowOptions] = useState(options(-1000, 1000))

    const [tableColumns, setTableColumns] = useState(defaultTableColumns)
    const [ordered, setOrdered] = useState([])

    const [timeframe, setTimeframe] = useState("month")

    useEffect(() => {
        fetchData({orderBy: "dueDate", limit: 10000, filter: ["dueDate", ">=", getYearBackDate().toISOString()]})
    }, [])

    useEffect(() => {
        //setCashflowOptions(options(-1000, 1000))
    }, [containerRef])

    function fetchData(_params) {
        //setParams(_params)
        setLoading(true)
        //dispatch(fetchIssued(_params))
        dispatch(fetchMonthlyCashflow(_params))
    }

    function populateDataArray(generateKeyForDate, timeframeMap, timeframePayedMap, ordered, subtract = false) {
        for (let i = 0; i < ordered.length; i++) {
            const it = ordered[i],
                key = generateKeyForDate(getFirestampDate(it.dueDate))
            //console.log("operating with", it)
            if (timeframeMap.has(key)) timeframeMap.set(key, subtract ? timeframeMap.get(key) - (it.amount?.value || 0) : timeframeMap.get(key) + (it.amount?.value || 0))
            if (timeframePayedMap.has(key)) timeframePayedMap.set(key, subtract ? timeframePayedMap.get(key) - (it.paidAmount?.value || 0) : timeframePayedMap.get(key) + (it.paidAmount?.value || 0))
        }
    }

    function calculateCashflow(data) {
        const expectedBalance = {};
        const cashInHandPayments = {};
        const cashInHandPlan = {};

        let runningTotalPayments = 0;
        let runningTotalPlan = 0;


        for (const key of data.income.keys()) {
            const income = data.income.get(key);
            const expenses = data.expenses.get(key);
            const incomePaid = data.incomePaid.get(key);
            const expensesPaid = data.expensesPaid.get(key);

            expectedBalance[key] = runningTotalPayments + (income - expenses);
            runningTotalPayments += incomePaid - expensesPaid;
            cashInHandPayments[key] = runningTotalPayments;

            // Replace 0 with your planned cash in hand for each period
            runningTotalPlan += 0;
            cashInHandPlan[key] = runningTotalPlan;
        }

        return {
            expectedBalance,
            cashInHandPayments,
            cashInHandPlan,
        };
    }

    useEffect(() => {
        /*let issuedByTimeframe,
            receivedByTimeframe,
            issuedPaidByTimeframe,
            receivedPaidByTimeframe

        switch (timeframe) {
            case "week":
                issuedByTimeframe = getLastWeeksMap(0, 12);
                receivedByTimeframe = getLastWeeksMap(0, 12);
                issuedPaidByTimeframe = getLastWeeksMap(0, 12);
                receivedPaidByTimeframe = getLastWeeksMap(0, 12);

                populateDataArray(generateWeekKeyForDate, issuedByTimeframe, issuedPaidByTimeframe, orderedIssued)
                populateDataArray(generateWeekKeyForDate, receivedByTimeframe, receivedPaidByTimeframe, orderedReceived, false)
                break;
            case "day":
                break;
            default:
                // month
                issuedByTimeframe = getLastMonthsMap(0, 12);
                receivedByTimeframe = getLastMonthsMap(0, 12);
                issuedPaidByTimeframe = getLastMonthsMap(0, 12);
                receivedPaidByTimeframe = getLastMonthsMap(0, 12);

                populateDataArray(generateMonthKeyForDate, issuedByTimeframe, issuedPaidByTimeframe, orderedIssued)
                populateDataArray(generateMonthKeyForDate, receivedByTimeframe, receivedPaidByTimeframe, orderedReceived, false)
        }


        const income = Array.from(issuedByTimeframe.values()),
            expenses = Array.from(receivedByTimeframe.values()),
            labels = Array.from(issuedByTimeframe.keys()),
            {
                expectedBalance,
                cashInHandPayments,
                cashInHandPlan
            } = calculateCashflow({
                income: issuedByTimeframe,
                expenses: receivedByTimeframe,
                incomePaid: issuedPaidByTimeframe,
                expensesPaid: receivedPaidByTimeframe
            })

        let min = 0, max = 0
        Array.from([...income, ...expenses, ...Object.values(expectedBalance), ...Object.values(cashInHandPayments), ...Object.values(cashInHandPlan)]).forEach(it => {
            min = Math.min(min, it)
            max = Math.max(max, it)
        })
        min += min * 0.1
        max += max * 0.1*/

        if (cashflowDatasets) {
            console.log("cashflowDatasets", cashflowDatasets)
            const {labels, datasets} = cashflowDatasets

            const cashflowData = {
                    labels,
                    datasets: [
                        /*{
                            type: 'line',
                            label: 'Expected balance',
                            backgroundColor: 'rgb(221 214 254)',
                            borderColor: 'rgb(139 92 246)',
                            borderWidth: 2,
                            fill: true,
                            data: Object.values(expectedBalance),
                        },*/
                        {
                            type: 'line',
                            label: 'Cash in hand',
                            labelText: 'Cash in hand',
                            backgroundColor: 'rgb(253 230 138)',
                            borderColor: 'rgb(245 158 11)',
                            borderWidth: 2,
                            fill: true,
                            data: datasets?.cashInHand || [],
                        },
                        {
                            type: 'bar',
                            label: <span className="inline-flex items-center"><ChevronRightIcon
                                className="inline-block h-3 w-3 text-gray-500 ml-2 mr-2"/> Cash in</span>,
                            labelText: 'Cash in',
                            backgroundColor: 'rgb(34 197 94)',
                            borderColor: 'rgb(34 197 94)',
                            borderWidth: 2,
                            data: datasets?.cashIn || [],
                        },
                        {
                            type: 'bar',
                            label: <span className="inline-flex items-center"><ChevronRightIcon
                                className="inline-block h-3 w-3 text-gray-500 ml-2 mr-2"/> Cash out</span>,
                            labelText: 'Cash out',
                            backgroundColor: 'rgb(239 68 68)',
                            borderColor: 'rgb(239 68 68)',
                            borderWidth: 2,
                            data: datasets?.cashOut || [],
                        },
                        {
                            type: 'bar',
                            label: "Income",
                            labelText: 'Income',
                            backgroundColor: 'rgb(89,89,89)',
                            borderColor: 'rgb(89,89,89)',
                            borderWidth: 2,
                            data: datasets?.income || [],
                        },
                        {
                            type: 'bar',
                            label: "Paid income",
                            labelText: 'Paid income',
                            backgroundColor: 'rgb(89,89,89)',
                            borderColor: 'rgb(89,89,89)',
                            borderWidth: 2,
                            data: datasets?.paidIncome || [],
                        },
                        {
                            type: 'bar',
                            label: "Unpaid income",
                            labelText: 'Unpaid income',
                            backgroundColor: 'rgb(89,89,89)',
                            borderColor: 'rgb(89,89,89)',
                            borderWidth: 2,
                            data: datasets?.unpaidIncome || [],
                        },


                        /*{
                            type: 'line',
                            label: 'Cash in hand, Plan',
                            backgroundColor: 'rgb(165 243 252)',
                            borderColor: 'rgb(6 182 212)',
                            borderWidth: 2,
                            fill: true,
                            data: Object.values(cashInHandPlan),
                        },
                        {
                            type: 'bar',
                            label: 'Income',
                            backgroundColor: 'rgb(34 197 94)',
                            borderColor: 'rgb(34 197 94)',
                            borderWidth: 2,
                            data: income,
                        },
                        {
                            type: 'bar',
                            label: 'Expenses',
                            backgroundColor: 'rgb(239 68 68)',
                            borderColor: 'rgb(239 68 68)',
                            borderWidth: 2,
                            data: expenses,
                        },*/
                    ],
                },
                newTableColumns = [...defaultTableColumns]

            labels.forEach((label, i) => {
                //if (i === 0) return // hide first column
                newTableColumns.push({
                    v: (d, idx, data) => {
                        const allNegative = 2,
                            allPositive = 1,
                            value = d.data[i],
                            prevValue = i > 0 ? d.data[i - 1] : 0,
                            formatted = (formatMoney(value, true) || "0,00") + " €"

                        let textColor = "text-gray-400",
                            prefix = ""

                        if (idx === allNegative) {
                            if (value > 0) {
                                textColor = "text-red-600"
                                prefix = "-"
                            }

                        } else if (idx === allPositive) {
                            if (value > 0) {
                                textColor = "text-green-600"
                            }
                        } else textColor = value === 0 ? "text-gray-700" : value > 0 ? "text-green-600" : "text-red-600"

                        return <span
                            className={classNames(textColor, "font-medium whitespace-nowrap")}>{formatted}</span> /*value > prevValue ? <span className="flex items-center relative pl-0"><ArrowSmallUpIcon
                            className="w-5 h-5 absolute -left-4 text-primary-400"/>{formatted}</span> :
                        value < prevValue ? <span className="flex items-center relative pl-0"><ArrowSmallDownIcon
                            className="w-5 h-5 absolute -left-4 text-red-400"/>{formatted}</span> : formatted*/
                    }, label: <span className="block text-left text-xs text-gray-400">{label}</span>
                })
            })

            //console.log(cashflowData)

            setCashflow(cashflowData)
            setCashflowOptions(options(0, 0))
            setTableColumns(newTableColumns)
            setOrdered(cashflowData.datasets.map((dataset, index) => {
                const dot = <span style={{
                    background: /*dataset.backgroundColor[1] || */dataset.backgroundColor,
                    borderColor: /*dataset.borderColor[1] || */dataset.borderColor,
                    borderWidth: "3px",
                    borderRadius: "50%",
                    boxSizing: "content-box",
                    display: "inline-block",
                    height: "0.4rem",
                    marginRight: "0.5rem",
                    width: "0.4rem",
                    overflow: "hidden"
                }}/>
                return {
                    label: <span>{dot} {dataset.label}</span>,
                    data: /*dataset.label === "Expenses" ? dataset.data.map(it => -it) :*/ dataset.data
                }
            }))

            setLoading(false)
        }


    }, [orderedIssued, orderedReceived, timeframe, cashflowDatasets])

    return (
        <>
            {overview ? (
                <div ref={containerRef}
                     className="overflow-hidden border-b border-gray-200 bg-white sm:rounded-xl border border-gray-200 flex flex-col">
                    <div className="py-8">
                        <CashflowChart data={cashflow} options={cashflowOptions}/>
                    </div>

                    <TableView className="table-fixed" id="cashflow" columns={tableColumns} data={ordered}
                               hasMore={false} loading={loading}
                               selectable={false} showNavigation={false}/>
                </div>
            ) : (
                <>
                    <Toolbar/>
                    <div className="mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8">
                        <div className="px-4 sm:px-6 lg:flex-auto lg:px-0 pb-16">
                            <div className="mt-8 flex items-center justify-between">
                                <h2 className="text-base font-semibold leading-7 text-gray-900"> {t('cashflow')}</h2>
                                <div>
                                    <select
                                        id="timeframe"
                                        name="timeframe"
                                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary-600 sm:text-sm sm:leading-6"
                                        value={timeframe}
                                        onChange={e => setTimeframe(e.target.value)}
                                    >
                                        <option value={"month"}>Monthly</option>
                                        <option value={"week"}>Weekly</option>
                                    </select>
                                </div>
                            </div>
                            <div
                                className="mt-8 overflow-hidden bg-white sm:rounded-xl border border-gray-200 flex flex-col">
                                <div className="px-6 py-6 pt-8">
                                    <CashflowChart data={cashflow} options={cashflowOptions}/>
                                </div>
                            </div>
                            <h2 className="mt-8 text-lg leading-6 font-medium text-gray-900">
                                Cashflow summary
                            </h2>
                            <div
                                className="mt-8 overflow-hidden border-b border-gray-200 bg-white sm:rounded-xl border border-gray-200 flex flex-col">
                                <TableView id="cashflow" columns={tableColumns} data={ordered} hasMore={false}
                                           loading={loading}
                                           selectable={false} showNavigation={false}/>
                            </div>
                        </div>
                    </div>
                </>
            )}

        </>
    )
}
