import {createSlice} from '@reduxjs/toolkit';
import {financeApi} from "../config";
import {serializeQuery} from "../utils";

const initialState = {
    ordered: [],
    hasMore: false,
};

export const slice = createSlice({
    name: 'financing',
    initialState,
    reducers: {
        setList: (state, action) => ({
            ...action.payload
        }),
    }
});

// ACTIONS

const {setList} = slice.actions;

export const fetchFinancingList = (params) => async (dispatch, getState) => {
    const state = getState(), activeOrganizationId = state.client.realActiveCid;
    await financeApi.get("/promissory-notes/" + activeOrganizationId + "?" + serializeQuery(params)).then(response => {
        //console.log("fetchFinancingList", response.data)
        if (response.data?.results && response.data.results.length >= 0) {
            dispatch(setList({
                ordered: response.data.offset > 0 ? [...state.financing.ordered, ...response.data.results] : [...response.data.results],
                hasMore: response.data.hasMore
            }));
        } else {
            dispatch(setList({
                ordered: [],
                hasMore: false
            }));
        }
    }).catch(error => {
        console.log("error", error)
    })
}

// SELECTORS


export const financingListSelector = state => state.financing.ordered;
export const financingListHasMoreSelector = state => state.financing.hasMore;

export default slice.reducer;
