import {useTranslation} from "react-i18next";
import {ExclamationCircleIcon} from '@heroicons/react/20/solid'
import {classNames} from "../../utils";
import {Link, useNavigate} from "react-router-dom";
import React from "react";

const inputName = "phone"
export default function Phone({data, setData, error, setError, nextState, prevState}) {
    const navigate = useNavigate();
    const {t} = useTranslation()

    function onSubmit(e) {
        e.preventDefault();

        if (!data) {
            setError("Please enter your phone number")
        } else {
            navigate("/quote/" + nextState.nextStepName)
        }
    }

    return (
        <form onSubmit={onSubmit} className="w-full max-w-sm">
            <label htmlFor={inputName} className="block text-sm font-medium leading-6 text-white">
                Phone
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
                <input
                    type="text"
                    name={inputName}
                    id={inputName}
                    className={classNames(
                        "block w-full rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6",
                        error ? "pr-10 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500" : "text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-primary-600"
                    )}
                    placeholder="+386 40 400 400"
                    value={data}
                    onChange={e => setData(e.target.value)}
                    aria-invalid={!!error}
                    aria-describedby={!!error ? inputName + "-error" : undefined}
                    required
                />
                {error && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                    </div>
                )}
            </div>
            {error && (
                <p className="mt-2 text-sm text-red-600" id={inputName + "-error"}>
                    {error}
                </p>
            )}
            <div className="mt-8 flex space-x-4">
                <Link
                    to={"/quote/" + prevState.prevStepName}
                    className="rounded-md bg-white/10 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                >
                    Back
                </Link>
                <button
                    type="submit"
                    disabled={!data}
                    className={classNames(
                        "rounded-md bg-primary-700 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-700",
                        !data ? "opacity-75 cursor-not-allowed" : ""
                    )}
                >
                    Next
                </button>
            </div>
        </form>
    )
}
