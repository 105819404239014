import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import CreditRatingDetails from "./details";
import {useSelector} from "react-redux";
import {clientSelector, realActiveCidSelector} from "../../store/client";

export default function CreditRating({showRating = false}) {
    const {id} = useParams()
    const activeOrganizationId = useSelector(realActiveCidSelector)
    const organization = useSelector(clientSelector(activeOrganizationId))
    const navigate = useNavigate();

    useEffect(() => {
        if (!id && organization?.oid) {
            navigate("/u/organization/" + organization?.oid)
        }
    }, [id, organization?.oid])

    /*const searchOptions = {
        /!*suggestions: [
            {id: 'taxNo', name: "Tax number"},
            // More projects...
        ]*!/
    }


    function autocompletePublicCompanies(param = "q", value = "") {
        return api.get("/organizations/search?" + param + "=" + encodeURIComponent(value)).then(response => {
            return response?.data || []
        }).catch(error => {
            // handle error
            console.log("error", error)
        })
    }

    function onAutocomplete(query) {
        //console.log("onAutocomplete", query)
        return autocompletePublicCompanies("q", query)
    }

    function onSearch(search) {
        //console.log("onSearch", search)
        //searchPublicCompanies(search.id, search.value)

        if (search.id === "oid") {
            navigate("/u/organization/" + search.value?.oid)
        }
    }*/

    return (
        <div className="mt-8">
            <div className="mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8">
                <div className="px-4 sm:px-6 lg:flex-auto lg:px-0">
                    <CreditRatingDetails id={id || organization?.oid} showRating={showRating}/>
                </div>
            </div>
        </div>
    )
}
