import React from 'react';
import ImportInvoices from "../importInvoices";
import {INVOICE_TYPE} from "../../consts";
import {useSelector} from "react-redux";
import {clientSelector, realActiveCidSelector} from "../../store/client";

export default function ReceivablesImport() {
    const activeOrganizationId = useSelector(realActiveCidSelector)
    const organization = useSelector(clientSelector(activeOrganizationId))

    return <ImportInvoices financingType={"factoring"} invoiceType={INVOICE_TYPE.ISSUED}
                           navigateToOnSuccess={"/u/receivables"}
                           canCreate={!!organization?.features?.FINANCE_FACTORING_CREATE}/>
};
