import {classNames} from "../utils";
import {Link} from "react-router-dom";
import React from "react";

export default function ActionsBar({actions = []}) {
    return (
        <div
            className="divide-y divide-gray-200 overflow-hidden sm:rounded-xl border border-gray-200 sm:grid md:grid-cols-2 xl:grid-cols-4 sm:gap-px sm:divide-y-0">
            {actions.map((action, actionIdx) => (
                <div
                    key={action.title}
                    className="bg-white"
                >
                    <div

                        className="m-1 relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-500 rounded-lg">
                        <div>
            <span
                className={classNames(
                    action.iconBackground,
                    action.iconForeground,
                    'rounded-lg inline-flex p-3 ring-4 ring-white'
                )}
            >
              <action.icon className="h-6 w-6" aria-hidden="true"/>
            </span>
                        </div>
                        <div className="mt-8">
                            <h3 className="text-md font-medium">
                                {action.href ? (
                                    <Link to={action.href} className="focus:outline-none text-gray-900">
                                        {/* Extend touch target to entire panel */}
                                        <span className="absolute inset-0" aria-hidden="true"/>
                                        {action.title}
                                    </Link>
                                ) : action.onClick ? (
                                    <button onClick={action.onClick} className="focus:outline-none text-gray-900">
                                        {/* Extend touch target to entire panel */}
                                        <span className="absolute inset-0" aria-hidden="true"/>
                                        {action.title}
                                    </button>
                                ) : null}

                            </h3>
                            {action.summary && (
                                <p className="mt-2 text-xs text-gray-500">
                                    {action.summary || ""}
                                </p>
                            )}
                        </div>
                        <span
                            className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                            aria-hidden="true"
                        >
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path
                  d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"/>
            </svg>
          </span>
                    </div>
                </div>
            ))}
        </div>
    )
}
