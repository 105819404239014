import {Fragment, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {classNames, dateFormatter, getNextWorkingDay} from "../utils";
import TailSpinner from "./tailSpinner";
import {INVOICE_TYPE} from "../consts";
import {useTranslation} from "react-i18next";

export default function CreateNewFinancingRequestOverviewDialog({onSubmit, open, setOpen, isSubmitting, invoiceType}) {
    const {t} = useTranslation()
    const cancelButtonRef = useRef(null)
    const [date, setDate] = useState(dateFormatter(getNextWorkingDay(new Date()).getTime()))

    function handleSubmit(e) {
        e.preventDefault();
        onSubmit(date)
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                as="form"
                                onSubmit={handleSubmit}
                                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        {/*<div
                                            className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600"
                                                                     aria-hidden="true"/>
                                        </div>*/}
                                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3"
                                                          className="text-lg font-medium leading-6 text-gray-900">
                                                {invoiceType === INVOICE_TYPE.ISSUED && "Create a New Financing Request"}
                                                {invoiceType === INVOICE_TYPE.RECEIVED && "Extend Payment Date"}
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">

                                                    {invoiceType === INVOICE_TYPE.ISSUED && "You're about to submit a new financing request. Please set the transaction data and then click the \"Submit\" button."}
                                                    {invoiceType === INVOICE_TYPE.RECEIVED && "You're about to submit an extension request. Please set the extended payment date and then click the \"Submit\" button."}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                        <div className="sm:col-span-6">
                                            <label htmlFor="date"
                                                   className="block text-sm font-medium text-gray-700">
                                                {invoiceType === INVOICE_TYPE.ISSUED && "Transaction date"}
                                                {invoiceType === INVOICE_TYPE.RECEIVED && "Extended payment date"}
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    type="date"
                                                    id="date"
                                                    onChange={e => setDate(e.target.value)}
                                                    value={date}
                                                    required
                                                    className={classNames(
                                                        "block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm bg-white",
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        disabled={isSubmitting}
                                        type="submit"
                                        className={classNames(
                                            "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm",
                                            isSubmitting ? "bg-gray-300 cursor-default" : "bg-primary-600 text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                        )}
                                    >
                                        {isSubmitting && <TailSpinner/>}
                                        {isSubmitting ? "Submitting..." : "Submit"}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        {t('cancel')}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
