import React, {Fragment, useEffect, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {api, financeApi} from "../../config";
import {useSelector} from "react-redux";
import {realActiveCidSelector} from "../../store/client";
import {formatAddress, serializeQuery} from "../../utils";
import TableView from "../../components/tableView";
import {useTranslation} from "react-i18next";
import {InboxArrowDownIcon} from "@heroicons/react/20/solid";
import AddPartnerDialog from "../../components/addPartnerDialog";


export default function ImportPartnersDialog({open, setOpen}) {
    const {t} = useTranslation()
    const cancelButtonRef = useRef(null)
    const activeOrganizationId = useSelector(realActiveCidSelector)
    const [name, setName] = useState(data?.name || "")
    const [loading, setLoading] = useState(true)
    const [params, setParams] = useState({})
    const [data, setData] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [selected, setSelected] = useState([])
    const [isAddPartnerDialogOpen, setIsAddPartnerDialogOpen] = useState(false)
    const [addPartnerTaxNo, setAddPartnerTaxNo] = useState("")
    const [addPartnerCompanyQuery, setAddPartnerCompanyQuery] = useState("")

    const tableColumns = [
        {v: d => d.name, label: "Name"},
        {v: d => d.taxNo, label: "Tax Number"},
        {v: d => formatAddress(d.address), label: "Address"},
    ]

    const searchOptions = {
        suggestions: [
            {id: 'keyword', name: "Keyword"},
            // More projects...
        ]
    }

    const rowActions = [
        {
            prominent: true,
            label: t('import'),
            icon: InboxArrowDownIcon,
            check: d => true,
            onClick: (item) => {
                console.log(item)
                setIsAddPartnerDialogOpen(true)
                setAddPartnerCompanyQuery(item.name)
                setAddPartnerTaxNo(item.taxNo)
                //setEditPartner(item)
            }
        }
    ]

    useEffect(() => {
        if (open) {
            setIsSubmitting(false)
            setName(data?.name)
            //console.log("partner",data)
        }
    }, [open])

    function onSubmit(e) {
        e.preventDefault()

        console.log(selected)

        return

        if (isSubmitting) return
        const obj = {
            name: name,
        }
        setIsSubmitting(true)
        return api.put(`/organizations/${activeOrganizationId}/partners/${data?.id}`, obj)
            .then(res => {
                //console.log("data", res.data)
            })
            .catch(error => {
                // TODO: handle error
                //console.log("error", error)
            })
            .finally(() => {
                setIsSubmitting(false)
                setOpen(false)
            })
    }

    function loadMore() {
        let offset = 0
        if (data.results) {
            offset = (data.offset || 0) + (data.limit || 10)
        }
        setParams({...params, offset})
        fetchData({...params, offset})
    }

    function fetchData(_params = {}) {
        setLoading(true)
        setParams(_params)
        financeApi.get("organizations/" + activeOrganizationId + "/providers/default/customers?" + serializeQuery(_params))
            .then(res => {
                console.log(res.data)
                if (res.status === 200 && res.data?.results) {
                    setData(res.data)
                } else setData({})
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                                    <form onSubmit={onSubmit}
                                          className="flex h-full flex-col bg-white shadow-xl">
                                        <div className="flex-1">
                                            {/* Header */}
                                            <div className="bg-gray-50 px-4 py-6 sm:px-6">
                                                <div className="flex items-start justify-between space-x-3">
                                                    <div className="space-y-1">
                                                        <Dialog.Title
                                                            className="text-base font-semibold leading-6 text-gray-900">
                                                            Import partners
                                                        </Dialog.Title>
                                                        <p className="text-sm text-gray-500">
                                                            Import your customers from Minimax.
                                                        </p>
                                                    </div>
                                                    {/*<div className="flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    className="text-gray-400 hover:text-gray-500"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    <span className="sr-only">Close panel</span>
                                                    <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                                </button>
                                            </div>*/}
                                                </div>
                                            </div>

                                            {/* Divider container */}
                                            <div
                                                className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">


                                                <TableView id="importedPartners" columns={tableColumns}
                                                           data={data.results || []}
                                                           loading={loading} hasMore={data.hasMore}
                                                           onLoadMore={loadMore}
                                                           searchOptions={searchOptions} setSelected={setSelected}
                                                           selectable={false}
                                                           rowActions={rowActions}
                                                           onSearch={fetchData}/>

                                            </div>
                                        </div>

                                        {/* Action buttons */}
                                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                            {/*<button
                                        disabled={isSubmitting}
                                        type="submit"
                                        className={classNames("w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm", (isSubmitting) ? "bg-gray-300 cursor-default" : "bg-primary-600 text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500")}
                                    >
                                        {isSubmitting && <TailSpinner/>}
                                        {isSubmitting ? "Importing..." : "Import"}
                                    </button>*/}
                                            <button
                                                type="button"
                                                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                                onClick={() => setOpen(false)}
                                                ref={cancelButtonRef}
                                            >
                                                Dismiss
                                            </button>
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <AddPartnerDialog open={isAddPartnerDialogOpen} setOpen={setIsAddPartnerDialogOpen} taxNo={addPartnerTaxNo}
                              query={addPartnerCompanyQuery}/>
        </>
    )
}
