import {createSlice} from '@reduxjs/toolkit';

const initialState = {

};

export const slice = createSlice({
    name: 'table',
    initialState,
    reducers: {
        setParams: (state, action) => {
            const {id, params} = action.payload;
            state[id] = {...state[id], params};
        },
    }
});

// ACTIONS

const {setParams} = slice.actions;

export const setTableParams = (tableId, params) => {
    return setParams({id: tableId, params})
}

// SELECTORS

export const tableSelector = tableId => state => ({params: null, ...state.table[tableId]});

export default slice.reducer;
