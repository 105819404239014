import {createSlice} from '@reduxjs/toolkit';
import {financeApi} from "../config";
import {serializeQuery} from "../utils";

const initialState = {
    ordered: [],
    hasMore: false,
};

export const slice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {
        setList: (state, action) => {
            const {serializedQuery, ordered, hasMore} = action.payload;
            state.ordered = ordered;
            state.hasMore = hasMore;
            if (serializedQuery) state[serializedQuery] = {ordered, hasMore};
        },
    }
});

// ACTIONS

const {setList} = slice.actions;

export const fetchInvoiceList = (params) => async (dispatch, getState) => {
    const state = getState(), activeOrganizationId = state.client.realActiveCid,
        serializedQuery = serializeQuery(params)
    await financeApi.get("/invoices/" + activeOrganizationId + "?" + serializedQuery).then(response => {
        //console.log("fetchInvoiceList", response.data)
        if (response.data?.results && response.data.results.length >= 0) {
            dispatch(setList({
                serializedQuery: params?.filter ? serializedQuery : undefined,
                ordered: response.data.offset > 0 ? [...state.financing.ordered, ...response.data.results] : [...response.data.results],
                hasMore: response.data.hasMore
            }));
        } else {
            dispatch(setList({
                serializedQuery: params?.filter ? serializedQuery : undefined,
                ordered: [],
                hasMore: false
            }));
        }
    }).catch(error => {
        console.log("error", error)
    })
}

// SELECTORS

export const queryInvoiceListSelector = params => state => state.invoices[serializeQuery(params)]?.ordered || [];
export const queryInvoiceListHasMoreSelector = params => state => state.invoices[serializeQuery(params)]?.hasMore || false;
export const invoiceListSelector = state => state.invoices.ordered;
export const invoiceListHasMoreSelector = state => state.invoices.hasMore;

export default slice.reducer;
