import React, {useState} from "react";
import {toCurrency} from "../utils";

export default function AmountInput({value, ...props}) {
    const [isEditing, setIsEditing] = useState(false)

    function toggleEditing() {
        setIsEditing(!isEditing)
    }

    return <>
        {isEditing ? (
            <input
                type="text"
                value={value}
                onBlur={toggleEditing}
                {...props}
            />
        ) : (
            <input
                type="text"
                value={toCurrency(value)}
                onFocus={toggleEditing}
                readOnly={true}
                {...props}
            />
        )}
    </>
};
