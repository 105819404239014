export const sloDateParser = v => {
    // v is a string of "DD MM YY" format
    const match = /^(\d{1,2})[-.\/](\d{1,2})[-.\/](\d{2,4})$/gm.exec(v);
    if (match === null) return;
    const d = new Date(Date.UTC(match[3], parseInt(match[2], 10) - 1, match[1]));
    if (isNaN(d)) return;
    return d;
};

// css helper
export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function parseDecimalDotNumberString(s) {
    return parseFloat(s.replaceAll(",", ""))
}

function parseDecimalCommaNumberString(s) {
    return parseFloat(s.replaceAll(".", "").replaceAll(",", "."))
}

export function parseNumberString(s) {
    if (s && typeof s !== "string") {
        s = "" + s
    }
    const dotPos = s.indexOf("."),
        commaPos = s.indexOf(",")
    if (commaPos !== -1 && dotPos !== -1) {
        return commaPos < dotPos ? parseDecimalDotNumberString(s) : parseDecimalCommaNumberString(s)
    }
    if (commaPos !== -1) {
        const [a, b] = s.split(",")
        if (commaPos !== s.lastIndexOf(",") || (a.length < b.length && b.length === 3)) {
            // is probably a thousand's separator
            return parseFloat(s.replaceAll(",", ""))
        } else {
            return parseFloat(s.replaceAll(",", "."))
        }
    }
    if (dotPos !== -1) {
        const [a, b] = s.split(".")
        if (dotPos !== s.lastIndexOf(".") || (a.length < b.length && b.length === 3)) {
            // is probably a thousand's separator
            return parseFloat(s.replaceAll(".", ""))
        }
    }
    return parseFloat(s)
}
