import React, {useEffect, useRef, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next'
import '../styles/register.scss';
import {Link, Route, Routes, useNavigate, useParams} from "react-router-dom";
import countries from '../locales/countries'
import {auth} from "../firebase";
import zxcvbn from "zxcvbn";
import {api, INVEST_API} from "../config";
import {useDispatch} from "react-redux";
import {setGlobalLoader} from "../store/loader";
import {Combobox} from "@headlessui/react";
import {CheckIcon, ChevronRightIcon, ChevronUpDownIcon, EyeIcon, EyeSlashIcon} from "@heroicons/react/20/solid";
import {catchErrors, classNames} from "../utils";
import TailSpinner from "../components/tailSpinner";
import {fetchSignInMethodsForEmail} from "firebase/auth";
import {TextField} from "../components/textField";
import LanguageSelector from "../components/languageSelector";

const countryList = Object.keys(countries).map((key, i) => {
    const c = countries[key];
    return Object.assign({text: c.name.toUpperCase()}, c)
});

export function NotFound() {
    return (
        <div>
            <div className="Register-Content-Header">
                <Trans i18nKey='register:notFoundHeader'>
                    <h1 className="Register-Heading">404.</h1>
                </Trans>
                <Trans i18nKey='register:notFoundSubheader'>
                    <span className="Register-Subtitle text-sm">Page you are looking for can't be found.</span>
                </Trans>
                <div className="Register-Content-Body">
                    <Link to="/" className="Button">
                        <Trans i18nKey='register:backToHomepage'>Back to homepage</Trans>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export function RegisterHeader() {
    return (
        <div
            className="bg-primary-500 md:bg-white border border-b border-primary-600 flex items-center justify-end py-8">
            <div
                className="w-full mx-auto max-w-2xl lg:max-w-5xl py-2 md:py-0 px-8 md:px-14 flex flex-col-reverse md:flex-row justify-between items-center text-white text-xs gap-3">
                <Link to="/" className="no-underline block">
                    <img alt="" src="/Nekster_logoMono.png" className="w-[200px] block md:hidden"/>
                    <img alt="" src="/Nekster_logo.png" className="h-[48px] hidden md:block"/>
                </Link>
            </div>
        </div>
    )
}

function RegisterDesktopSteps(props) {
    const _steps = [
        {
            t: window.trans("Identity"),
            h: () => {
                return steps["account-type"].href
            }
        },
        {
            t: window.trans("Contact"),
            h: () => {
                return steps["personal-email"].href
            }
        },
        {
            t: window.trans("Security"),
            h: () => {
                return steps["choose-password"].href
            }
        },
        {
            t: window.trans("Legal"),
            h: () => {
                return steps[props.inputData.accountType]["agreements"].href
            }
        },
    ];
    _steps.forEach((s, i) => {
        if (props.step === i) {
            s.c = " Register-Desktop-Steps-Item--Active";
        } else if (props.completed >= i) {
            s.c = " Register-Desktop-Steps-Item--Completed";
            s._h = s.h();
        }
    });

    return (
        <div className="Register-Desktop-Steps-Container">
            <ul className="Register-Desktop-Steps">
                {_steps.map((step, i) => (
                    <li key={i} className={"Register-Desktop-Steps-Item" + (step.c || "")}>
                        {step._h ? (<Link to={step._h}>{step.t}</Link>) : step.t}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export function RegisterContent({children}) {
    return (
        <div
            className="flex-grow relative bg-primary-500 text-white sm:flex sm:justify-center sm:items-center py-8 text-center">
            {children}
        </div>
    )
}

function RegisterLoginInstead() {
    const {t} = useTranslation('register')

    return (
        <div className="text-sm my-8 flex-col text-center">
            <span>{t("loginInsteadText")}</span>
            <Link to="/register" className="block font-semibold">{t("loginInsteadLink")}</Link>
        </div>
    )
}

export function RegisterInstead() {
    const {t} = useTranslation('register')

    return (
        <div className="text-sm my-8 flex-col text-center">
            <span>{t("registerInsteadText")}</span>
            <Link to="/register" className="block font-semibold">{t("registerInsteadLink")}</Link>
        </div>
    )
}

export function RegisterFooter() {
    const {t} = useTranslation('register')
    return (
        <div className="bg-primary-500 border border-t border-primary-600 flex items-center justify-end py-2">
            <div
                className="w-full mx-auto max-w-2xl lg:max-w-5xl py-2 md:py-0 px-8 md:px-14 flex flex-col-reverse md:flex-row justify-between items-center text-white text-xs gap-3">
                <span>{t("copyright")}</span>
                <div>
                    <LanguageSelector/>
                </div>
            </div>
        </div>
    )
}

function RegisterStep1() {
    const _pendingInvitation = sessionStorage?.getItem("invitationId") || ""

    return <>
        <div className="Register-Content-Header">
            <Trans i18nKey='register:registerTitle'>
                <h1 className="Register-Heading">Smart <b>investing</b> starts <b>here</b>.</h1>
            </Trans>
            <Trans i18nKey='register:registerSubtitle'>
                <span className="Register-Subtitle text-sm">Get your investor account in under 2 min</span>
            </Trans>
            <div className="Register-Content-Body">
                <Link to={_pendingInvitation ? "/register/account-type" : "/register/business-country"}
                      className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20">
                    <Trans i18nKey='register:startRegistration'>Start registration</Trans>
                </Link>
            </div>
        </div>
        <RegisterLoginInstead/>
    </>
}

function RegisterStep2({onChange, onForward}) {
    const _pendingInvitation = sessionStorage?.getItem("invitationId") || ""

    function handlePersonalClick(e) {
        onChange("personal")
        onForward()
    }

    function handleBusinessClick(e) {
        onChange("business")
        onForward()
    }

    return <>
        <div>
            <div className="Register-Desktop-Steps-Wrapper">
                <div className="Register-Desktop-Steps-Content">
                    <div className="Register-Content-Header">
                        <Trans i18nKey='register:accountType'>
                            <h1 className="Register-Heading2">What kind of account would you like to
                                open<b>?</b>
                            </h1>
                        </Trans>
                    </div>
                    <div className="Register-Content-Body Register-Content-Body--Horizontal">
                        {_pendingInvitation && (
                            <>
                                <button type="button"
                                        className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                                        onClick={handlePersonalClick}>
                                    <Trans i18nKey='register:personalAccount'>Personal account</Trans>
                                </button>
                                <div className="Register-Content-Spacer"/>
                                <div className="Register-Content-Spacer"/>
                            </>
                        )}

                        <button type="button"
                                className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                                onClick={handleBusinessClick}>
                            <Trans i18nKey='register:businessAccount'>Business account</Trans>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

function CountrySelector({valid, onChange}) {
    const {t} = useTranslation('register')
    const [query, setQuery] = useState('')
    const [selectedCountry, setSelectedCountry] = useState(null)
    const btnRef = useRef()

    const _countryList = [
        {
            "name": t("choose-country"), "tel_code": "", "code": "",
            "text": t("choose-country"),
        },
        ...countryList
    ]

    const filteredCountries =
        query === ''
            ? _countryList
            : countryList.filter((person) => {
                return person.name.toLowerCase().includes(query.toLowerCase())
            })

    function handleChange(value) {
        setSelectedCountry(value)
        onChange(value)
    }

    useEffect(() => {
        if (onChange) {
            //handleChange(countryList.find((country) => country.code === 'SI'))
        }
    }, [])

    return (
        <div className="relative w-full sm:max-w-[288px]">
            <Combobox as="div" value={selectedCountry} onChange={handleChange}>
                <div className="flex gap-2 relative w-full sm:max-w-[288px] dark">
                    <Combobox.Input
                        autoFocus={true}
                        style={{colorScheme: "dark"}}
                        className={classNames(
                            "basis-auto peer w-full border-0 rounded-t-sm !bg-primary-500 !autofill:bg-primary-500 py-1.5 text-white disabled:text-primary-200 placeholder:text-primary-200 shadow-sm focus:ring-0 sm:text-sm sm:leading-6",
                            /*hideSubmit ? "basis-full" : "basis-auto"*/
                        )}
                        onChange={(event) => setQuery(event.target.value)}
                        autoComplete="off"
                        displayValue={(person) => person?.name}
                        /*onFocus={() => btnRef.current?.click()}*/
                        required
                        placeholder={t("countryPlaceholder")}
                    />
                    <div
                        className="absolute inset-x-0 bottom-0 border-t border-primary-300 peer-focus:border-t-2 peer-focus:border-primary-600 peer-[[aria-invalid]]:border-red-600"
                        aria-hidden="true"
                    />
                    <Combobox.Button
                        type="button"
                        ref={btnRef}
                        className="absolute inset-y-0 right-8 flex-- items-center rounded-r-md px-2 focus:outline-none">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-200" aria-hidden="true"/>
                    </Combobox.Button>
                    <button
                        type="submit"
                        disabled={!valid}
                        className="basis-8 opacity-100 disabled:opacity-40 disabled:cursor-default flex items-center my-1 rounded-md bg-primary-400 p-1 text-white disabled:bg-primary-300 disabled:text-primary-50 shadow-sm hover:bg-primary-300 disabled:hover:bg-primary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                    >
                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                    </button>
                </div>

                {filteredCountries.length > 0 && (
                    <Combobox.Options
                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredCountries.map((item) => (
                            <Combobox.Option
                                key={item.code || "empty"}
                                value={item}
                                disabled={!item.code}
                                className={({active}) =>
                                    classNames(
                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                        active ? 'bg-primary-600 text-white' : !item.code ? 'text-gray-500' : 'text-gray-900'
                                    )
                                }
                            >
                                {({active, selected}) => (
                                    <>
                                    <span
                                        className={classNames('block truncate text-left', selected && 'font-semibold')}>{item.name}</span>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                    active ? 'text-white' : 'text-primary-600'
                                                )}
                                            >
                        <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                      </span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </Combobox>
        </div>
    )
}

function RegisterPersonalCountry(props) {
    const [value, setValue] = useState(props.value || {})
    const [valid, setValid] = useState(props.valid || false)

    function handleChange(value) {
        const [valid, error] = props.onChange(value);
        setValue(value)
        setValid(valid)
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (valid) {
            const verifiedValue = Object.assign({valid: true}, value);
            const [valid, error] = props.onChange(verifiedValue);
            setValue(verifiedValue)
            setValid(valid)

            props.onForward()
        }
    }

    return (
        <div className="Register-Desktop-Steps-Wrapper">
            <RegisterDesktopSteps step={0} completed={props.completed} inputData={props.inputData}/>
            <div className="Register-Desktop-Steps-Content">
                <form onSubmit={handleSubmit}>
                    <div className="Register-Content-Header">
                        {/*<Trans i18nKey='register:countryOfCitizenship'>
                            <h1 className="Register-Heading2">What is your country of citizenship<b>?</b></h1>
                        </Trans>*/}
                        Izberite državo vašega davčnega rezidentstva
                    </div>
                    <div className="Register-Content-Body">
                        <CountrySelector valid={valid} onChange={handleChange}/>
                    </div>
                </form>
            </div>
        </div>
    );
}

function RegisterBusinessCountry(props) {
    const [value, setValue] = useState(props.value || {})
    const [valid, setValid] = useState(props.valid || false)

    function handleChange(value) {
        const [valid, error] = props.onChange(value);
        setValue(value)
        setValid(valid)
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (valid) {
            const verifiedValue = Object.assign({valid: true}, value);
            const [valid, error] = props.onChange(verifiedValue);
            setValue(verifiedValue)
            setValid(valid)
            props.onForward()
        }
    }

    //const {stringValue, valid, datalist, fetching} = this.state;
    return (
        <div className="Register-Desktop-Steps-Wrapper">
            <RegisterDesktopSteps step={0} completed={props.completed} inputData={props.inputData}/>
            <div className="Register-Desktop-Steps-Content">
                <form onSubmit={handleSubmit}>
                    <div className="Register-Content-Header">
                        <Trans i18nKey='register:companyCountry'>
                            <h1 className="Register-Heading2">In which country is your company based?</h1>
                        </Trans>
                    </div>
                    <div className="Register-Content-Body">
                        <CountrySelector valid={valid} onChange={handleChange}/>
                    </div>
                </form>
            </div>
        </div>
    );
}

function RegisterBusinessCountryNotSupported() {
    return (
        <div className="Register-Desktop-Steps-Wrapper">
            <div className="Register-Desktop-Steps-Content">
                <div>
                    <div className="Register-Content-Header">
                        <Trans i18nKey='register:countryNotSupportedHeading1'>
                            <h1 className="Register-Heading">Sorry, we don't support your country yet.</h1>
                        </Trans>
                        <div className="Register-Content-Body">
                            <Link to={"/"}
                                  className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20">
                                <Trans i18nKey='register:backToLogin'>Back to login</Trans>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function RegisterBusinessLegalRepresentativeError() {
    return (
        <div className="Register-Desktop-Steps-Wrapper">
            <div className="Register-Desktop-Steps-Content">
                <div>
                    <div className="Register-Content-Header">
                        <Trans i18nKey='register:legalRepresentativeErrorHeading1'>
                            <h1 className="Register-Heading">Sorry, you must be a legal <b>representative</b>.</h1>
                        </Trans>
                        <Trans i18nKey='register:legalRepresentativeErrorHeading2'>
                            <span className="Register-Subtitle text-sm">Only legal representatives can open an account on behalf of the company.</span>
                        </Trans>
                    </div>
                    <div className="Register-Content-Body">
                        <Link to="/">
                            <button
                                className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20">
                                <Trans i18nKey='register:backToHomepage'>Back to homepage</Trans>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

// TODO: check if business is already registered
function RegisterBusinessVat(props) {
    const {t} = useTranslation('register')
    const inputRef = useRef();
    const [defaultValue, setDefaultValue] = useState("")
    const [error, setError] = useState()
    const [fetching, setFetching] = useState(false)

    useEffect(() => {
        setDefaultValue(props.value || "")
    }, [])

    function validate(...args) {
        const [valid, err] = props.onChange(...args)
        setError(err)
        return [args[0], valid]
    }

    function handleSubmit(e) {
        e.preventDefault();

        const [value, valid] = validate(inputRef.current?.value)

        if (valid) {
            setFetching(true)
            fetch(INVEST_API + 'companies?tax_no=' + encodeURIComponent(value), {
                method: 'get',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                },
            }).then(res => {
                if (res.status === 404) setError('company-vat-not-found')
                else return res.json();
            }).then(res => {
                if (typeof res === "object") {
                    const companyData = {};
                    companyData["oid"] = res.Id;
                    companyData["name"] = res.name;
                    companyData["taxNo"] = res.taxNo;
                    companyData["vatNo"] = res.vatNo;
                    companyData["businessCategoryCode"] = res.businessCategoryCode;
                    companyData["registrationNumber"] = res.registrationNo;
                    companyData["vatLiable"] = res.vatLiable === true;
                    if (res.hasOwnProperty("address")) {
                        companyData["address"] = res.address["streetAndNumber"];
                        companyData["postalCode"] = res.address["postCode"];
                        companyData["city"] = res.address["city"];
                        companyData["administrativeUnit"] = res.address["administrativeUnit"];
                    }
                    const [value, valid] = validate(res.taxNo, ["company", companyData])
                    if (!valid) setError('something-wrong')
                    else props.onForward();
                }
            }).catch(err => {
                console.error(err)
                setError(err.message || 'something-wrong')
            }).finally(() => {
                setFetching(false)
            })
        }
    }


    return (
        <form onSubmit={handleSubmit}>
            <div className="Register-Content-Header">
                <Trans i18nKey='register:companyVat'>
                    <h1 className="Register-Heading2">What is your company VAT registration number<b>?</b></h1>
                </Trans>
            </div>
            <div className="Register-Content-Body">
                <TextField
                    ref={inputRef}
                    name={"taxNo"}
                    defaultValue={defaultValue}
                    fetching={fetching}
                    error={error}
                    placeholder={t("vatPlaceholder")}
                    hideSubmit={true}
                />
                <button disabled={fetching} type="submit"
                        className="mt-8 inline-flex justify-center rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20 disabled:cursor-default disabled:hover:bg-white/10">
                    {fetching && <TailSpinner/>}
                    <Trans i18nKey='register:confirm'>I confirm</Trans>
                </button>
            </div>
        </form>
    )
}

function RegisterBusinessCompany(props) {
    const {t} = useTranslation('register')

    function handleSubmit(e) {
        e.preventDefault();

        if (props.valid) {
            props.onForward()
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="Register-Content-Header">
                <Trans i18nKey='register:confirmCompanyData'>
                    <h1 className="Register-Heading2">Is your company data correct?</h1>
                </Trans>
            </div>
            <div className="Register-Content-Body Register-Content-Body--With-Action flex flex-col gap-2">
                <TextField
                    name="name"
                    defaultValue={props.value.name}
                    autoComplete="organization"
                    label={t("helperCompanyName")}
                    required
                    hideSubmit={true}
                    disabled
                />
                <TextField
                    name="address"
                    defaultValue={props.value.address}
                    placeholder={t("placeholderAddress")}
                    autoComplete="street-address"
                    label={t("helperStreetAddress")}
                    required
                    hideSubmit={true}
                    disabled
                />
                <div className="flex max-w-[288px] gap-2">
                    <div className="basis-1/3 shrink">
                        <TextField
                            name="postalCode"
                            defaultValue={props.value.postalCode}
                            placeholder={t("placeholderPostalCode")}
                            autoComplete="postal-code"
                            label={t("helperPostalCode")}
                            required
                            hideSubmit={true}
                            disabled
                        />
                    </div>
                    <div className="basis-2/3 shrink grow">
                        <TextField
                            name="city"
                            defaultValue={props.value.city}
                            placeholder={t("placeholderCity")}
                            autoComplete="address-level2"
                            label={t("helperCity")}
                            required
                            hideSubmit={true}
                            disabled
                        />
                    </div>
                </div>
                <button
                    type="submit"
                    className="mt-8 inline-flex justify-center rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20 disabled:cursor-default disabled:hover:bg-white/10">
                    <Trans i18nKey='register:confirm'>I confirm</Trans>
                </button>
            </div>
        </form>
    )
}

function RegisterBusinessLegal(props) {
    const {t} = useTranslation('register')

    function validate(...args) {
        const [valid, err] = props.onChange(...args)
        return [args[0], valid]
    }

    function handleChange(e) {
        const [value, valid] = validate(e.target.value)
    }

    function handleSubmit(e) {
        e.preventDefault();
        props.onForward()
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="Register-Content-Header">
                <Trans i18nKey='register:legalRepresentative'>
                    <h1 className="Register-Heading2">Are you a legal representative of the company?</h1>
                </Trans>
            </div>
            <div className="Register-Content-Body Register-Content-Body--With-Action">
                <fieldset>
                    <legend className="sr-only">{t("legalRepresentative")}</legend>
                    <div className="space-y-5">
                        <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                                <input
                                    autoFocus
                                    id="yes"
                                    onChange={handleChange}
                                    checked={props.value === "on"}
                                    value="on"
                                    name="legalRepresentative"
                                    type="radio"
                                    className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600"
                                    required
                                />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                                <label htmlFor="yes" className="font-medium text-white">
                                    {t("YES")}
                                </label>
                            </div>
                        </div>
                        <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                                <input
                                    id="no"
                                    onChange={handleChange}
                                    checked={props.value === "off"}
                                    value="off"
                                    name="legalRepresentative"
                                    type="radio"
                                    className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600"
                                    required
                                />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                                <label htmlFor="no" className="font-medium text-white">
                                    {t("NO")}
                                </label>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <button type="submit"
                        className="mt-8 inline-flex justify-center rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20 disabled:cursor-default disabled:hover:bg-white/10">
                    <Trans i18nKey='register:confirm'>I confirm</Trans>
                </button>
            </div>
        </form>
    )
}

function RegisterPersonalFirstName(props) {
    const {t} = useTranslation('register')
    const inputRef = useRef();
    const [defaultValue, setDefaultValue] = useState("")
    const [error, setError] = useState()

    useEffect(() => {
        setDefaultValue(props.value || "")
    }, [])

    function validate(...args) {
        const [valid, err] = props.onChange(...args)
        setError(err)
        return [args[0], valid]
    }

    function handleChange(e) {
        const [valid, err] = validate(e.target.value);
    }

    function handleSubmit(e) {
        e.preventDefault();

        const [value, valid] = validate(inputRef.current?.value)

        if (valid) {
            props.onForward()
        }
    }

    return (
        <div className="Register-Desktop-Steps-Wrapper">
            <RegisterDesktopSteps step={0} completed={props.completed} inputData={props.inputData}/>
            <div className="Register-Desktop-Steps-Content">
                <form onSubmit={handleSubmit}>
                    <div className="Register-Content-Header">
                        <h1 className="Register-Heading2">{t('headingFirstName')}</h1>
                    </div>
                    <div className="Register-Content-Body">
                        <TextField
                            ref={inputRef}
                            name={"firstName"}
                            defaultValue={defaultValue}
                            placeholder={t("placeholderFirstName")}
                            autoComplete={"given-name"}
                            error={error}
                            required
                        />
                    </div>

                </form>
            </div>
        </div>
    )
}

function RegisterPersonalLastName(props) {
    const {t} = useTranslation('register')
    const inputRef = useRef();
    const [defaultValue, setDefaultValue] = useState("")
    const [error, setError] = useState()

    useEffect(() => {
        setDefaultValue(props.value || "")
    }, [])

    function validate(...args) {
        const [valid, err] = props.onChange(...args)
        setError(err)
        return [args[0], valid]
    }

    function handleChange(e) {
        const [valid, err] = validate(e.target.value);
    }

    function handleSubmit(e) {
        e.preventDefault();

        const [value, valid] = validate(inputRef.current?.value)

        if (valid) {
            props.onForward()
        }
    }

    return (
        <div className="Register-Desktop-Steps-Wrapper">
            <RegisterDesktopSteps step={0} completed={props.completed} inputData={props.inputData}/>
            <div className="Register-Desktop-Steps-Content">
                <form onSubmit={handleSubmit}>
                    <div className="Register-Content-Header">
                        <h1 className="Register-Heading2">
                            {t('headingLastName')}{" "}{props.inputData.personalFirstName}?
                        </h1>
                    </div>
                    <div className="Register-Content-Body">
                        <TextField
                            ref={inputRef}
                            name={"lastName"}
                            defaultValue={defaultValue}
                            placeholder={t("placeholderLastName")}
                            autoComplete={"family-name"}
                            error={error}
                            required
                        />
                    </div>

                </form>
            </div>
        </div>
    )
}

function RegisterPersonalDateOfBirth(props) {
    const {t} = useTranslation('register')
    const inputRef = useRef();
    const [defaultValue, setDefaultValue] = useState("")
    const [error, setError] = useState()

    useEffect(() => {
        setDefaultValue(`${props.value?.year || ""}-${props.value?.month || ""}-${props.value?.dayOfMonth || ""}`)
    }, [])

    function validate(dateString) {
        const date = dateString.split("-"),
            value = {
                dayOfMonth: date[2],
                month: date[1],
                year: date[0],
            }

        const [valid, err] = props.onChange(value)
        setError(err)
        return [value, valid]
    }

    function handleChange(e) {
        const [valid, err] = validate(e.target.value);
    }

    function handleSubmit(e) {
        e.preventDefault();

        const [value, valid] = validate(inputRef.current?.value)

        if (valid) {
            props.onForward()
        }
    }

    return (
        <div className="Register-Desktop-Steps-Wrapper">
            <RegisterDesktopSteps step={0} completed={props.completed} inputData={props.inputData}/>
            <div className="Register-Desktop-Steps-Content">
                <form onSubmit={handleSubmit}>
                    <div className="Register-Content-Header">
                        <h1 className="Register-Heading2">
                            <Trans i18nKey='register:headingDateOfBirth'>When were you born,</Trans>{" "}
                            {props.inputData.personalFirstName}?
                        </h1>
                    </div>
                    <div className="Register-Content-Body flex flex-col gap-2">
                        <TextField
                            ref={inputRef}
                            name="dob"
                            type="date"
                            pattern="\d{4}-\d{2}-\d{2}"
                            defaultValue={defaultValue}
                            placeholder={t("placeholderDateDay")}
                            autoComplete="bday"
                            error={error}
                            required
                        />
                    </div>

                </form>
            </div>
        </div>
    )
}

function RegisterPersonalEmail(props) {
    const {t} = useTranslation('register')
    const inputRef = useRef();
    const [defaultValue, setDefaultValue] = useState("")
    const [error, setError] = useState()
    const [fetching, setFetching] = useState(false)

    useEffect(() => {
        setDefaultValue(props.value || "")
    }, [])

    function validate(...args) {
        const [valid, err] = props.onChange(...args)
        setError(err)
        return [args[0], valid]
    }

    function handleChange(e) {
        const [valid, err] = validate(e.target.value);
    }

    function handleSubmit(e) {
        e.preventDefault();

        const [value, valid] = validate(inputRef.current?.value)

        if (valid) {
            setFetching(true);

            fetchSignInMethodsForEmail(auth, value)
                .then(signInMethods => {
                    if (signInMethods.length > 0) {
                        // email already exists
                        setError("email-taken")
                    } else {
                        setError(null)
                        props.onForward()
                    }
                })
                .catch(err => {
                    console.error(err)
                    setError(err.message || "somethingWentWrongPleaseTryAgain")
                })
                .finally(() => {
                    setFetching(false)
                })
            //props.onForward()
        }
    }

    return (
        <div className="Register-Desktop-Steps-Wrapper">
            <RegisterDesktopSteps step={1} completed={props.completed} inputData={props.inputData}/>
            <div className="Register-Desktop-Steps-Content">
                <form onSubmit={handleSubmit}>
                    <div className="Register-Content-Header">
                        <h1 className="Register-Heading2">
                            <Trans i18nKey='register:headingEmail'>What is your email address,</Trans>{" "}
                            {props.inputData.personalFirstName}?
                        </h1>
                    </div>
                    <div className="Register-Content-Body">
                        <TextField
                            ref={inputRef}
                            /*onChange={handleChange}*/
                            name="email"
                            defaultValue={defaultValue}
                            placeholder={t("placeholderEmail")}
                            autoComplete={"email"}
                            fetching={fetching}
                            error={error}
                            required
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}

function RegisterPersonalAddress(props) {
    const {t} = useTranslation('register')
    const [defaultValue, setDefaultValue] = useState("")
    const [error, setError] = useState()
    const [invalidForm, setInvalidForm] = useState(false)
    const [inputValue, setInputValue] = useState({})

    useEffect(() => {
        setDefaultValue(props.value || {})
        setInputValue(props.value || {})
    }, [])

    function validate(...args) {
        const [valid, err] = props.onChange(...args)
        setError(err)
        setInvalidForm(err?.[0] && Object.keys(err[1]).length === 0)
        return [args[0], valid]
    }

    function handleChange(e) {
        const value = inputValue || {}
        value[e.target.name] = e.target.value
        setInputValue(value)
    }

    function handleSubmit(e) {
        e.preventDefault();
        const [value, valid] = validate(inputValue)
        if (valid) {
            props.onForward()
        }
    }

    return (
        <div className="Register-Desktop-Steps-Wrapper">
            <RegisterDesktopSteps step={1} completed={props.completed} inputData={props.inputData}/>
            <div className="Register-Desktop-Steps-Content">
                <form onSubmit={handleSubmit}>
                    <div className="Register-Content-Header">
                        <h1 className="Register-Heading2">
                            <Trans i18nKey='register:headingAddress'>What is your address,</Trans>{" "}
                            {props.inputData.personalFirstName}?
                        </h1>
                    </div>
                    <div className="Register-Content-Body flex flex-col gap-2">
                        <div className="flex max-w-[288px] gap-2">
                            <div className="basis-2/3 shrink">
                                <TextField
                                    name="streetName"
                                    defaultValue={defaultValue.streetName}
                                    placeholder={t("placeholderStreetName")}
                                    autoComplete="address-level3"
                                    label={t("helperStreetName")}
                                    onChange={handleChange}
                                    error={error?.[1]?.streetName || invalidForm || null}
                                    required
                                    hideSubmit={true}
                                />
                            </div>
                            <div className="basis-1/3 shrink grow">
                                <TextField
                                    name="streetNumber"
                                    defaultValue={defaultValue.streetNumber}
                                    placeholder={t("placeholderStreetNumber")}
                                    autoComplete="address-level4"
                                    label={t("helperStreetNumber")}
                                    onChange={handleChange}
                                    error={error?.[1]?.streetNumber || invalidForm || null}
                                    required
                                    hideSubmit={true}
                                    autoFocus={false}
                                />
                            </div>
                        </div>
                        <div className="flex max-w-[288px] gap-2">
                            <div className="basis-1/3 shrink">
                                <TextField
                                    name="postalCode"
                                    defaultValue={defaultValue.postalCode}
                                    placeholder={t("placeholderPostalCode")}
                                    autoComplete="postal-code"
                                    label={t("helperPostalCode")}
                                    onChange={handleChange}
                                    error={error?.[1]?.postalCode || invalidForm || null}
                                    required
                                    hideSubmit={true}
                                    autoFocus={false}
                                />
                            </div>
                            <div className="basis-2/3 shrink grow">
                                <TextField
                                    name="city"
                                    defaultValue={defaultValue.city}
                                    placeholder={t("placeholderCity")}
                                    autoComplete="address-level2"
                                    label={t("helperCity")}
                                    onChange={handleChange}
                                    error={error?.[1]?.city || invalidForm || null}
                                    required
                                    autoFocus={false}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

function RegisterPersonalConfirm(props) {
    const {t} = useTranslation('register')
    //const [defaultValue, setDefaultValue] = useState("")
    const [error, setError] = useState()
    const [fetching, setFetching] = useState(false)
    const [invalidForm, setInvalidForm] = useState(false)

    /*useEffect(() => {
        setDefaultValue(props.value || {})
    }, [])*/

    function validate(...args) {
        const [valid, err] = props.onChange(...args)
        setError(err)
        setInvalidForm(err?.[0] && Object.keys(err[1]).length === 0)
        return [args[0], valid]
    }

    function handleChange(e) {
        const value = props.value || {}

        let pointer = value;
        e.target.name.split('.').forEach((name, i, arr) => {
            if (i === arr.length - 1) {
                // last
                if (name === "personalDateOfBirth") {
                    const date = e.target.value.split("-")
                    pointer[name] = {
                        dayOfMonth: date[2],
                        month: date[1],
                        year: date[0],
                    }
                } else pointer[name] = e.target.value
            } else {
                if (!pointer.hasOwnProperty(name)) {
                    pointer[name] = {};
                }
                pointer = pointer[name]
            }
        });
        validate(value)
    }

    function handleSubmit(e) {
        e.preventDefault();

        const [value, valid] = validate(props.value)

        if (valid) {
            setFetching(true);

            fetchSignInMethodsForEmail(auth, value.email)
                .then(signInMethods => {
                    if (signInMethods.length > 0) {
                        // email already exists
                        setError(["required", {"email": "email-taken"}])
                    } else {
                        props.onForward()
                    }
                })
                .finally(() => setFetching(false))

        }
    }

    return (
        <div className="Register-Desktop-Steps-Wrapper">
            <RegisterDesktopSteps step={1} completed={props.completed} inputData={props.inputData}/>
            <div className="Register-Desktop-Steps-Content">
                <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="Register-Content-Header">
                        <h1 className="Register-Heading2">
                            <Trans i18nKey='register:headingConfirmData'>Is your personal data correct,</Trans>{" "}
                            {props.inputData.personalFirstName}?
                        </h1>
                        <Trans i18nKey='register:subtitleConfirmData'>
                                <span
                                    className="Register-Subtitle text-sm">To finalize registration please confirm your data</span>
                        </Trans>
                    </div>
                    <div className="Register-Content-Body Register-Content-Body--With-Action flex flex-col gap-2">
                        <div className="flex max-w-[288px] gap-2">
                            <div className="basis-1/2 shrink grow">
                                <TextField
                                    autoFocus={false}
                                    name="accountType"
                                    value={t(props.value?.accountType)}
                                    autoComplete="off"
                                    label={t("helperAccountType")}
                                    error={error?.[1]?.accountType || invalidForm || null}
                                    required
                                    disabled
                                    hideSubmit={true}
                                />
                            </div>
                            <div className="basis-1/2 shrink grow">
                                <TextField
                                    autoFocus={false}
                                    name="country"
                                    value={props.value?.country?.name}
                                    autoComplete="off"
                                    label={t("helperCountry")}
                                    error={error?.[1]?.country || invalidForm || null}
                                    required
                                    disabled
                                    hideSubmit={true}
                                />
                            </div>
                        </div>

                        <div className="flex max-w-[288px] gap-2">
                            <div className="basis-1/2 shrink grow">
                                <TextField
                                    autoFocus={false}
                                    name="personalFirstName"
                                    value={props.value?.personalFirstName}
                                    autoComplete="off"
                                    label={t("helperFirstName")}
                                    onChange={handleChange}
                                    error={error?.[1]?.personalFirstName || invalidForm || null}
                                    required
                                    hideSubmit={true}
                                />
                            </div>
                            <div className="basis-1/2 shrink grow">
                                <TextField
                                    autoFocus={false}
                                    name="personalLastName"
                                    value={props.value?.personalLastName}
                                    autoComplete="off"
                                    label={t("helperLastName")}
                                    onChange={handleChange}
                                    error={error?.[1]?.personalLastName || invalidForm || null}
                                    required
                                    hideSubmit={true}
                                />
                            </div>
                        </div>

                        <TextField
                            autoFocus={false}
                            type="date"
                            pattern="\d{4}-\d{2}-\d{2}"
                            name="personalDateOfBirth"
                            value={props.value?.personalDateOfBirth ? `${props.value?.personalDateOfBirth?.year || ""}-${props.value?.personalDateOfBirth?.month || ""}-${props.value?.personalDateOfBirth?.dayOfMonth || ""}` : undefined}
                            autoComplete="off"
                            label={t("helperDateOfBirth")}
                            onChange={handleChange}
                            error={error?.[1]?.personalDateOfBirth || invalidForm || null}
                            required
                            hideSubmit={true}
                        />

                        <TextField
                            autoFocus={false}
                            name="email"
                            value={props.value?.email}
                            autoComplete="off"
                            label={t("helperEmail")}
                            onChange={handleChange}
                            error={error?.[1]?.email || invalidForm || null}
                            required
                            hideSubmit={true}
                        />

                        <div className="flex max-w-[288px] gap-2">
                            <div className="basis-2/3 shrink grow">
                                <TextField
                                    autoFocus={false}
                                    name="personalAddress.streetName"
                                    value={props.value?.personalAddress?.streetName}
                                    autoComplete="off"
                                    label={t("helperStreetName")}
                                    onChange={handleChange}
                                    error={error?.[1]?.["personalAddress.streetName"] || error?.[1]?.personalAddress || invalidForm || null}
                                    required
                                    hideSubmit={true}
                                />
                            </div>
                            <div className="basis-1/3 shrink grow">
                                <TextField
                                    autoFocus={false}
                                    name="personalAddress.streetNumber"
                                    value={props.value?.personalAddress?.streetNumber}
                                    autoComplete="off"
                                    label={t("helperStreetNumber")}
                                    onChange={handleChange}
                                    error={error?.[1]?.["personalAddress.streetNumber"] || error?.[1]?.personalAddress || invalidForm || null}
                                    required
                                    hideSubmit={true}
                                />
                            </div>
                        </div>

                        <div className="flex max-w-[288px] gap-2">
                            <div className="basis-1/3 shrink grow">
                                <TextField
                                    autoFocus={false}
                                    name="personalAddress.postalCode"
                                    value={props.value?.personalAddress?.postalCode}
                                    autoComplete="off"
                                    label={t("helperPostalCode")}
                                    onChange={handleChange}
                                    error={error?.[1]?.["personalAddress.postalCode"] || error?.[1]?.personalAddress || invalidForm || null}
                                    required
                                    hideSubmit={true}
                                />
                            </div>
                            <div className="basis-2/3 shrink grow">
                                <TextField
                                    autoFocus={false}
                                    name="personalAddress.city"
                                    value={props.value?.personalAddress?.city}
                                    autoComplete="off"
                                    label={t("helperCity")}
                                    onChange={handleChange}
                                    error={error?.[1]?.["personalAddress.city"] || error?.[1]?.personalAddress || invalidForm || null}
                                    required
                                    hideSubmit={true}
                                />
                            </div>
                        </div>

                        <button type="submit"
                                disabled={fetching}
                                className="mt-8 inline-flex justify-center rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20 disabled:cursor-default disabled:hover:bg-white/10">
                            {fetching && <TailSpinner/>}
                            <Trans i18nKey='register:confirm'>I confirm</Trans>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

function RegisterChoosePassword(props) {
    const {t} = useTranslation('register')
    const [defaultValue, setDefaultValue] = useState("")
    const [error, setError] = useState()
    const [passVisible, setPassVisible] = useState(false)

    useEffect(() => {
        setDefaultValue(props.value || {})
    }, [])

    function validate(...args) {
        const [valid, err] = props.onChange(...args)
        setError(err)
        return [args[0], valid]
    }

    function handleChange(e) {
        const value = props.value || {}
        value[e.target.name] = e.target.value;
        validate(value)
    }

    function handleSubmit(e) {
        e.preventDefault();
        const [value, valid] = validate(props.value)
        if (valid) {
            if (props.value?.strength?.weak) {
                if (!window.confirm(t("weakPasswordConfirmation"))) {
                    return;
                }
            }
            props.onForward()
        }
    }

    function togglePasswordVisibility(e) {
        setPassVisible(!passVisible)
    }

    return (
        <div className="Register-Desktop-Steps-Wrapper">
            <RegisterDesktopSteps step={2} completed={props.completed} inputData={props.inputData}/>
            <div className="Register-Desktop-Steps-Content">
                <form onSubmit={handleSubmit}>
                    <input hidden aria-hidden="true" type="email" autoComplete="username"
                           defaultValue={props.inputData.email || ""}/>
                    <div className="Register-Content-Header">
                        <h1 className="Register-Heading2">
                            <Trans i18nKey='register:headingPassword'>Select your password,</Trans>{" "}
                            {props.inputData.personalFirstName}!
                        </h1>
                    </div>
                    <div className="Register-Content-Body">
                        <TextField
                            name="password"
                            type={passVisible ? "text" : "password"}
                            defaultValue={defaultValue?.password}
                            autoComplete="new-password"
                            placeholder={t("placeholderPassword")}
                            error={error?.[1]?.password || null}
                            onChange={handleChange}
                            required
                            hideSubmit={true}
                        >
                            <button
                                type="button"
                                className="absolute inset-y-0 right-0 my-2 mr-3 rounded-full bg-primary-500 p-0.5 text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                                onClick={togglePasswordVisibility}>
                                {
                                    passVisible ? (
                                        <EyeSlashIcon className="h-4 w-4 text-primary-50" aria-hidden="true"/>
                                    ) : (
                                        <EyeIcon className="h-4 w-4 text-primary-50" aria-hidden="true"/>
                                    )
                                }
                            </button>
                        </TextField>
                        <div className="Register-Row Register-Row--No-Margin-Top">
                            <div className="Register-Password-Meter">
                                <Trans i18nKey='register:passwordStrength'>Password strength: </Trans><span
                                className={classNames(
                                    "Register-Password-Meter-State",
                                    props.value?.strength?.class
                                )}>{t(props.value?.strength?.text)}</span>
                            </div>
                        </div>
                        <div className="Register-Row Register-Row--No-Margin-Top">
                            <Trans i18nKey='register:passwordHint'>
                                <ul className="Register-Hint">
                                    <li>Use at least 8 characters.</li>
                                    <li>Besides letters, include at least a number or symbol.</li>
                                    <li>Don't use a password from another site, or something too obvious like your
                                        pet's name.
                                    </li>
                                </ul>
                            </Trans>
                        </div>
                        <div className="mt-2"/>
                        <TextField
                            autoFocus={false}
                            name="passwordRepeat"
                            type={passVisible ? "text" : "password"}
                            defaultValue={defaultValue?.passwordRepeat}
                            autoComplete="new-password"
                            placeholder={t("placeholderRepeatPassword")}
                            error={error?.[1]?.passwordRepeat || null}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}

function RegisterAgreements(props) {
    const {t} = useTranslation('register')
    const [defaultValue, setDefaultValue] = useState("")
    const [error, setError] = useState()
    const [fetching, setFetching] = useState(false)

    useEffect(() => {
        setDefaultValue(props.value || {})
        setFetching(false)
    }, [])

    function validate(...args) {
        const [valid, err] = props.onChange(...args)
        setError(err)
        return [args[0], valid]
    }

    function handleChange(e) {
        const value = props.value || {}
        value[e.target.name] = e.target.checked
        validate(value)
    }

    function handleSubmit(e) {
        e.preventDefault();
        const [value, valid] = validate(props.value)
        if (valid) {
            const data = props.prepareData();
            if (data) {
                setFetching(true)

                api.post(`/auth/signup`, data)
                    .then(res => {
                        if (res.data && typeof res.data === "object") {
                            props.onForward();
                        } else {
                            window.alert(t("error-creating-account"))
                        }
                    })
                    .catch(e => {
                        catchErrors(e, window.alert)
                    })
                    .finally(() => {
                        setFetching(false)
                    })
            }
        }
    }


    return (
        <div className="Register-Desktop-Steps-Wrapper">
            <RegisterDesktopSteps step={3} completed={props.completed} inputData={props.inputData}/>
            <div className="Register-Desktop-Steps-Content">
                <form onSubmit={handleSubmit}>
                    <div className="Register-Content-Header">
                        <h1 className="Register-Heading2">
                            <Trans i18nKey='register:headingAgreements'>We are almost done,</Trans>{" "}
                            {props.inputData.personalFirstName}.
                        </h1>
                        <Trans i18nKey='register:subtitleAgreements'>
                            <span className="Register-Subtitle text-sm">Let's finalize your account.</span>
                        </Trans>
                    </div>
                    <div className="Register-Content-Body Register-Content-Body--With-Action">
                        <fieldset className="max-w-[288px]">
                            <legend className="sr-only">{t('agreements')}</legend>
                            <div className="space-y-5">
                                <div className="relative flex items-start">
                                    <div className="flex h-6 items-center">
                                        <input
                                            autoFocus
                                            id="tosAgreement"
                                            onChange={handleChange}
                                            name="tosAgreement"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
                                            required
                                        />
                                    </div>
                                    <div className="ml-3 text-xs leading-6 text-justify">
                                        <label htmlFor="yes" className="text-white">
                                            <Trans i18nKey='register:agreementsText1'>
                                                I hereby open my Nekster account with accordance to the following legal
                                                documents which I have read and to which I consent: <a
                                                className="!underline !decoration-dotted underline-offset-1">Nekster
                                                Terms and
                                                Conditions</a>; and I confirm that I act on my own behalf.
                                            </Trans>
                                        </label>
                                    </div>
                                </div>
                                <div className="relative flex items-start">
                                    <div className="flex h-6 items-center">
                                        <input
                                            id="privacyAgreement"
                                            onChange={handleChange}
                                            name="privacyAgreement"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
                                            required
                                        />
                                    </div>
                                    <div className="ml-3 text-xs leading-6 text-justify">
                                        <label htmlFor="yes" className="text-white">
                                            <Trans i18nKey='register:agreementsText2'>
                                                Our <a className="!underline !decoration-dotted underline-offset-1">Privacy
                                                policy</a> applies.
                                            </Trans>
                                        </label>
                                    </div>
                                </div>
                                <div className="relative flex items-start">
                                    <div className="flex h-6 items-center">
                                        <input
                                            id="notPoliticallyExposed"
                                            onChange={handleChange}
                                            name="notPoliticallyExposed"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
                                            required
                                        />
                                    </div>
                                    <div className="ml-3 text-xs leading-6 text-justify">
                                        <label htmlFor="yes" className="text-white">
                                            <Trans i18nKey='register:agreementsText3'>
                                                I confirm that I am not <a
                                                className="!underline !decoration-dotted underline-offset-1">a
                                                politically exposed person</a>.
                                            </Trans>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <button disabled={fetching} type="submit"
                                className="mt-8 inline-flex justify-center rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20 disabled:cursor-default disabled:hover:bg-white/10">
                            {fetching && <TailSpinner/>}
                            <Trans i18nKey='register:openAccount'>Open my account</Trans>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

function RegisterConfirmEmail() {
    return (
        <div>
            <div className="Register-Content-Header">
                <Trans i18nKey='register:headingConfirmEmail'>
                    <h1 className="Register-Heading2">Check your email <b>inbox</b>.</h1>
                </Trans>
                <Trans i18nKey='register:subtitleConfirmEmail'>
                    <span className="Register-Subtitle text-sm">We have sent you a verification email to start your investing journey</span>
                </Trans>
            </div>
        </div>
    )
}

export function RegisterFinal() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setGlobalLoader(false))
    }, [])

    return (
        <RegisterRoot>
            <div className="Register-Desktop-Steps-Wrapper">
                <div className="Register-Desktop-Steps-Content">
                    <div>
                        <div className="Register-Content-Header">
                            <Trans i18nKey='register:headingFinal'>
                                <h1 className="Register-Heading2"><b>Congratulations!</b></h1>
                            </Trans>
                            <Trans i18nKey='register:headingFinal2'>
                                <h1 className="Register-Heading">Your account is now verified.</h1>
                            </Trans>
                            <Trans i18nKey='register:subtitleFinal'>
                                <span
                                    className="Register-Subtitle text-sm">Start using our platform by logging in</span>
                            </Trans>
                        </div>
                        <div className="Register-Content-Body Register-Content-Body--With-Action">
                            <Link to="/" className="Button Button--Wide Register-Action">
                                <Trans i18nKey='register:loginNow'>Login now</Trans>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </RegisterRoot>
    )
}

const steps = {
    'account-type': {
        href: '/register/account-type',
        component: RegisterStep2,
        fieldName: 'accountType',
        step: 0,
        next: (val) => {
            // clear all data on account type change
            /*if (old && old.length > 0 && val !== old) {
                d.value = {};
                d.value[steps["account-type"].fieldName] = val;
                d.completed = steps["account-type"].step;
            }*/
            switch (val) {
                case "personal": {
                    return steps["personal-country"];
                }
                case "business": {
                    return steps["business-country"];
                }
            }
            return false
        },
        validate: (val) => {
            return val === "personal" || val === "business"
        }
    },
    'business-country': {
        href: '/register/business-country',
        component: RegisterBusinessCountry,
        fieldName: 'country',
        step: 0,
        next: (value, __, d) => {
            if (typeof value === "object" && countries.hasOwnProperty(value.code) && !countries[value.code].enabled) {
                return steps["country-not-supported"]
            }
            if (d.hasOwnProperty("accountType")) {
                if (d.accountType === "personal") {
                    return steps["personal-first-name"]
                } else if (d.accountType === "business") {
                    return steps["business-vat"]
                }
            }
            return steps["account-type"]
        },
        validate: (value) => {
            return typeof value === "object" && countries.hasOwnProperty(value.code) || "required"
        }
    },
    'business-vat': {
        href: '/register/business-vat',
        component: RegisterBusinessVat,
        fieldName: 'businessVAT',
        next: () => {
            return steps["business-company"]
        },
        validate: (value, inputData) => {
            if (typeof value !== "string") return "invalid-type"
            if (value.length < 8) return "too-short"
            const c = inputData.country,
                regex = c && c.hasOwnProperty("vat_regex") ? new RegExp(c.vat_regex) : false;
            if (!regex) return "regex-undefined"
            return regex.test(value) || "invalid-format"
        }
    },
    'business-company': {
        href: '/register/business-company',
        component: RegisterBusinessCompany,
        fieldName: 'company',
        next: () => {
            return steps["business-legal"]
        },
        validate: (value) => {
            return true
        }
    },
    'business-legal': {
        href: '/register/business-legal',
        component: RegisterBusinessLegal,
        fieldName: 'businessLegal',
        next: (value) => {
            if (value !== "on") {
                return steps["legal-representative-error"]
            }
            return steps["personal-first-name"]
        },
        validate: (value) => {
            return true
            //return value === "on"
        }
    },
    'personal-country': {
        href: '/register/personal-country',
        component: RegisterPersonalCountry,
        fieldName: 'country',
        step: 0,
        next: (value, __, d) => {
            if (typeof value === "object" && countries.hasOwnProperty(value.code) && !countries[value.code].enabled) {
                return steps["country-not-supported"]
            }
            if (d.hasOwnProperty("accountType")) {
                if (d.accountType === "personal") {
                    return steps["personal-first-name"]
                } else if (d.accountType === "business") {
                    return steps["business-vat"]
                }
            }
            return steps["account-type"]
        },
        validate: (value) => {
            return typeof value === "object" && countries.hasOwnProperty(value.code) || "required"
        }
    },
    'personal-first-name': {
        href: '/register/personal-first-name',
        component: RegisterPersonalFirstName,
        fieldName: 'personalFirstName',
        step: 0,
        next: () => {
            return steps["personal-last-name"]
        },
        validate: (value) => {
            return value && value.length > 1 || "required"
        }
    },
    'personal-last-name': {
        href: '/register/personal-last-name',
        component: RegisterPersonalLastName,
        fieldName: 'personalLastName',
        step: 0,
        next: () => {
            return steps["personal-date-of-birth"]
        },
        validate: (value) => {
            return value && value.length > 1 || "required"
        }
    },
    'personal-date-of-birth': {
        href: '/register/personal-date-of-birth',
        component: RegisterPersonalDateOfBirth,
        fieldName: 'personalDateOfBirth',
        step: 0,
        next: () => {
            return steps["personal-email"]
        },
        validate: (value) => {
            if (value && typeof value === "object" && value.hasOwnProperty("dayOfMonth")
                && value.hasOwnProperty("month")
                && value.hasOwnProperty("year")
                && value.dayOfMonth && value.month && value.year
            ) {

                const date = new Date(), legalAge = new Date(), dayOfMonth = parseInt(value.dayOfMonth),
                    month = parseInt(value.month), year = parseInt(value.year);


                if (!dayOfMonth || !month || !year) {
                    // error parsing or value is 0
                    return "required"
                }

                if (value.dayOfMonth.length > 2 || value.month.length > 2 || value.year.length !== 4) {
                    // date not in format DD MM YYYY
                    return "invalid-date-format"
                }

                date.setFullYear(year, month - 1, dayOfMonth); // set date

                // save parsed int
                value.dayOfMonthInt = dayOfMonth;
                value.monthInt = month;
                value.yearInt = year;
                value.date = date;

                if (date.getDate() !== dayOfMonth || date.getMonth() !== (month - 1) || date.getFullYear() !== year) {
                    // check if date entered is a valid calendar date
                    return "invalid-date"
                }

                // legal age check
                legalAge.setFullYear(legalAge.getFullYear() - 18);
                return date.getTime() < legalAge.getTime() || "legal-age"
            }
            return "required"
        }
    },
    'personal-email': {
        href: '/register/personal-email',
        component: RegisterPersonalEmail,
        fieldName: 'email',
        step: 1,
        next: () => {
            return steps["personal-address"]
        },
        validate: (value) => {
            return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,18}$/i.test(value) || "invalid-email"
        }
    },
    'personal-address': {
        href: '/register/personal-address',
        component: RegisterPersonalAddress,
        fieldName: 'personalAddress',
        step: 1,
        next: () => {
            return steps["personal-confirm"]
        },
        validate: (value) => {
            if (typeof value === "object") {
                const errArr = {};
                if (!value.hasOwnProperty("streetName") || value.streetName.length <= 1) {
                    errArr["streetName"] = "required"
                }
                if (!value.hasOwnProperty("streetNumber") || value.streetNumber.length < 1) {
                    errArr["streetNumber"] = "required"
                }
                if (!value.hasOwnProperty("postalCode") || value.postalCode.length <= 3) {
                    errArr["postalCode"] = "required"
                }
                if (!value.hasOwnProperty("city") || value.city.length <= 1) {
                    errArr["city"] = "required"
                }

                if (Object.keys(errArr).length === 0) return true
                return ["required", errArr]
            }

            return ["required"]
        }
    },
    'personal-confirm': {
        href: '/register/personal-confirm',
        component: RegisterPersonalConfirm,
        step: 1,
        next: () => {
            return steps["choose-password"]
        },
        validate: (value, inputData) => {
            if (typeof value === "object") {
                const errArr = {};

                // ACCOUNT TYPE
                if (!value.hasOwnProperty("accountType") ||
                    (value.accountType !== "personal" && value.accountType !== "business")) {
                    errArr["accountType"] = "required"
                }

                // COUNTRY
                if (!value.hasOwnProperty("country") || !countries.hasOwnProperty(value.country?.code)) {
                    errArr["country"] = "required"
                } else if (!countries[value.country.code].enabled) {
                    errArr["country"] = "country-not-supported"
                }

                // FIRST NAME
                if (!value.hasOwnProperty("personalFirstName") || value.personalFirstName.length <= 1) {
                    errArr["personalFirstName"] = "required"
                }

                // LAST NAME
                if (!value.hasOwnProperty("personalLastName") || value.personalLastName.length <= 1) {
                    errArr["personalLastName"] = "required"
                }

                // DATE OF BIRTH
                if (!value.hasOwnProperty("personalDateOfBirth") ||
                    !(value.personalDateOfBirth.hasOwnProperty("dayOfMonth") &&
                        value.personalDateOfBirth.hasOwnProperty("month") &&
                        value.personalDateOfBirth.hasOwnProperty("year"))
                ) {
                    errArr["personalDateOfBirth"] = "required"
                } else {
                    const date = new Date(), legalAge = new Date(),
                        dayOfMonth = parseInt(value.personalDateOfBirth.dayOfMonth),
                        month = parseInt(value.personalDateOfBirth.month),
                        year = parseInt(value.personalDateOfBirth.year);

                    if (!dayOfMonth || !month || !year) {
                        errArr["personalDateOfBirth"] = "required"
                    }

                    if (value.personalDateOfBirth.dayOfMonth.length > 2 || value.personalDateOfBirth.month.length > 2 || value.personalDateOfBirth.year.length !== 4) {
                        // date not in format DD MM YYYY
                        errArr["personalDateOfBirth"] = "invalid-date-format"
                    }

                    date.setFullYear(year, month - 1, dayOfMonth); // set date

                    // save parsed int
                    value.personalDateOfBirth.dayOfMonthInt = dayOfMonth;
                    value.personalDateOfBirth.monthInt = month;
                    value.personalDateOfBirth.yearInt = year;
                    value.personalDateOfBirth.date = date;

                    if (date.getDate() !== dayOfMonth || date.getMonth() !== (month - 1) || date.getFullYear() !== year) {
                        // check if date entered is a valid calendar date
                        errArr["personalDateOfBirth"] = "invalid-date"
                    }

                    // legal age check
                    legalAge.setFullYear(legalAge.getFullYear() - 18);
                    if (date.getTime() > legalAge.getTime()) {
                        errArr["personalDateOfBirth"] = "legal-age"
                    }
                }

                // EMAIL
                if (!value.hasOwnProperty("email") || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,18}$/i.test(value.email)) {
                    errArr["email"] = "required"
                }

                // ADDRESS
                if (!value.hasOwnProperty("personalAddress") || typeof value.personalAddress !== "object") {
                    errArr["personalAddress"] = "required"
                } else {
                    if (!value.personalAddress.hasOwnProperty("streetName") || value.personalAddress.streetName.length <= 1) {
                        errArr["personalAddress.streetName"] = "required"
                    }
                    if (!value.personalAddress.hasOwnProperty("streetNumber") || value.personalAddress.streetNumber.length < 1) {
                        errArr["personalAddress.streetNumber"] = "required"
                    }
                    if (!value.personalAddress.hasOwnProperty("postalCode") || value.personalAddress.postalCode.length <= 3) {
                        errArr["personalAddress.postalCode"] = "required"
                    }
                    if (!value.personalAddress.hasOwnProperty("city") || value.personalAddress.city.length <= 1) {
                        errArr["personalAddress.city"] = "required"
                    }
                }


                if (Object.keys(errArr).length === 0) return true
                return ["required", errArr]
            }

            return ["required"]


            //return true
            //return userData.validate(steps['account-type'], steps['personal']['address']);
        }
    },
    'country-not-supported': {
        href: '/register/country-not-supported',
        component: RegisterBusinessCountryNotSupported,
        step: 0,
        next: (_, __, d) => {
            return steps["account-type"]
        },
        validate: () => {
            return false
        }
    },
    'legal-representative-error': {
        href: '/register/legal-representative-error',
        component: RegisterBusinessLegalRepresentativeError,
        step: 0,
        next: (_, __, d) => {
            return steps["account-type"]
        },
        validate: () => {
            return false
        }
    },
    'choose-password': {
        href: '/register/choose-password',
        component: RegisterChoosePassword,
        fieldName: 'password',
        step: 2,
        next: () => {
            return steps["agreements"]
        },
        validate: (value) => {
            // calculate score
            if (typeof value === "object" && value.hasOwnProperty("password")) {
                const password = value.password,
                    strength = zxcvbn(password);
                if (password.length < 8) {
                    strength.text = "passwordTooShort";
                    strength.class = "text-red-300";
                } else if (strength.score <= 0) {
                    strength.text = "passwordUnsafe";
                    strength.class = "text-red-300";
                } else if (strength.score <= 1) {
                    strength.weak = true
                    strength.good = true
                    strength.text = "passwordWeak";
                    strength.class = "text-orange-300";
                } else if (strength.score <= 2) {
                    strength.good = true
                    strength.text = "passwordGood";
                    strength.class = "text-green-300";
                } else if (strength.score <= 3) {
                    strength.good = true
                    strength.text = "passwordStrong";
                    strength.class = "text-green-300";
                } else if (strength.score > 3) {
                    strength.good = true
                    strength.text = "passwordVeryStrong";
                    strength.class = "text-green-300";
                }
                value.strength = strength;
            }

            if (typeof value === "object" && value.hasOwnProperty("password")
                && value.hasOwnProperty("passwordRepeat")) {
                if (value.password.length < 8) {
                    return ["required", {"password": "too-short"}]
                }
                if (value.password !== value.passwordRepeat) {
                    return ["required", {"passwordRepeat": "not-matching"}]
                }
                if (!value.strength?.good) {
                    return ["required", {"password": "low-score"}]
                }
                return true
            }
            return ["required"]
        }
    },
    'agreements': {
        href: '/register/agreements',
        component: RegisterAgreements,
        fieldName: 'agreements',
        step: 3,
        next: () => {
            return steps["confirm-email"]
        },
        validate: (value) => {
            if (typeof value === "object" && value.hasOwnProperty("tosAgreement")
                && value.hasOwnProperty("privacyAgreement")
                && value.hasOwnProperty("notPoliticallyExposed")) {
                return value.tosAgreement === true && value.privacyAgreement === true && value.notPoliticallyExposed === true
            }
            return "required"
        }
    },
    'confirm-email': {
        href: '/register/confirm-email',
        component: RegisterConfirmEmail,
        fieldName: 'emailConfirmed',
        next: () => {
            return steps["final"]
        },
        validate: (value) => {
            return true
        }
    },
    'final': {
        href: '/register/final',
        component: RegisterFinal,
        step: 3,
        validate: () => {
            // check if email is confirmed

            return true
        }
    }
};

function RegistrationSteps() {
    const navigate = useNavigate()
    const params = useParams()
    const [completed, setCompleted] = useState(/*fromLocalStorage[0] ||*/ -1)
    const [timestamp, setTimestamp] = useState(/*fromLocalStorage[1] ||*/ Date.now())
    const [inputData, setInputData] = useState(/*fromLocalStorage[2] ||*/ {accountType: "business"}) // user input data

    const [stepID, setStepID] = useState()
    const [stepValue, setStepValue] = useState()
    const [stepValuePrev, setStepValuePrev] = useState()
    const [hasValidValue, setHasValidValue] = useState(false)
    const [error, setError] = useState(null)
    const [hasChangedValue, setHasChangedValue] = useState(false)
    const [goToNext, setGoToNext] = useState(false)

    const stepPath = params?.step,
        step = steps[stepPath],
        isStepProcessed = step?.href === stepID

    useEffect(() => {
        //console.log("inputData", inputData)
        if (step) {
            const [validatedStep, value, isValid, err] = validateSteps(steps['account-type'], step);

            //console.log("step", validatedStep, value, isValid, err)

            //if (err) console.error(err)
            if (validatedStep.href !== step.href) {
                navigate(validatedStep.href) // should navigate to validatedStep
            } else {
                setStepID(step.href)
                setStepValue(value)
                setStepValuePrev(value)
                setHasValidValue(isValid)
                setError(typeof err === "string" || typeof err === "object" ? err : null)
                setCompleted(step.step)
                setHasChangedValue(false)
            }
        }
    }, [stepPath])

    /*useEffect(() => {
        if (hasValidValue) saveToLocalStorage()
    }, [completed, timestamp, inputData, hasValidValue])*/
    useEffect(() => {
        if (goToNext && step) {
            const next = typeof step.next === "function" ? step.next(stepValue, stepValuePrev, inputData) : null
            next && navigate(next.href)
            setGoToNext(false)
        }
    }, [goToNext])

    // clears user input data
    function clear() {
        setCompleted(-1)
        setTimestamp(0)
        setInputData({})
        //localStorage.removeItem("reg")
    }

    // checks validity of values of steps and goes to the last step if possible
    function validateSteps(start, end) {
        let value = start.fieldName ? inputData[start.fieldName] : inputData,
            errorMsg = start.validate(value, inputData),
            isValid = errorMsg === true,
            next;

        //console.log("validating field name", start.fieldName, "is valid", isValid)

        // return if reached end or value is invalid
        if (start.href === end.href || !isValid) return [start, value, isValid, errorMsg !== true ? errorMsg : null]

        // continue towards "goal" step
        if (typeof start.next === "function") next = start.next(value, value, inputData)

        if (!next) return [start, value, isValid, "not-found"]

        // update number of completed steps (to update DesktopSteps)
        /*if (start && start.hasOwnProperty("step") && (!data.completed || data.completed < start.step)) setData({
            ...data,
            completed: start.step
        })*/

        return validateSteps(next, end)
    }

    function onStepValueChange(input, ...additionalData) {
        const err = step.validate(input, inputData),
            isValid = err === true,
            modifiedData = step.fieldName ? {...inputData, [step.fieldName]: input} : {...input}

        //console.log("input error", err)

        for (let i = 0; i < additionalData.length; i++) {
            const [key, value] = additionalData[i]
            modifiedData[key] = value
        }

        //console.log("onStepValueChange", {input, modifiedData})

        setInputData(modifiedData)
        setStepValuePrev(stepValue)
        setStepValue(input)
        setHasValidValue(isValid)
        setError(typeof err === "string" || typeof err === "object" ? err : null)
        setHasChangedValue(true)

        return [isValid, typeof err === "string" || typeof err === "object" ? err : null]
    }

    function onStepForward() {
        setGoToNext(true)
        //history.push(next.href);
        //saveToLocalStorage(); // because onbeforeunload doesn't work for some mobile devices
    }

    // prepares data object to send to server
    function prepareData() {
        const [step, _, isValid, err] = validateSteps(steps["account-type"], steps["agreements"])
        let object;
        try {
            if (isValid) {
                const dateOfBirth = inputData.personalDateOfBirth,
                    isPersonal = inputData.accountType === "personal",
                    country = inputData.country || {}

                if (isPersonal) object = {
                    accountType: "personal",
                    appName: "finance",
                    email: inputData.email,
                    password: inputData.password.password,
                    contactFirstName: inputData.personalFirstName,
                    contactLastName: inputData.personalLastName,
                    contactDateOfBirth: {
                        dayOfMonth: dateOfBirth.dayOfMonthInt,
                        month: dateOfBirth.monthInt,
                        year: dateOfBirth.yearInt
                    }, contactAddress: {
                        unitNumber: null,
                        street: inputData.personalAddress.streetName,
                        houseNumber: inputData.personalAddress.streetNumber,
                        postCode: inputData.personalAddress.postalCode,
                        city: inputData.personalAddress.city,
                        country: country.name,
                        countryCode: country.code,
                    }
                }
                else object = {
                    accountType: "business",
                    appName: "finance",
                    email: inputData.email,
                    password: inputData.password.password,
                    contactFirstName: inputData.personalFirstName,
                    contactLastName: inputData.personalLastName,
                    contactDateOfBirth: {
                        dayOfMonth: dateOfBirth.dayOfMonthInt,
                        month: dateOfBirth.monthInt,
                        year: dateOfBirth.yearInt
                    },
                    contactAddress: {
                        street: inputData.personalAddress.streetName,
                        houseNumber: inputData.personalAddress.streetNumber,
                        postCode: inputData.personalAddress.postalCode,
                        city: inputData.personalAddress.city,
                        country: country.name,
                        countryCode: country.code,
                    },
                    organizationOid: inputData.company.oid,
                    organizationName: inputData.company.name,
                    organizationTaxNum: inputData.company.taxNo,
                    organizationVatNum: inputData.company.vatLiable ? inputData.company.vatNo : inputData.company.taxNo,

                    organizationRegistrationNumber: inputData.company.registrationNumber,
                    organizationVatLiable: inputData.company.vatLiable,

                    organizationAddress: {
                        address: inputData.company.address,
                        postCode: inputData.company.postalCode,
                        city: inputData.company.city,
                        country: country.name,
                        countryCode: country.code,
                    }
                };
            }
        } catch (e) {
            console.error(e)
        }

        return object
    }

    //window.history.replaceState(null, '', step.href);

    const TagName = step?.component;


    if (!TagName) return <NotFound/>

    // render if reached goal step or if value is not valid
    return <>
        {isStepProcessed && step ? (
            <TagName inputData={inputData} clear={clear}
                     value={step.fieldName ? inputData[step.fieldName] : inputData}
                     valid={hasValidValue} error={error}
                     completed={completed} prepareData={prepareData}
                     onChange={onStepValueChange} onForward={onStepForward}/>
        ) : (
            <h2>Loading ...</h2>
        )}
    </>
}

export function RegisterRoot({children}) {
    return (
        <div className="h-full flex flex-col">
            <RegisterHeader/>
            <RegisterContent>
                <div className="w-full mx-auto max-w-2xl lg:max-w-5xl py-0 px-14">
                    {children}
                </div>
            </RegisterContent>
            <RegisterFooter/>
        </div>
    )
}

export default function Register() {
    const {t} = useTranslation("register")

    useEffect(() => {
        window.trans = t
    }, [])

    return (
        <RegisterRoot>
            <Routes>
                <Route path="" element={<RegisterStep1/>}/>
                <Route path=":step" element={<RegistrationSteps/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </RegisterRoot>
    );
}
