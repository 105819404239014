import {Fragment, useEffect, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {api} from "../../config";
import {useSelector} from "react-redux";
import {realActiveCidSelector} from "../../store/client";
import TailSpinner from "../../components/tailSpinner";
import {classNames} from "../../utils";
import {useTranslation} from "react-i18next";


export default function EditPartnerDialog({data, open, setOpen}) {
    const {t} = useTranslation()
    const cancelButtonRef = useRef(null)

    const activeOrganizationId = useSelector(realActiveCidSelector)

    const [partnerName, setPartnerName] = useState(data?.name || "")
    const [name, setName] = useState(data?.name || "")
    const [syncEnabled, setSyncEnabled] = useState(data?.syncEnabled || false)

    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        if (open) {
            setIsSubmitting(false)
            setPartnerName(data?.name || "")
            setName(data?.name || "")
            setSyncEnabled(data?.syncEnabled || false)
            //console.log("partner",data)
        }
    }, [open])

    function onSubmit(e) {
        e.preventDefault()

        if (isSubmitting) return
        const obj = {
            name: name,
        }
        setIsSubmitting(true)
        return api.put(`/organizations/${activeOrganizationId}/partners/${data?.id}`, obj)
            .then(res => {
                //console.log("data", res.data)
            })
            .catch(error => {
                // TODO: handle error
                //console.log("error", error)
            })
            .finally(() => {
                setIsSubmitting(false)
                setOpen(false)
            })
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel
                            as="div"
                            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                            <form onSubmit={onSubmit}
                                  className="flex h-full flex-col bg-white shadow-xl">
                                <div className="flex-1">
                                    {/* Header */}
                                    <div className="bg-gray-50 px-4 py-6 sm:px-6">
                                        <div className="flex items-start justify-between space-x-3">
                                            <div className="space-y-1">
                                                <Dialog.Title
                                                    className="text-base font-semibold leading-6 text-gray-900">
                                                    {partnerName}
                                                </Dialog.Title>
                                                <p className="text-sm text-gray-500">
                                                    {t("updatePartnerDetails")}
                                                </p>
                                            </div>
                                            {/*<div className="flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    className="text-gray-400 hover:text-gray-500"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    <span className="sr-only">Close panel</span>
                                                    <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                                </button>
                                            </div>*/}
                                        </div>
                                    </div>

                                    {/* Divider container */}
                                    <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                                        {/* Project name */}
                                        <div
                                            className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                            <div>
                                                <label
                                                    htmlFor="partner-name"
                                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                                >
                                                    {t("partnerName")}
                                                </label>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <input
                                                    type="text"
                                                    name="partner-name"
                                                    id="partner-name"
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                                                    value={name}
                                                    onChange={e => setName(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        {/* Project description */}
                                        {/*<div
                                            className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                            <div>
                                                <label
                                                    htmlFor="project-description"
                                                    className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                                >
                                                    Description
                                                </label>
                                            </div>
                                            <div className="sm:col-span-2">
                            <textarea
                                id="project-description"
                                name="project-description"
                                rows={3}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                                defaultValue={''}
                            />
                                            </div>
                                        </div>*/}

                                        {/* Privacy */}

                                        {/*<fieldset
                                            className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                            <legend className="sr-only">Sync</legend>
                                            <div className="text-sm font-medium text-gray-900" aria-hidden="true">
                                                Sync
                                            </div>
                                            <div className="space-y-5 sm:col-span-2">
                                                <div className="space-y-5 sm:mt-0">
                                                    <div className="relative flex items-start">
                                                        <div className="absolute flex h-5 items-center">
                                                            <input
                                                                id="sync-enabled"
                                                                aria-describedby="sync-enabled-description"
                                                                name="sync"
                                                                type="checkbox"
                                                                checked={syncEnabled}
                                                                onChange={e => setSyncEnabled(e.target.checked)}
                                                                className="h-4 w-4 rounded border-gray-300 text-green-500 focus:ring-gray-300"
                                                            />
                                                        </div>
                                                        <div className="pl-7 text-sm">
                                                            <label htmlFor="sync-enabled"
                                                                   className="font-medium text-gray-900">
                                                                Active
                                                            </label>
                                                            <p id="sync-enabled-description" className="text-gray-500">
                                                                Sync data from provider.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>*/}
                                    </div>
                                </div>

                                {/* Action buttons */}
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">

                                    <button
                                        disabled={isSubmitting}
                                        type="submit"
                                        className={classNames("w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm", (isSubmitting) ? "bg-gray-300 cursor-default" : "bg-primary-600 text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500")}
                                    >
                                        {isSubmitting && <TailSpinner/>}
                                        {isSubmitting ? t("savingDots") : t("save")}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        {t("cancel")}
                                    </button>
                                </div>
                            </form>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition.Root>
    )
}
