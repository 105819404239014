import React from 'react';
import ImportInvoices from "../importInvoices";
import {INVOICE_TYPE} from "../../consts";
import {useSelector} from "react-redux";
import {clientSelector, realActiveCidSelector} from "../../store/client";

export default function PayablesImport() {
    const activeOrganizationId = useSelector(realActiveCidSelector)
    const organization = useSelector(clientSelector(activeOrganizationId))

    return <ImportInvoices financingType={"reverse-factoring"} invoiceType={INVOICE_TYPE.RECEIVED}
                           navigateToOnSuccess={"/u/payables"}
                           canCreate={!!organization?.features?.FINANCE_REVERSE_FACTORING_CREATE}/>
};
