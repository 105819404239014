import React, {Fragment, useEffect, useRef, useState} from 'react';
import '../styles/app.scss';
import {Link, Navigate, NavLink, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {auth} from "../firebase";
import Financing from "./financing/financing";
import {Dialog, Menu, Transition} from '@headlessui/react'
import {
    ArrowDownOnSquareStackIcon,
    ArrowTrendingUpIcon,
    ArrowUpOnSquareStackIcon,
    BuildingOffice2Icon,
    ChevronUpDownIcon,
    CogIcon,
    CurrencyEuroIcon,
    ExclamationTriangleIcon,
    HomeIcon,
    MagnifyingGlassIcon,
    NoSymbolIcon,
    PresentationChartLineIcon
} from '@heroicons/react/20/solid'
import {classNames} from "../helpers/helpers";
import NewFinancingRequest from "./financing/newFinancingRequest";
import {BanknotesIcon, Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import AppSettings from "./settings/index";
import Partners from "./partners/partners";
import {
    activeCidSelector,
    clientSelector,
    fetchClient,
    realActiveCidSelector,
    setActiveCid,
    setRealActiveCid
} from "../store/client";
import Cashflow from "./cashflow/cashflow";
import Receivables from "./receivables";
import Payables from "./payables";
import CreditRating from "./credit-rating";
import Dashboard from "./dashboard";
import ReceivablesImport from "./receivables/receivablesImport";
import PayablesImport from "./payables/payablesImport";
import AcceptInvitation from "../components/AcceptInvitation";
import TableViewPalette from "../components/tableViewPalette";
import {api} from "../config";
import {signOut} from "firebase/auth";
import Spinner from "../components/Spinner";
import useSWRImmutable from "swr/immutable";

const searchOptions = {
    /*suggestions: [
        {id: 'taxNo', name: "Tax number"},
        // More projects...
    ]*/
}

function OrganizationSwitchItem({cid}) {
    const dispatch = useDispatch()
    const client = useSelector(clientSelector(cid))

    return <Menu.Item>
        {({active}) => (<span
            onClick={() => dispatch(setActiveCid(cid))}
            className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'cursor-pointer block px-4 py-2 text-xs')}
        >
            {client?.name}
        </span>)}
    </Menu.Item>
}

export default function App() {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch()
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const activeCid = useSelector(activeCidSelector)
    const realActiveCid = useSelector(realActiveCidSelector)
    const _pendingInvitation = sessionStorage?.getItem("invitationId") || ""
    const [showPalette, setShowPalette] = useState(false)
    const [paletteHidden, setPaletteHidden] = useState(Date.now())
    const location = useLocation()
    const searchInputRef = useRef()
    const navigate = useNavigate()

    const clientAccess = useSWRImmutable('client+' + activeCid,
        () => api.get(`/organizations/${activeCid}/access`).then(({data}) => {
            data?.lastAccessedCid && dispatch(setRealActiveCid(data.lastAccessedCid))
            return data || null
        }),
        {shouldRetryOnError: false})
    const userProfile = useSWRImmutable(auth.currentUser?.uid ? 'user-profile+' + auth.currentUser?.uid : null, () => {
        return api.get(`/users/${auth.currentUser?.uid}`).then(({data}) => {
            if (typeof data === "object") {
                if (data.cidAccessList) {
                    for (let i = 0; i < data.cidAccessList.length; i++) {
                        const id = data.cidAccessList[i]
                        if (id) dispatch(fetchClient(id))
                    }
                }

                if (data.language && data.language !== i18n.language) {
                    i18n.changeLanguage(data.language)
                }
            }
            return data
        })
    })

    const isAuthenticatedUserNotRegisteredToPlatform = clientAccess.error?.response?.data?.message === "User not found"
    const clientAccessError = !!clientAccess.error
    const networkError = clientAccess.error?.code === "ERR_NETWORK"
    const isLoading = clientAccess.isLoading || userProfile.isLoading

    const features = clientAccess.data?.features
    const allNavItems = [
        {name: t('home'), href: '/u/dashboard', icon: HomeIcon, feature: null},
        {
            name: t('payables'),
            href: '/u/payables',
            icon: ArrowDownOnSquareStackIcon,
            feature: features?.FINANCE_REVERSE_FACTORING
        },
        {
            name: t('receivables'),
            href: '/u/receivables',
            icon: ArrowUpOnSquareStackIcon,
            feature: features?.FINANCE_FACTORING
        },
        {
            name: t('financing'),
            href: '/u/financing',
            icon: BanknotesIcon,
            feature: features?.FINANCE_REVERSE_FACTORING || features?.FINANCE_FACTORING
        },
        {name: t('partners'), href: '/u/partners', icon: BuildingOffice2Icon, feature: features?.FINANCE_PARTNERS},
        {
            name: t('cashflow'),
            href: '/u/cashflow',
            icon: PresentationChartLineIcon,
            feature: features?.FINANCE_CASHFLOW
        },
        {
            name: t('creditCheck'),
            href: '/u/organization',
            icon: CurrencyEuroIcon,
            feature: features?.FINANCE_CREDIT_CHECK
        },
        {
            name: t('financialAnalytics'),
            href: '/u/financial-analytics',
            icon: ArrowTrendingUpIcon,
            feature: features?.FINANCE_FINANCIAL_ANALYTICS
        },
        {name: t('settings'), href: '/u/settings/general', icon: CogIcon, feature: null}
    ];

    const navigation = allNavItems
        .filter(item => item.feature !== false)
        .map(item => ({...item, current: location.pathname.startsWith(item.href), feature: undefined}));

    useEffect(() => {
        setSidebarOpen(false)
    }, [location])

    useEffect(() => {
        if (!showPalette) setPaletteHidden(Date.now())
    }, [showPalette])

    function logout() {
        signOut(auth).finally(() => window.location.reload())
    }

    function onFocused() {
        if (searchInputRef.current && paletteHidden < Date.now() - 500) {
            //console.log("RES")
            searchInputRef.current.blur()
            setShowPalette(true)
        } else if (searchInputRef.current) searchInputRef.current.blur()
    }

    function autocompletePublicCompanies(param = "q", value = "") {
        return api.get("/organizations/search?" + param + "=" + encodeURIComponent(value)).then(response => {
            return response?.data || []
        }).catch(error => {
            // handle error
            console.log("error", error)
        })
    }

    function onAutocomplete(query) {
        //console.log("onAutocomplete", query)
        return autocompletePublicCompanies("q", query)
    }

    function onSearch(search) {
        //console.log("onSearch", search)
        //searchPublicCompanies(search.id, search.value)

        if (search.id === "oid") {
            navigate("/u/organization/" + search.value?.oid)
        }
    }

    return isLoading ? <Spinner fullHeight/> : (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 xl:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75"/>
                    </Transition.Child>

                    <div className="fixed inset-0 flex z-40">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel
                                className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">{t('closeSidebar')}</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex-shrink-0 flex items-center px-4">
                                    <img
                                        className="h-8 w-auto"
                                        src="/logo.png"
                                        alt="Nekster"
                                    />
                                </div>
                                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                    <nav className="px-2">
                                        <div className="space-y-1">
                                            {navigation.map((item) => (<NavLink
                                                key={item.name}
                                                to={item.href}
                                                className={classNames(item.current ? 'bg-gray-100 text-gray-900 font-medium' : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50', 'group flex items-center px-2 py-2 text-base leading-5 rounded-md')}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                <item.icon
                                                    className={classNames(item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6')}
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                            </NavLink>))}
                                        </div>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="flex-shrink-0 w-14" aria-hidden="true">
                            {/* Dummy element to force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div
                className="hidden xl:flex xl:flex-col xl:w-72 xl:fixed xl:inset-y-0 xl:pt-5 xl:pb-4 xl:bg-primary-900 xl:drop-shadow-lg">
                <div className="flex items-center justify-center flex-shrink-0 px-6">
                    <img
                        className="h-10 "
                        src="/logo-dark.png"
                        alt="Nekster"
                    />
                </div>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="mt-4 pt-4 h-0 flex-1 flex flex-col overflow-y-auto">
                    {/* User account dropdown */}
                    <Menu as="div" className="px-3 relative inline-block text-left">
                        <div>
                            <Menu.Button
                                className="group w-full bg-primary-800 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500">
                  <span className="flex w-full justify-between items-center">
                    <span className="flex min-w-0 items-center justify-between space-x-3">
                        <span className="flex-1 flex flex-col min-w-0">
                            {clientAccessError ? (
                                <span
                                    className="text-primary-100 text-xs font-medium truncate">{t('account')}</span>
                            ) : (
                                <>
                                <span
                                    className="text-primary-100 text-xs font-medium truncate">{userProfile.data?.fullName}</span>
                                    <span
                                        className="text-primary-400 text-xs truncate">{clientAccess.data?.clientType === "PERSON" ? t('personalProfile') : clientAccess.data?.clientName}</span>
                                </>
                            )}
                      </span>
                    </span>
                    <ChevronUpDownIcon
                        className="flex-shrink-0 h-5 w-5 text-primary-100 group-hover:text-primary-50"
                        aria-hidden="true"
                    />
                  </span>
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items
                                className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                                {userProfile.data?.cidAccessList?.length > 1 && (
                                    <div className="py-1">
                                        <div
                                            className="text-xs font-semibold leading-6 text-gray-400 px-4">{t('myOrganizations')}</div>
                                        {userProfile.data?.cidAccessList.map(id => <OrganizationSwitchItem key={id}
                                                                                                           cid={id}/>)}
                                    </div>
                                )}
                                <div className="py-1">
                                    {!isAuthenticatedUserNotRegisteredToPlatform && (
                                        <Menu.Item>
                                            {({active}) => (<Link
                                                to="/u/settings/general"
                                                className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'cursor-pointer block px-4 py-2 text-xs')}
                                            >
                                                {t('settings')}
                                            </Link>)}
                                        </Menu.Item>
                                    )}
                                    <Menu.Item>
                                        {({active}) => (<span
                                            onClick={logout}
                                            className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'cursor-pointer block px-4 py-2 text-xs')}
                                        >
                                                {t('logout')}
                                            </span>)}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    {/* Navigation */}
                    <nav className="px-3 mt-6">
                        <div className="space-y-1">
                            {navigation.map((item) => (<NavLink
                                key={item.name}
                                to={item.href}
                                className={classNames(item.current ? 'bg-primary-800 text-primary-200 font-bold' : 'text-primary-300 hover:text-primary-200 bg-primary-900 hover:bg-primary-800', 'group flex font-medium items-center px-2 py-2 text-xs rounded-md')}
                                aria-current={item.current ? 'page' : undefined}
                            >
                                <item.icon
                                    className={classNames(item.current ? 'text-primary-400' : 'text-primary-400 group-hover:text-primary-300', 'mr-3 flex-shrink-0 h-6 w-6')}
                                    aria-hidden="true"
                                />
                                {item.name}
                            </NavLink>))}
                        </div>
                    </nav>
                </div>
            </div>


            {/* Main column */}
            <div className="xl:pl-72 h-full">

                {/* Search header */}
                <div
                    className={classNames(
                        "top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-gray-900/5 bg-white dark:border-white/5 dark:bg-gray-900 px-4 shadow-sm sm:px-6 lg:px-8",
                        clientAccessError && "xl:hidden"
                    )}>
                    <button type="button" className="-m-2.5 p-2.5 text-gray-700 dark:text-white xl:hidden"
                            onClick={() => setSidebarOpen(true)}>
                        <span className="sr-only">{t('openSidebar')}</span>
                        <Bars3Icon className="h-5 w-5" aria-hidden="true"/>
                    </button>

                    {!clientAccessError && (
                        <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                            <div className="flex flex-1">
                                <label htmlFor="search-field" className="sr-only">
                                    {t('search')}
                                </label>
                                <div className="relative w-full">
                                    <MagnifyingGlassIcon
                                        className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400 dark:text-gray-500"
                                        aria-hidden="true"
                                    />
                                    <input
                                        ref={searchInputRef}
                                        onFocus={onFocused}
                                        id="search-field"
                                        className="block h-full w-full border-0 bg-transparent py-0 pl-8 pr-0 text-gray-900 dark:text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                        placeholder={t('search')}
                                        type="search"
                                        name="search"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* Main */}
                <main className="min-h-[100%] h-full">
                    {!clientAccessError ? (
                        <Routes>
                            <Route path="" element={<Navigate to="/u/dashboard"/>}/>
                            <Route path="/" element={<Navigate to="/u/dashboard"/>}/>
                            <Route path="/u" element={<Navigate to="/u/dashboard"/>}/>
                            <Route path="/u/" element={<Navigate to="/u/dashboard"/>}/>
                            <Route path="dashboard" element={<Dashboard/>}/>

                            {features?.FINANCE_REVERSE_FACTORING && (
                                <Route path="payables" element={<Payables/>}/>
                            )}
                            {features?.FINANCE_REVERSE_FACTORING && (
                                <Route path="payables/import" element={<PayablesImport/>}/>
                            )}
                            {features?.FINANCE_FACTORING && (
                                <Route path="receivables" element={<Receivables/>}/>
                            )}
                            {features?.FINANCE_FACTORING && (
                                <Route path="receivables/import" element={<ReceivablesImport/>}/>
                            )}
                            {features?.FINANCE_PARTNERS && (
                                <Route path="partners" element={<Partners/>}/>
                            )}
                            {features?.FINANCE_CASHFLOW && (
                                <Route path="cashflow" element={<Cashflow/>}/>
                            )}
                            <Route path="organization"
                                   element={<CreditRating showRating={features?.FINANCE_CREDIT_CHECK}/>}/>
                            <Route path="organization/:id"
                                   element={<CreditRating showRating={features?.FINANCE_CREDIT_CHECK}/>}/>

                            {features?.FINANCE_FINANCIAL_ANALYTICS && (
                                <Route path="financial-analytics" element={<Navigate to="/u/dashboard"/>}/>
                            )}

                            <Route path="settings" element={<Navigate to="/u/settings/general"/>}/>
                            <Route path="settings/*" element={<AppSettings/>}/>
                            <Route path="financing" element={<Financing/>}/>
                            <Route path="financing/:type" element={<NewFinancingRequest/>}/>

                            <Route path="*" element={<Navigate to="/u/dashboard"/>}/>
                        </Routes>
                    ) : (
                        <Routes>
                            <Route path="" element={<Navigate to="/u/client-error"/>}/>
                            <Route path="/" element={<Navigate to="/u/client-error"/>}/>
                            <Route path="/u" element={<Navigate to="/u/client-error"/>}/>
                            <Route path="/u/" element={<Navigate to="/u/client-error"/>}/>
                            <Route path="client-error" element={<ClientError
                                networkError={networkError}
                                isAuthenticatedUserNotRegisteredToPlatform={isAuthenticatedUserNotRegisteredToPlatform}/>}/>

                            {!isAuthenticatedUserNotRegisteredToPlatform && (
                                <>
                                    <Route path="settings" element={<Navigate to="/u/settings/general"/>}/>
                                    <Route path="settings/*"
                                           element={<AppSettings clientAccessError={clientAccessError}/>}/>
                                </>
                            )}

                            <Route path="*" element={<Navigate to="/u/client-error"/>}/>
                        </Routes>
                    )}
                </main>
            </div>

            {!clientAccessError && (
                <>
                    <TableViewPalette setOpen={setShowPalette} open={showPalette} onSearch={onSearch}
                                      onAutocomplete={onAutocomplete}
                                      suggestions={searchOptions.suggestions} quickActions={searchOptions.quickAction}/>
                    {_pendingInvitation && <AcceptInvitation id={_pendingInvitation}/>}
                </>
            )}
        </>
    )
}


function ClientError({isAuthenticatedUserNotRegisteredToPlatform, networkError}) {
    const {t} = useTranslation()

    function logout() {
        signOut(auth).finally(() => window.location.reload())
    }

    return (
        <div className="flex flex-col items-center justify-center h-full">
            <div className="text-center">
                {isAuthenticatedUserNotRegisteredToPlatform ? (
                    <>
                        <NoSymbolIcon className="mx-auto h-12 w-12 text-gray-400"/>
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">{t('userAccessIssue')}</h3>
                        <p className="mt-1 text-sm text-gray-500 max-w-lg">{t('accessToThisApplicationIsCurrentlyUnavailableForYo')}</p>
                        <div className="mt-6">
                            <button
                                onClick={logout}
                                type="button"
                                className="inline-flex items-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                            >
                                {t('logout')}
                            </button>
                        </div>
                    </>
                ) : networkError ? (
                    <>
                        <ExclamationTriangleIcon className="mx-auto h-12 w-12 text-gray-400"/>
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">{t('networkError')}</h3>
                        <p className="mt-1 text-sm text-gray-500 max-w-lg">{t('weAreExperiencingNetworkIssuesPleaseCheckYourInter')}</p>
                        <div className="mt-6">
                            <button
                                onClick={() => window.location.reload()}
                                type="button"
                                className="inline-flex items-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                            >
                                {t('reloadPage')}
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <ExclamationTriangleIcon className="mx-auto h-12 w-12 text-gray-400"/>
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">{t('organizationDataRetrievalError')}</h3>
                        <p className="mt-1 text-sm text-gray-500 max-w-lg">{t('weAreUnableToRetrieveTheOrganizationDataAssociated')}</p>
                        <div className="mt-6">
                            <button
                                onClick={logout}
                                type="button"
                                className="inline-flex items-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                            >
                                {t('logout')}
                            </button>
                        </div>
                    </>
                )}

            </div>
        </div>
    )
}
