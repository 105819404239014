import {useTranslation} from "react-i18next";
import {catchErrors, classNames} from "../../utils";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import TailSpinner from "../../components/tailSpinner";
import {financeApi} from "../../config";

const inputName = "phone"
export default function Complete({data, error, prevState, setSuccess, allData}) {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)

    /*
      @IsString()
    type: FinancingType;

    @IsString()
    documentNo: string;

    @IsString()
    date: string;

    @IsObject()
    client: Partner;

    @IsArray()
    items: Array<FinancingItem>
     */

    function onSubmit(e) {
        e.preventDefault();

        if (loading) return

        setLoading(true)

        // prepare financing items
        const typeSpecific = {}
        if (allData.financing === "F" && allData.details?.list?.length > 0) {
            typeSpecific.factoringDebtorList = []
            for (let i = 0; i < allData.details.list.length; i++) {
                const [taxNo, amount] = allData.details.list[i]
                if (!taxNo || !amount) continue
                typeSpecific.factoringDebtorList.push({
                    amount: parseFloat(amount),
                    taxNo: taxNo
                })
            }
        } else if (allData.financing === "RF" && allData.details) {
            typeSpecific.amount = parseFloat(allData.details?.amount)
            typeSpecific.daysOfExtension = parseInt(allData.details?.extension)
        }

        financeApi.post(`/quotes`, {
            type: allData.financing,
            date: (new Date()).toISOString(),
            sendToEmail: true,
            email: allData.email,
            phone: allData.phone,
            client: {
                oid: allData.company?.oid || null,
                name: allData.company?.name,
                taxNo: allData.company?.taxNo || "",
                vatNo: allData.company?.vatNo || "",
                registrationNo: allData.company?.registrationNo || "",
                address: {
                    address1: allData.company?.address1 || "",
                    postCode: allData.company?.postCode || "",
                    city: allData.company?.city || "",
                },
            },
            ...typeSpecific
        })
            .then(res => {
                console.log("data", res.data)
                setSuccess(true)
            })
            .catch(error => {
                // TODO: handle error
                catchErrors(error, window.alert)
                console.error("error", error)
            })
            .finally(() => {
                setLoading(false)
            })

        //setSuccess(true)
    }

    return (
        <form onSubmit={onSubmit} className="w-full max-w-sm">
            <div>
                <h3 className="text-base font-semibold leading-6 text-white">I agree with</h3>
                {/*<p className="mt-1 text-sm text-gray-50">Read our Terms of Service and Privacy Policy.</p>*/}
            </div>
            <fieldset className="mt-6">
                <legend className="sr-only">Policies</legend>
                <div className="space-y-5">
                    <div className="relative flex items-start">
                        <div className="flex h-6 items-center">
                            <input
                                id="comments"
                                aria-describedby="comments-description"
                                name="comments"
                                type="checkbox"
                                className="h-4 w-4 bg-primary-600 rounded border-primary-600 text-primary-600 focus:ring-primary-600"
                                required
                            />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                            <label htmlFor="comments" className="font-medium text-white">
                                Terms of service
                            </label>
                            <p id="comments-description" className="text-gray-50">

                            </p>
                        </div>
                    </div>
                    <div className="relative flex items-start">
                        <div className="flex h-6 items-center">
                            <input
                                id="candidates"
                                aria-describedby="candidates-description"
                                name="candidates"
                                type="checkbox"
                                className="h-4 w-4 bg-primary-600 rounded border-primary-600 text-primary-600 focus:ring-primary-600"
                                required
                            />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                            <label htmlFor="candidates" className="font-medium text-white">
                                Privacy policy
                            </label>
                            <p id="candidates-description" className="text-gray-50">
                                Data submitted with this form will be stored and used to prepare and send you a custom
                                quote.
                            </p>
                        </div>
                    </div>
                </div>
            </fieldset>


            {error && (
                <p className="mt-2 text-sm text-red-600" id={inputName + "-error"}>
                    {error}
                </p>
            )}
            <div className="mt-8 flex space-x-4">
                <Link
                    to={"/quote/" + prevState.prevStepName}
                    className="rounded-md bg-white/10 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                >
                    Back
                </Link>
                <button
                    type="submit"
                    disabled={loading}
                    className={classNames(
                        "inline-flex rounded-md bg-primary-700 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-700",
                        loading ? "opacity-75 cursor-not-allowed" : "",
                    )}
                >
                    {loading && <TailSpinner/>}
                    {loading ? "Submitting..." : "Submit"}
                </button>
            </div>
        </form>
    )
}
