import React, {Fragment, useEffect, useState} from 'react'
import {Dialog, Tab, Transition} from '@headlessui/react'
import {
    catchErrors,
    classNames,
    formatAddress,
    getFirestampDateString,
    getFirestampDatetimeString,
    serializeQuery
} from "../../../utils";
import {financeApi} from "../../../config";
import {useSelector} from "react-redux";
import {realActiveCidSelector} from "../../../store/client";
import {XMarkIcon} from '@heroicons/react/24/outline'
import {BuildingOfficeIcon, CheckIcon, QuestionMarkCircleIcon} from '@heroicons/react/20/solid'
import {useTranslation} from "react-i18next";
import TailSpinner from "../../../components/tailSpinner";
import SearchBox from "../../../components/searchBox";


const tabs = [
    {name: 'General'},
    {name: 'Partners'}
]

const syncDateMin = new Date(), syncDateMax = new Date()
syncDateMin.setMonth(syncDateMin.getMonth() - 12)
syncDateMin.setDate(syncDateMin.getDate() + 1)
syncDateMax.setMonth(syncDateMax.getMonth() + 1)
syncDateMax.setDate(0)

export function CustomerList({query}) {
    const {t} = useTranslation()
    const activeOrganizationId = useSelector(realActiveCidSelector)
    const [checkedFilters, setCheckedFilters] = useState({})
    const [params, setParams] = useState({})
    const [providerCustomersData, setProviderCustomersData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const searchOptions = {
        suggestions: [
            {id: 'query', name: t('search')},
            // More projects...
        ]
    }

    /*useEffect(() => {
        fetchProviderCustomers()
    }, [])*/

    useEffect(() => {
        onSearch(query ? {id: 'query', value: query, name: "Tax"} : {})
    }, [query])

    function fetchProviderCustomers(_params = {}, loadMore = false) {
        if (isSubmitting) return
        setLoading(true)
        setParams(_params)
        financeApi.get("organizations/" + activeOrganizationId + "/integrations/accounting/customers?" + serializeQuery(_params))
            .then(res => {
                //console.log(res.data)
                if (res.status === 200 && res.data?.results) {
                    setProviderCustomersData({
                        ...res.data,
                        results: loadMore ? [...providerCustomersData.results, ...res.data.results] : res.data.results
                    })
                } else setProviderCustomersData(null)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function onSearch(search) {
        const checked = search.value ? {
            //...checkedFilters,
            [search.id]: {id: search.id, value: search.value, label: search.name, valueLabel: search.value}
        } : {}

        fetchProviderCustomers(search?.value ? {filter: ["q", "==", search.value]} : {})
        setCheckedFilters(checked)
    }

    function removeFilter(filter) {
        return () => {
            const checked = {}
            /*const checked = {...checkedFilters}
            delete checked[filter.id]*/
            onSearch(checked)
        }
    }

    function loadMore() {
        fetchProviderCustomers({...params, offset: (params?.offset || 0) + 5}, true)
    }

    function setItemLoading(id, bool) {
        const newData = providerCustomersData?.results?.map(it => it.id === id ? ({...it, loading: bool}) : it)
        setProviderCustomersData({...providerCustomersData, results: newData})
    }

    function onSync(item) {
        const itemId = item?.id
        if (isSubmitting || !itemId || !item.taxNo) return

        setIsSubmitting(true)
        setItemLoading(itemId, true)

        if (item.syncEnabledAt) {
            financeApi.delete(`/organizations/${activeOrganizationId}/integrations/accounting/customers/${item.id}/sync`)
                .then(res => {
                    if (res.data.success && providerCustomersData?.results) {
                        const newData = providerCustomersData?.results?.map(it => ({
                            ...it,
                            syncEnabledAt: it.id === itemId ? null : it.syncEnabledAt,
                            loading: false
                        }))
                        setProviderCustomersData({...providerCustomersData, results: newData})
                    } else throw new Error("Error syncing customer")
                    //setView("finishSetup")
                })
                .catch(error => {
                    setItemLoading(itemId, false)
                    catchErrors(error, window.alert)
                })
                .finally(() => {
                    setIsSubmitting(false)
                })
        } else {
            financeApi.post(`/organizations/${activeOrganizationId}/integrations/accounting/customers/${item.id}/sync`)
                .then(res => {
                    if (res.data.success && providerCustomersData?.results) {
                        const newData = providerCustomersData?.results?.map(it => ({
                            ...it,
                            syncEnabledAt: it.id === itemId ? new Date() : it.syncEnabledAt,
                            loading: false
                        }))
                        setProviderCustomersData({...providerCustomersData, results: newData})
                    } else throw new Error("Error syncing customer")
                    //setView("finishSetup")
                })
                .catch(error => {
                    setItemLoading(itemId, false)
                    catchErrors(error, window.alert)
                })
                .finally(() => {
                    setIsSubmitting(false)
                })
        }

    }

    return (
        <>
            <div className="px-4 pt-4 pb-2 flex items-center">
                <div className="mr-2">
                    <SearchBox searchOptions={searchOptions}
                               onSearch={onSearch}/>
                </div>

                {Object.values(checkedFilters).map((checked, idx) => (
                    <button
                        key={idx}
                        onClick={removeFilter(checked)}
                        type="button"
                        className="ml-1 inline-flex items-center px-3 py-2 border border-gray-200 text-xs font-medium rounded-full leading-4 text-gray-500 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                        <XMarkIcon className="-ml-0.5 mr-2 h-4 w-4"
                                   aria-hidden="true"/>
                        <span className="sr-only">{t('removeFilter')}</span>
                        {checked?.label}: {checked?.valueLabel}
                    </button>
                ))}
            </div>
            <div>
                <ul role="list"
                    className="flex-1 divide-y divide-gray-200">
                    {providerCustomersData?.results?.map((item) => (
                        <li key={item.id} className="py-4">
                            <div className="flex items-center space-x-4 px-5">
                                                                                <span
                                                                                    className="relative inline-block flex-shrink-0">
                                  <div className="rounded-full bg-gray-100 h-10 w-10 flex justify-center items-center">
                                      <BuildingOfficeIcon className="h-5 w-5" aria-hidden="true"/>
                                  </div>
                                  <span
                                      className={classNames(item.syncEnabledAt ? 'bg-green-400' : 'bg-gray-300', 'absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white')}
                                      aria-hidden="true"
                                  />
                                </span>
                                <div className="min-w-0 flex-1">
                                    <p className="truncate text-sm font-medium text-gray-900">{item.name}</p>
                                    <p className="truncate text-sm text-gray-500">{formatAddress(item.address)}</p>
                                </div>
                                <div>
                                    {item.loading ? (<TailSpinner/>) : (<button
                                        type="button"
                                        disabled={isSubmitting || !item.taxNo}
                                        onClick={() => onSync(item)}
                                        className={classNames(
                                            "inline-flex items-center rounded-full border px-2.5 py-1.5 text-sm font-medium leading-5 gap-x-1.5",
                                            isSubmitting || !item.taxNo ? "bg-white border-gray-200 text-gray-400 cursor-not-allowed" : "bg-white border-gray-300 text-gray-700 shadow-sm hover:bg-gray-50")
                                        }
                                    >
                                        {item.syncEnabledAt && (
                                            <CheckIcon
                                                className="-ml-0.5 h-5 w-5 text-green-500"
                                                aria-hidden="true"/>)}
                                        {item.syncEnabledAt ? "Synced" : "Sync"}
                                    </button>)}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className="flex justify-center p-2">
                    <button
                        type="button"
                        disabled={loading}
                        onClick={() => loadMore()}
                        className={classNames(
                            "inline-flex items-center rounded-md border border-transparent bg-white px-2.5 py-1.5 text-xs font-medium text-gray-500 hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2",
                            loading ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                        )}>
                        {t('loadMore')}
                        {loading && <TailSpinner className="-mr-1 ml-2" tiny
                                                 aria-hidden="true"/>}
                    </button>
                </div>
            </div>

        </>
    )
}

export default function AccountingIntegrationSlide({open, setOpen, provider, query, fetchUpdates}) {
    const {t} = useTranslation()
    const activeOrganizationId = useSelector(realActiveCidSelector)
    const [view, setView] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isSyncing, setIsSyncing] = useState(false)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [status, setStatus] = useState("active")
    const [startDate, setStartDate] = useState("default")
    const [startDateCustom, setStartDateCustom] = useState("")
    const [syncOption, setSyncOption] = useState("")
    const [organizationId, setOrganizationId] = useState("")
    const [organizations, setOrganizations] = useState([])

    useEffect(() => {
        if (open) {
            //console.log("open", provider)
            setIsSubmitting(false)
            setUsername("")
            setPassword("")
            setStatus(provider?.status || "active")
            setSyncOption(provider?.syncOption || "")
            setOrganizationId("")
            if (provider === false) {
                setView("loading")
            } else if (!provider?.credentials) {
                setView("connect")
            } else if (!provider?.credentials?.organizationId) {
                setView("finishSetup")
            } else {
                setView("manage")
            }
        }
        if (open && provider && !provider?.credentials?.organizationId) {
            fetchOrganizations()
        }
    }, [open, provider])

    function fetchOrganizations() {
        setIsLoading(true)
        financeApi.get("/organizations/" + activeOrganizationId + "/integrations/accounting/organizations").then(res => {
            if (res.status === 200 && res.data) {
                //dispatch(setIntegrations(res.data))
                if (res.data.results) setOrganizations(res.data.results || [])
                console.log(res.data)
            }
        }).catch(error => {
            console.log("error fetching organization integrations", error)
            //console.log(error.response?.status === 418)
            if (error.response?.status === 418) {
                // invalid credentials
                window.alert("Invalid provider credentials")
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }

    function onCloseIfCan(open = false) {
        if (!isSubmitting) setOpen(open)
    }

    function onSubmit(e) {
        e.preventDefault()

        if (isSubmitting) return

        if (view === "finishSetup") {
            if (!organizationId) return window.alert("Please select an organization")

            const obj = {
                organizationId, status: "active", syncOption: "all"
            }
            if (startDate === "custom") obj.startDate = startDateCustom

            setIsSubmitting(true)
            financeApi.put(`/organizations/${activeOrganizationId}/integrations/accounting`, obj)
                .then(res => {
                    //console.log("data", res.data)
                    setView("manage")
                    //setOpen(false)
                })
                .catch(error => {
                    catchErrors(error, window.alert)
                })
                .finally(() => {
                    setIsSubmitting(false)
                    fetchUpdates && fetchUpdates()
                })

        } else if (view === "manage") {
            // update syncOption

            const obj = {
                syncOption,
                status
            }
            setIsSubmitting(true)
            financeApi.put(`/organizations/${activeOrganizationId}/integrations/accounting`, obj)
                .then(res => {
                    //console.log("data", res.data)
                    //setOpen(false)
                })
                .catch(error => {
                    catchErrors(error, window.alert)
                })
                .finally(() => {
                    setIsSubmitting(false)
                    fetchUpdates && fetchUpdates()
                })
        } else {
            const obj = {
                username, password, status: "inactive", //organizationId,
            }
            setIsSubmitting(true)
            financeApi.post(`/organizations/${activeOrganizationId}/integrations/accounting`, obj)
                .then(res => {
                    //console.log("data", res.data)
                    setView("finishSetup")
                    setTimeout(() => {
                        fetchOrganizations()
                    }, 500)
                })
                .catch(error => {
                    catchErrors(error, window.alert)
                })
                .finally(() => {
                    setIsSubmitting(false)
                    fetchUpdates && fetchUpdates()
                })
        }
    }

    function sync() {
        setIsSyncing(true)
        financeApi.get(`/organizations/${activeOrganizationId}/integrations/accounting/sync`)
            .then(res => {
                console.log("data", res.data)
                setOpen(false)
            })
            .catch(error => {
                catchErrors(error, window.alert)
            })
            .finally(() => {
                setIsSyncing(false)
            })
    }

    function onSelect(item) {
        setOrganizationId(item.id)
    }

    //console.log("syncedAt", provider?.syncedAt)


    return (<Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onCloseIfCan}>
            <div className="fixed inset-0"/>

            <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                <form onSubmit={onSubmit}
                                      className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                    <div className="flex flex-col h-0 flex-1 overflow-y-auto">
                                        <div className="bg-primary-700 py-6 px-4 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <Dialog.Title
                                                    className="text-base font-semibold leading-6 text-white">
                                                    {t('accountingSoftware')}
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-md bg-primary-700 text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                        onClick={() => onCloseIfCan()}
                                                    >
                                                        <span className="sr-only">{t('closePanel')}</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mt-1">
                                                <p className="text-sm text-primary-300">
                                                    {t('accountingSoftwareDescription')}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex flex-1 flex-col justify-between grow">
                                            {view === "loading" ? (
                                                <div className="grow flex justify-center items-center">
                                                    <TailSpinner big/>
                                                </div>
                                            ) : view === "finishSetup" ? (
                                                <div className="relative">
                                                    <div
                                                        className="sticky top-0 z-10 border-b border-gray-200 bg-gray-50 px-6 py-2.5 text-sm font-medium text-gray-500">
                                                        <h3>{t('selectOrganization')}</h3>
                                                    </div>
                                                    {isLoading ? (
                                                        <div className="flex justify-center items-center p-4">
                                                            <TailSpinner/>
                                                        </div>
                                                    ) : (
                                                        <ul role="list"
                                                            className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                                                            {organizations.map((item) => (
                                                                <li key={item.id} className="py-4">
                                                                    <div className="flex items-center space-x-4 px-5">
                                                                        <div className="flex-shrink-0">
                                                                            <div
                                                                                className="h-8 w-8 rounded-full bg-gray-100 flex items-center justify-center">
                                                                                <BuildingOfficeIcon
                                                                                    className="h-5 w-5 text-gray-500"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="min-w-0 flex-1">
                                                                            <p className="truncate text-sm font-medium text-gray-900">{item.name}</p>
                                                                        </div>
                                                                        <div>
                                                                            {item.loading ? (<TailSpinner/>) : (<button
                                                                                type="button"
                                                                                disabled={!item.access}
                                                                                onClick={() => item.access && onSelect(item)}
                                                                                className={classNames("inline-flex items-center rounded-full border px-2.5 py-1.5 text-sm font-medium leading-5 gap-x-1.5", !item.access ? "bg-white border-gray-100 text-gray-300 cursor-not-allowed" : "bg-white border-gray-300 text-gray-700 shadow-sm hover:bg-gray-50")}
                                                                            >
                                                                                {organizationId === item.id && (
                                                                                    <CheckIcon
                                                                                        className="-ml-0.5 h-5 w-5 text-green-500"
                                                                                        aria-hidden="true"/>)}
                                                                                {organizationId === item.id ? "Selected" : "Select"}
                                                                            </button>)}
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                            {organizations.length === 0 && (
                                                                <li className="py-4">
                                                                    <div
                                                                        className="flex items-center justify-center px-5">
                                                                        <span
                                                                            className="text-xs text-gray-700">{t('noResults')}</span>
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    )}

                                                    <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                                        <div className="space-y-6 pt-6 pb-5">
                                                            <fieldset>
                                                                <legend
                                                                    className="text-sm font-medium leading-6 text-gray-900">
                                                                    {t('syncStartDate')}
                                                                </legend>
                                                                <div className="mt-2 space-y-4">
                                                                    <div className="relative flex items-start">
                                                                        <div
                                                                            className="absolute flex h-6 items-center">
                                                                            <input
                                                                                id="startDate-default"
                                                                                name="startDate"
                                                                                aria-describedby="startDate-default-description"
                                                                                type="radio"
                                                                                onChange={() => setStartDate('default')}
                                                                                checked={startDate === 'default'}
                                                                                className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600"
                                                                            />
                                                                        </div>
                                                                        <div className="pl-7 text-sm leading-6">
                                                                            <label htmlFor="startDate-default"
                                                                                   className="font-medium text-gray-900">
                                                                                {t('fromTheBeginningOfTheYear')}
                                                                            </label>
                                                                            <p id="startDate-default-description"
                                                                               className="text-gray-500">
                                                                                {t('syncDataFromTheBeginningOfThisYear')}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className="relative flex items-start">
                                                                            <div
                                                                                className="absolute flex h-6 items-center">
                                                                                <input
                                                                                    id="startDate-custom"
                                                                                    name="startDate"
                                                                                    aria-describedby="startDate-custom-description"
                                                                                    type="radio"
                                                                                    onChange={() => setStartDate('custom')}
                                                                                    checked={startDate === 'custom'}
                                                                                    className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600"
                                                                                />
                                                                            </div>
                                                                            <div className="pl-7 text-sm leading-6">
                                                                                <label htmlFor="startDate-custom"
                                                                                       className="font-medium text-gray-900">
                                                                                    {t('customDate')}
                                                                                </label>
                                                                                <p id="startDate-inactive-description"
                                                                                   className="text-gray-500">
                                                                                    {t('syncDataFromACustomDate')}
                                                                                </p>
                                                                                <div className="mt-2">
                                                                                    <label htmlFor="custom-date"
                                                                                           className="sr-only">
                                                                                        {t('customDate')}
                                                                                    </label>
                                                                                    <input
                                                                                        type="date"
                                                                                        min={syncDateMin.toISOString().split('T')[0]}
                                                                                        max={syncDateMax.toISOString().split('T')[0]}
                                                                                        disabled={startDate !== 'custom'}
                                                                                        value={startDateCustom}
                                                                                        onChange={(e) => setStartDateCustom(e.target.value)}
                                                                                        name="custom-date"
                                                                                        id="custom-date"
                                                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 disabled:opacity-60"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        <div className="pt-4 pb-6">
                                                            {/*<div className="mb-4 flex text-sm">
                                                            <a
                                                                href="#"
                                                                className="group inline-flex items-center font-medium text-primary-600 hover:text-primary-900"
                                                            >
                                                                <LinkIcon
                                                                    className="h-5 w-5 text-primary-500 group-hover:text-primary-900"
                                                                    aria-hidden="true"
                                                                />
                                                                <span className="ml-2">Copy link</span>
                                                            </a>
                                                        </div>*/}
                                                            <div className="flex text-sm">
                                                                <a href="#"
                                                                   className="group inline-flex items-center text-gray-500 hover:text-gray-900">
                                                                    <QuestionMarkCircleIcon
                                                                        className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                        aria-hidden="true"
                                                                    />
                                                                    <span
                                                                        className="ml-2">{t('learnMoreAboutDataSynchronization')}</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : view === "connect" ? (
                                                <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                                    <div className="space-y-6 pt-6 pb-5">
                                                        <div>
                                                            <label htmlFor="provider"
                                                                   className="block text-sm font-medium leading-6 text-gray-900"
                                                            >
                                                                {t('provider')}
                                                            </label>
                                                            <div className="mt-2">
                                                                <select
                                                                    id="provider"
                                                                    name="provider"
                                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                                                >
                                                                    <option value="minimax">Minimax</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label
                                                                htmlFor="username"
                                                                className="block text-sm font-medium leading-6 text-gray-900"
                                                            >
                                                                {t('username')}
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                    type="text"
                                                                    name="username"
                                                                    id="username"
                                                                    value={username}
                                                                    onChange={e => setUsername(e.target.value)}
                                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label
                                                                htmlFor="password"
                                                                className="block text-sm font-medium leading-6 text-gray-900"
                                                            >
                                                                {t('password')}
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                    type="text"
                                                                    name="password"
                                                                    id="password"
                                                                    value={password}
                                                                    onChange={e => setPassword(e.target.value)}
                                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <Tab.Group
                                                        as="div"
                                                        className="flex h-full flex-col overflow-y-auto bg-white">
                                                        <div className="border-b border-gray-200 mt-6">
                                                            <div className="px-6">
                                                                <Tab.List className="-mb-px flex space-x-6"
                                                                          x-descriptions="Tab component">
                                                                    {tabs.map((tab) => (
                                                                        <Tab
                                                                            as="span"
                                                                            key={tab.name}
                                                                            className={({selected}) =>
                                                                                classNames(
                                                                                    'inline-block cursor-pointer whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium',
                                                                                    selected
                                                                                        ? 'border-primary-500 text-primary-600'
                                                                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                                                                                )
                                                                            }
                                                                        >
                                                                            {tab.name}
                                                                        </Tab>
                                                                    ))}
                                                                </Tab.List>
                                                            </div>
                                                        </div>
                                                        <Tab.Panels className="">
                                                            <Tab.Panel>
                                                                <div
                                                                    className="h-full overflow-y-auto bg-white py-8 px-4 sm:px-6">
                                                                    <div className="space-y-6">
                                                                        <div>
                                                                            <h3 className="font-medium text-gray-900">{t('overview')}</h3>
                                                                            <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                                                                                <div
                                                                                    className="flex justify-between py-3 text-sm font-medium">
                                                                                    <dt className="text-gray-500">{t('provider')}</dt>
                                                                                    <dd className="text-gray-900">Minimax</dd>
                                                                                </div>
                                                                                <div
                                                                                    className="flex justify-between py-3 text-sm font-medium">
                                                                                    <dt className="text-gray-500">{t('status')}</dt>
                                                                                    <dd className="text-gray-900">{t(provider?.status)}</dd>
                                                                                </div>
                                                                                <div
                                                                                    className="flex justify-between py-3 text-sm font-medium">
                                                                                    <dt className="text-gray-500">{t('credentials')}</dt>
                                                                                    <dd className="text-gray-900">{provider?.credentialsValid === false ? t("invalid") : provider?.credentialsValid === true ? t("valid") : t("notValidated")}</dd>
                                                                                </div>
                                                                                <div
                                                                                    className="flex justify-between py-3 text-sm font-medium">
                                                                                    <dt className="text-gray-500">{t('partners')}</dt>
                                                                                    <dd className="text-gray-900">{t(provider?.syncOption)}</dd>
                                                                                </div>
                                                                                <div
                                                                                    className="flex justify-between py-3 text-sm font-medium">
                                                                                    <dt className="text-gray-500">{t('syncStartDate')}</dt>
                                                                                    <dd className="text-gray-900">{getFirestampDateString(provider?.syncStartDate) || "-"}</dd>
                                                                                </div>
                                                                                <div
                                                                                    className="flex justify-between py-3 text-sm font-medium">
                                                                                    <dt className="text-gray-500 flex items-center">
                                                                                        {t('lastSyncedAt')}
                                                                                        {/*<button
                                                                                            onClick={() => sync()}
                                                                                            disabled={isSyncing}
                                                                                            type="button"
                                                                                            title={t('sync')}
                                                                                            className="disabled:animate-spin disabled:text-primary-500 disabled:cursor-progress ml-2.5 flex h-6 w-6 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500"
                                                                                        >
                                                                                            <ArrowPathIcon
                                                                                                className="h-4 w-4"
                                                                                                aria-hidden="true"/>
                                                                                            <span
                                                                                                className="sr-only">{t('sync')}</span>
                                                                                        </button>*/}
                                                                                    </dt>
                                                                                    <dd className="text-gray-900">{getFirestampDatetimeString(provider?.syncedAt) || t("never")}</dd>
                                                                                </div>
                                                                                <div
                                                                                    className="flex justify-between py-3 text-sm font-medium">
                                                                                    <dt className="text-gray-500">{t('nextSyncTimestamp')}</dt>
                                                                                    <dd className="text-gray-900">{getFirestampDatetimeString(provider?.syncTask?.scheduledAt) || "-"}</dd>
                                                                                </div>
                                                                            </dl>
                                                                        </div>
                                                                        <div className="divide-y divide-gray-200">
                                                                            <div className="space-y-6 pt-2 pb-5">

                                                                                <fieldset>
                                                                                    <legend
                                                                                        className="text-sm font-medium leading-6 text-gray-900">{t('sync')}
                                                                                    </legend>
                                                                                    <div className="mt-2 space-y-4">
                                                                                        <div
                                                                                            className="relative flex items-start">
                                                                                            <div
                                                                                                className="absolute flex h-6 items-center">
                                                                                                <input
                                                                                                    id="sync-all"
                                                                                                    name="syncOption"
                                                                                                    aria-describedby="sync-all-description"
                                                                                                    type="radio"
                                                                                                    onChange={() => setSyncOption('all')}
                                                                                                    checked={syncOption === 'all'}
                                                                                                    className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600"
                                                                                                />
                                                                                            </div>
                                                                                            <div
                                                                                                className="pl-7 text-sm leading-6">
                                                                                                <label
                                                                                                    htmlFor="sync-all"
                                                                                                    className="font-medium text-gray-900">
                                                                                                    {t('everything')}
                                                                                                </label>
                                                                                                <p id="sync-all-description"
                                                                                                   className="text-gray-500">
                                                                                                    {t('syncAllInvoices')}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div
                                                                                                className="relative flex items-start">
                                                                                                <div
                                                                                                    className="absolute flex h-6 items-center">
                                                                                                    <input
                                                                                                        id="sync-selected"
                                                                                                        name="syncOption"
                                                                                                        aria-describedby="sync-selected-description"
                                                                                                        type="radio"
                                                                                                        onChange={() => setSyncOption('selectedOnly')}
                                                                                                        checked={syncOption === 'selectedOnly'}
                                                                                                        className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600"
                                                                                                    />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="pl-7 text-sm leading-6">
                                                                                                    <label
                                                                                                        htmlFor="sync-selected"
                                                                                                        className="font-medium text-gray-900">
                                                                                                        {t('connectedPartnersOnly')}
                                                                                                    </label>
                                                                                                    <p id="sync-selected-description"
                                                                                                       className="text-gray-500">
                                                                                                        {t('syncInvoicesForTheConnectedPartnersOnly')}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </fieldset>
                                                                                <fieldset>
                                                                                    <legend
                                                                                        className="text-sm font-medium leading-6 text-gray-900">{t('status')}
                                                                                    </legend>
                                                                                    <div className="mt-2 space-y-4">
                                                                                        <div
                                                                                            className="relative flex items-start">
                                                                                            <div
                                                                                                className="absolute flex h-6 items-center">
                                                                                                <input
                                                                                                    id="status-active"
                                                                                                    name="status"
                                                                                                    aria-describedby="status-active-description"
                                                                                                    type="radio"
                                                                                                    onChange={() => setStatus('active')}
                                                                                                    checked={status === 'active'}
                                                                                                    className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600"
                                                                                                />
                                                                                            </div>
                                                                                            <div
                                                                                                className="pl-7 text-sm leading-6">
                                                                                                <label
                                                                                                    htmlFor="status-active"
                                                                                                    className="font-medium text-gray-900">
                                                                                                    {t('active')}
                                                                                                </label>
                                                                                                <p id="status-active-description"
                                                                                                   className="text-gray-500">
                                                                                                    {t('dataFromMinimaxWillBeSynchronizedFromTheDateTheSta')}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <div
                                                                                                className="relative flex items-start">
                                                                                                <div
                                                                                                    className="absolute flex h-6 items-center">
                                                                                                    <input
                                                                                                        id="status-inactive"
                                                                                                        name="status"
                                                                                                        /*aria-describedby="status-inactive-description"*/
                                                                                                        type="radio"
                                                                                                        onChange={() => setStatus('inactive')}
                                                                                                        checked={status === 'inactive'}
                                                                                                        className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600"
                                                                                                    />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="pl-7 text-sm leading-6">
                                                                                                    <label
                                                                                                        htmlFor="status-inactive"
                                                                                                        className="font-medium text-gray-900">
                                                                                                        {t('inactive')}
                                                                                                    </label>
                                                                                                    {/*<p id="status-inactive-description"
                                                                               className="text-gray-500">
                                                                                Only members of this project would be
                                                                                able to access.
                                                                            </p>*/}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </fieldset>
                                                                            </div>
                                                                            <div className="pt-4">
                                                                                <div className="flex text-sm">
                                                                                    <a href="#"
                                                                                       className="group inline-flex items-center text-gray-500 hover:text-gray-900">
                                                                                        <QuestionMarkCircleIcon
                                                                                            className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                        <span
                                                                                            className="ml-2">{t('learnMoreAboutDataSynchronization')}</span>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab.Panel>
                                                            <Tab.Panel>
                                                                <CustomerList query={query}/>
                                                            </Tab.Panel>
                                                        </Tab.Panels>
                                                    </Tab.Group>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                            onClick={() => onCloseIfCan()}
                                        >
                                            {t('dismiss')}
                                        </button>
                                        {view !== "manage" && (<button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className={classNames("ml-4 inline-flex justify-center rounded-md bg-primary-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600", isSubmitting ? "opacity-50" : "")}
                                        >
                                            {isSubmitting && <TailSpinner/>}
                                            {isSubmitting ? (view === "finishSetup" ? t("finishingSetupDots") : t("addingDots")) : (view === "finishSetup" ? t("finishSetup") : t("next"))}
                                        </button>)}
                                        {view === "manage" && (<button
                                            type="submit"
                                            disabled={isSubmitting || !status || !syncOption || (provider?.status == status && provider?.syncOption == syncOption)}
                                            className="ml-4 inline-flex justify-center rounded-md bg-primary-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                            {isSubmitting && <TailSpinner/>}
                                            {isSubmitting ? t("savingDots") : t("save")}
                                        </button>)}
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </div>
        </Dialog>
    </Transition.Root>)
}
