import {useTranslation} from "react-i18next";
import countries from "../../locales/countries";
import React, {useState} from 'react'
import {CheckIcon, ChevronUpDownIcon, ExclamationCircleIcon} from '@heroicons/react/20/solid'
import {Combobox} from '@headlessui/react'
import {classNames} from "../../utils";
import {useNavigate} from "react-router-dom";

const countryList = Object.keys(countries).map((key, i) => {
    const c = countries[key];
    return Object.assign({id: key, name: c.name.toUpperCase()}, c)
});

export default function Country({data, setData, error, setError, nextState}) {
    const navigate = useNavigate();
    const {t} = useTranslation()
    const [query, setQuery] = useState('')

    const filtered =
        query === ''
            ? countryList
            : countryList.filter(item => {
                return item.name.toLowerCase().includes(query.toLowerCase())
            })

    function onSubmit(e) {
        e.preventDefault();

        if (!data.name) {
            setError("Please select country from the dropdown list")
        } else {
            navigate("/quote/" + nextState.nextStepName)
        }
    }

    return (
        <form onSubmit={onSubmit} className="w-full max-w-sm">
            <Combobox as="div"
                      className="w-full"
                      value={data} onChange={setData}>
                <Combobox.Label className="block text-sm font-medium leading-6 text-white">Country of
                    business</Combobox.Label>
                <div className="relative mt-2">
                    <Combobox.Input
                        className={classNames(
                            "w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10  shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6",
                            error ? "pr-16 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500" : "text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-primary-600"
                        )}
                        onChange={(event) => setQuery(event.target.value)}
                        displayValue={(person) => person?.name}
                    />
                    <Combobox.Button
                        className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                    </Combobox.Button>
                    {error && (
                        <div className="pointer-events-none absolute inset-y-0 right-6 flex items-center pr-3">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                        </div>
                    )}

                    {filtered.length > 0 && (
                        <Combobox.Options
                            className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filtered.map((person) => (
                                <Combobox.Option
                                    key={person.id}
                                    value={person}
                                    className={({active}) =>
                                        classNames(
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                            active ? 'bg-primary-600 text-white' : 'text-gray-900'
                                        )
                                    }
                                >
                                    {({active, selected}) => (
                                        <>
                                        <span
                                            className={classNames('block truncate', selected && 'font-semibold')}>{person.name}</span>

                                            {selected && (
                                                <span
                                                    className={classNames(
                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                        active ? 'text-white' : 'text-primary-600'
                                                    )}
                                                >
                        <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                      </span>
                                            )}
                                        </>
                                    )}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>
                    )}
                </div>
            </Combobox>
            {error && (
                <p className="mt-2 text-sm text-red-600">
                    {error}
                </p>
            )}
            <div className="mt-8">
                <button
                    type="submit"
                    disabled={!data}
                    className={classNames(
                        "rounded-md bg-primary-700 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-700",
                        !data ? "opacity-75 cursor-not-allowed" : ""
                    )}
                >
                    Next
                </button>
            </div>
        </form>
    )
}
