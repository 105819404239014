import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import TableView from "../../components/tableView";
import Toolbar from "../../components/toolbar";
import {fetchFinancingList, financingListHasMoreSelector, financingListSelector} from "../../store/financing";
import {getFirestampDateString} from "../../utils";
import {CloudArrowDownIcon} from "@heroicons/react/20/solid";
import {financeApi} from "../../config";
import {BanknotesIcon, BuildingOfficeIcon} from "@heroicons/react/24/outline";
import {useTranslation} from "react-i18next";


export default function Financing() {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    //const [selectedIndex, setSelectedIndex] = useState([])
    //const loadMoreExhausted = useSelector(state => state.issuedInvoices.loadMoreExhausted)
    const ordered = useSelector(financingListSelector)
    const hasMore = useSelector(financingListHasMoreSelector)
    const [loading, setLoading] = useState(true)
    const [params, setParams] = useState(null)

    const PROMISSORY_NOTE_STATUS = {
        PENDING_APPROVAL: t('pending'),
        UNSIGNED: t('unsigned'),
        SIGNED_BY_CREDITOR: t('signedByCreditor'),
        SIGNED_BY_DEBTOR: t('signedByDebtor'),
        FINANCING_IN_PROGRESS: t('financingInProgress'),
        FINANCED: t('financed'),
        PAID: t('paid'),
        DELAYED: t('delayed'),
        IN_RECOVERY: t('inRecovery'),
        INSURANCE_CLAIM: t('insuranceClaim'),
        REPAID: t('repaid'),
    }

    const TYPE = {
        RF: t('reverseFactoring'),
        F: t('factoring'),
        IF: t('internationalFactoring'),
    }

    const tableColumns = [
        {v: d => d.number, label: t('document'), sort: "number"},
        {
            v: d => d.debtor?.name,
            label: t('debtor'),
            sort: "debtor.name",
        },
        {
            v: d => getFirestampDateString(d.date),
            label: t('date'),
            sort: "date",
            className: "hidden 2xl:table-cell",
        },
        {
            v: d => getFirestampDateString(d.transactionDate),
            label: t('transactionDate'),
            sort: "transactionDate",
            className: "hidden 2xl:table-cell",
        },
        {v: d => d.amount?.formatted, label: t('total'), sort: "amount.value"},
        {
            v: d => d.paidAmount?.formatted,
            label: t('paid'),
            sort: "paidAmount.value",
            className: "hidden 2xl:table-cell",
        },
        {
            v: d => d.assignee?.name,
            label: t('assignee'),
            sort: "assignee.name",
        },
        {v: d => TYPE[d.type] || d.type, label: t('type'), sort: "type", className: "hidden xl:table-cell",},
        {v: d => PROMISSORY_NOTE_STATUS[d.status] || d.status, label: t('status'), sort: "status"},
    ]

    const rowActions = [
        {
            label: t('download'),
            icon: CloudArrowDownIcon,
            check: d => d.status === "UNSIGNED" || d.status === "SIGNED_BY_CREDITOR" || d.status === "SIGNED_BY_DEBTOR",
            onClick: (item) => {
                //console.log(item)
                return financeApi.get("/promissory-notes/" + item.id + "/draft", {responseType: "blob"}).then(response => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(new Blob([response.data]));
                    link.setAttribute('download', "document-" + item.number + ".pdf");
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }).catch(error => {
                    console.log("error", error)
                })
            }
        }
    ]

    const tableFilters = [
        {
            type: "radio",
            id: "type",
            label: t('type'),
            options: [
                {label: t('all'), value: ""},
                {label: t('factoring'), value: "F"},
                {label: t('reverseFactoring'), value: "RF"},
            ],
        }
    ]

    const searchOptions = {
        suggestions: [
            {id: 'number', name: t('documentNumber')},
            // More projects...
        ],
        quickActions: [
            {name: t('addNewFinancing'), icon: BanknotesIcon, shortcut: 'F', url: '#'},
            {name: t('addNewPartner'), icon: BuildingOfficeIcon, shortcut: 'P', url: '#'},
        ]
    }

    useEffect(() => {
        loading && setLoading(false)
    }, [ordered])

    function fetchData(_params) {
        setParams(_params)
        setLoading(true)
        dispatch(fetchFinancingList(_params))
    }

    function onSearch(item) {
        fetchData(item || null, true)
    }

    function onLoadMore() {
        fetchData({...params, offset: ordered.length})
    }

    return (
        <>
            <Toolbar/>
            <div className="mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8">
                <div className="px-4 sm:px-6 lg:flex-auto lg:px-0">
                    <ul role="list" className="mt-8 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
                        <li className="col-span-1 bg-primary-700 rounded-lg shadow">
                            <div className="w-full flex items-center justify-between p-4 space-x-6">
                                <div className="flex-1 truncate m-2">
                                    <div className="flex items-center space-x-3">
                                        <h3 className="text-white text-xl font-medium truncate uppercase bold tracking-wider">Financiranje<br/> terjatev
                                        </h3>
                                        {/*<span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">Role</span>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex items-center justify-between p-4 space-x-6">
                                <Link
                                    to="/u/receivables/import"
                                    className="full-width uppercase text-primary-500 justify-center inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded shadow-sm bg-white hover:bg-gray-100 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-gray-100"
                                >
                                    {t('quickFunding')}
                                </Link>
                            </div>
                            <div>
                                <div className="-mt-px pb-4 px-6 flex divide-x divide-gray-100 text-white">
                                    <div className="w-0 flex-1 flex flex-col text-center justify-center">
                                        <span className="bolder">150.000 €</span>
                                        <span className="text-xs">{t('maximum')}</span>
                                    </div>
                                    <div className="-ml-px w-0 flex-1 flex flex-col text-center justify-center">
                                        <span className="bolder">137.000 €</span>
                                        <span className="text-xs">{t('used')}</span>
                                    </div>
                                    <div className="-ml-px w-0 flex-1 flex flex-col text-center justify-center">
                                        <span className="bolder">13.000 €</span>
                                        <span className="text-xs">{t('available')}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="col-span-1 bg-primary-700 rounded-lg shadow">
                            <div className="w-full flex items-center justify-between p-4 space-x-6">
                                <div className="flex-1 truncate m-2">
                                    <div className="flex items-center space-x-3">
                                        <h3 className="text-white text-xl font-medium truncate uppercase bold tracking-wider">Financiranje<br/> obveznosti
                                        </h3>
                                        {/*<span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">Role</span>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex items-center justify-between p-4 space-x-6">
                                <Link
                                    to="/u/payables/import"
                                    className="full-width uppercase text-primary-500 justify-center inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded shadow-sm bg-white hover:bg-gray-100 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-gray-100"
                                >
                                    {t('quickFunding')}
                                </Link>
                            </div>
                            <div>
                                <div className="-mt-px pb-4 px-6 flex divide-x divide-gray-100 text-white">
                                    <div className="w-0 flex-1 flex flex-col text-center justify-center">
                                        <span className="bolder">50.000 €</span>
                                        <span className="text-xs">{t('maximum')}</span>
                                    </div>
                                    <div className="-ml-px w-0 flex-1 flex flex-col text-center justify-center">
                                        <span className="bolder">0 €</span>
                                        <span className="text-xs">{t('used')}</span>
                                    </div>
                                    <div className="-ml-px w-0 flex-1 flex flex-col text-center justify-center">
                                        <span className="bolder">50.000 €</span>
                                        <span className="text-xs">{t('available')}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <h2 className="mt-8 text-lg leading-6 font-medium text-gray-900">
                        {t('recentActivity')}
                    </h2>
                    <div
                        className="mt-8 bg-white shadow sm:rounded-lg flex flex-col">
                        <TableView id="financing" columns={tableColumns} data={ordered} hasMore={hasMore}
                                   onLoadMore={onLoadMore}
                                   loading={loading} filters={tableFilters}
                                   rowActions={rowActions} onSearch={onSearch} searchOptions={searchOptions}/>
                    </div>
                </div>
            </div>
        </>
    )
}
