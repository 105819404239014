import React, {useEffect, useState} from 'react';
import {getRandomInt} from "../utils";
import {useTranslation} from "react-i18next";

const playerHeight = 10,
    playerWidth = 10,
    playerJump = 60,
    obstacleWidths = [10],
    obstacleHeights = [10, 20, 30, 40, 50],
    obstacleXSpawn = [288, 500],
    playerX = obstacleXSpawn[0] / 2 - playerWidth / 2;

function JumperGame() {
    const {t} = useTranslation("app")
    const [score, setScore] = useState(0);
    const [playerY, setPlayerY] = useState(0); // Start on the "ground"
    const [velocity, setVelocity] = useState(0);
    const [isJumping, setIsJumping] = useState(false);
    const [obstacleX, setObstacleX] = useState(getRandomInt(obstacleXSpawn[0], obstacleXSpawn[1]));
    const [obstacleWidth, setObstacleWidth] = useState(obstacleWidths[0]);
    const [obstacleHeight, setObstacleHeight] = useState(obstacleHeights[0]);
    const [hasStarted, setHasStarted] = useState(false);
    const [gameOver, setGameOver] = useState(true);

    useEffect(() => {
        const handleSpacePress = (e) => {
            if ((e.keyCode === 32 || !e.keyCode) && !isJumping && !gameOver) {
                setIsJumping(true);
                setVelocity(15); // Jump upwards
            } else if (e.keyCode === 32 && gameOver) {
                retry()
            }
        };
        window.addEventListener('keydown', handleSpacePress);
        window.addEventListener('mousedown', handleSpacePress);

        return () => {
            window.removeEventListener('keydown', handleSpacePress);
            window.removeEventListener('mousedown', handleSpacePress);
        }
    }, [isJumping, gameOver]);

    useEffect(() => {
        if (playerY <= 0) {
            setPlayerY(0);
            setIsJumping(false);
        }

        if (playerY >= playerJump && velocity > 0) {
            setVelocity(-5); // Peak of the jump, start falling
        }

        if (obstacleX < -obstacleWidth) {
            setScore((prev) => prev + 1);
            setObstacleX(getRandomInt(obstacleXSpawn[0], obstacleXSpawn[1])); // Respawn the obstacle
            setObstacleHeight(obstacleHeights[getRandomInt(0, obstacleHeights.length - 1)]);
            setObstacleWidth(obstacleWidths[getRandomInt(0, obstacleWidths.length - 1)]);
        }

        if (playerX <= (obstacleX + obstacleWidth) && (playerX + playerWidth) >= obstacleX && playerY <= obstacleHeight) {
            setGameOver(true); // Collision detected
        }

        const gameLoop = setInterval(() => {
            if (!gameOver) {
                setPlayerY((prev) => prev + velocity);
                setObstacleX((prev) => prev - 5);
                if (isJumping && playerY > 0) {
                    setVelocity((v) => v - 1); // Gravity effect
                }
            }
        }, 20);

        return () => clearInterval(gameLoop);
    }, [playerY, isJumping, obstacleX, gameOver]);

    function retry() {
        setHasStarted(true);
        setGameOver(false);
        setObstacleX(getRandomInt(obstacleXSpawn[0], obstacleXSpawn[1]));
        setObstacleHeight(obstacleHeights[getRandomInt(0, obstacleHeights.length - 1)]);
        setObstacleWidth(obstacleWidths[getRandomInt(0, obstacleWidths.length - 1)]);
        setPlayerY(0);
        setScore(0)
    }

    return (
        <div className="border-2 border-primary-700 overflow-hidden rounded-md select-none">
            <div className="relative w-72 h-72 border-b-8 border-primary-700 overflow-hidden">
                <div className="absolute top-2 right-2">
                    <div className="flex justify-between items-center bg-primary-400 rounded-xl py-1 px-2">
                        <div className="text-primary-700 font-bold text-sm">{score}</div>
                        <div className="text-primary-700 font-medium text-sm pl-1">{t("score")}</div>
                    </div>
                </div>
                <div
                    className="absolute bg-primary-400"
                    style={{
                        bottom: `${playerY}px`,
                        left: playerX + 'px',
                        width: playerHeight + 'px',
                        height: playerWidth + 'px',
                    }}
                ></div>
                <div
                    className="absolute bg-primary-700"
                    style={{
                        bottom: '0px',
                        left: `${obstacleX}px`,
                        width: obstacleWidth + 'px',
                        height: obstacleHeight + 'px',
                    }}
                ></div>
                {gameOver && (
                    <div
                        className="absolute w-full h-full bg-primary-700 bg-opacity-50 flex flex-col justify-center items-center">
                        {!hasStarted &&
                            <p className="-mt-6 m-6 text-sm text-white text-center">{t('heresSomethingToKeepYouBusyWhileYouWait')}</p>}
                        <button
                            className="p-2 px-4 bg-primary-700 text-white rounded-md font-medium text-xs"
                            onClick={retry}
                        >
                            {hasStarted ? t("retry") : t("pressSpaceToStart")}
                        </button>
                    </div>)}
            </div>
        </div>
    );
}

export default JumperGame;
