import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import register_en from "./locales/register.en.json";
import app_en from "./locales/app.en.json";
import register_sl from "./locales/register.sl.json";
import app_sl from "./locales/app.sl.json";

i18n.on('languageChanged', lng => document.documentElement.setAttribute('lang', lng))

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        //lng: "sl", // if you're using a language detector, do not define the lng option
        fallbackLng: "en",
        debug: true,

        keySeparator: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        ns: ["app", "register"],
        defaultNS: "app",
        load: "languageOnly",
        resources: {
            en: {
                register: register_en,
                app: app_en
            },
            sl: {
                register: register_sl,               // 'common' is our custom namespace
                app: app_sl               // 'common' is our custom namespace
            },
        },

        detection: {
            // order and from where user language should be detected
            order: ['localStorage', 'htmlTag'],

            // keys or params to lookup language from
            lookupLocalStorage: 'i18nextLng',

            // cache user language on
            caches: ['localStorage'],
            excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

            // optional htmlTag with lang attribute, the default is:
            htmlTag: document.documentElement
        }
    });

export default i18n;
