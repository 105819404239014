import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import TableView from "../../components/tableView";
import {fetchPartners, partnerListHasMoreSelector, partnerListSelector} from "../../store/partners";
import {
    clientSelector,
    fetchOrganizationIntegrations,
    organizationIntegrationsSelector,
    realActiveCidSelector
} from "../../store/client";
import {formatMoney} from "../../utils";
import Toolbar from "../../components/toolbar";
import AddPartnerDialog from "../../components/addPartnerDialog";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {
    AcademicCapIcon,
    BanknotesIcon,
    BuildingOfficeIcon,
    ClockIcon,
    ReceiptRefundIcon
} from "@heroicons/react/24/outline";
import {statusView, syncStatusView} from "../../components/tableViewHelpers";
import {PencilSquareIcon} from "@heroicons/react/20/solid";
import EditPartnerDialog from "./editPartnerDialog";
import ImportPartnersDialog from "./importPartnersDialog";
import AccountingIntegrationSlide from "../settings/providers/accountingIntegrationSlide";
import {api} from "../../config";
import TailSpinner from "../../components/tailSpinner";
import ActionsBar from "../../components/actionsBar";

const defaultSort = ["name", "asc"]


export default function Partners() {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const ordered = useSelector(partnerListSelector)
    const hasMore = useSelector(partnerListHasMoreSelector)
    const activeOrganizationId = useSelector(realActiveCidSelector)
    const organization = useSelector(clientSelector(activeOrganizationId))
    const [open, setOpen] = useState(false)
    const [editPartner, setEditPartner] = useState(null)
    const [importPartnersDialogOpen, setImportPartnersDialogOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [params, setParams] = useState(null)
    const [minimaxDialogOpen, setMinimaxDialogOpen] = useState(false)
    const [minimaxTaxNo, setMinimaxTaxNo] = useState(false)
    const integrationsMap = useSelector(organizationIntegrationsSelector)
    const [statsMap, setStatsMap] = useState({})

    function fetchStats(partnerData) {
        api.get("/organizations/" + activeOrganizationId + "/partners/" + partnerData.id + "/stats?oid=" + partnerData?.oid).then(res => {
            if (res.status === 200 && res.data) {
                setStatsMap(prev => ({...prev, [partnerData.id]: res.data}))
                //setStats(res.data)
                //dispatch(setIntegrations(res.data))
            } else setStatsMap(prev => ({...prev, [partnerData.id]: false}))
        }).catch(error => {
            console.log("error fetching organization integrations", error)
            setStatsMap(prev => ({...prev, [partnerData.id]: false}))
        })
    }

    useEffect(() => {
        if (ordered?.length) {
            ordered.forEach((partner) => {
                fetchStats(partner)
            })
        }
    }, [ordered]);

    const tableColumns = [
        {
            v: d => organization?.features?.FINANCE_CREDIT_CHECK && d.oid ?
                <Link className="underline text-gray-900" to={"/u/organization/" + d.oid}>{d.name}</Link> : d.name,
            label: t('company'),
            sort: "name"
        },
        {v: d => statusView(d.status, t), label: t('status'), sort: "status"},
        {v: d => d.taxNo, label: t('taxNumber')},
        {
            v: d => statsMap[d.id] === false ? "-" : statsMap[d.id] ?
                <span className="whitespace-nowrap">{formatMoney(statsMap[d.id]?.balance) + " €"}</span> :
                <TailSpinner tiny/>,
            label: t('revenue'),
            className: "hidden xl:table-cell"
        },
        {
            v: d => statsMap[d.id] === false ? "-" : statsMap[d.id] ? (statsMap[d.id]?.creditScore || "-") :
                <TailSpinner tiny/>,
            label: t('creditRating'),
            className: "hidden xl:table-cell"
        },
        {v: d => "-", label: t('limit'), className: "hidden xl:table-cell"},
        {
            v: d => syncStatusView(d.syncStatus, t) || <span onClick={() => onSyncBtnClick(d)}
                                                             className="text-primary-600 hover:text-primary-900 font-medium cursor-pointer text-sm font-medium">{t("sync")}</span>,
            label: "Sync"
        },
    ]
    const rowActions = [
        {
            label: t('edit'),
            icon: PencilSquareIcon,
            check: d => true,
            onClick: (item) => {
                //console.log(item)
                setEditPartner(item)
            }
        }
    ]
    const tableFilters = [
        {
            type: "radio",
            id: "status",
            label: t('status'),
            options: [
                {label: t('all')},
                {label: t('active'), value: "ACTIVE"},
                {label: t('pending'), value: "PENDING"},
            ],
        }
    ]
    const searchOptions = {
        suggestions: [
            {id: 'taxNo', name: t('taxNumber')},
            // More projects...
        ],
        quickActions: [
            {name: t('addNewFinancing'), icon: BanknotesIcon, shortcut: 'F', url: '#'},
            {name: t('addNewPartner'), icon: BuildingOfficeIcon, shortcut: 'P', url: '#'},
        ]
    }
    const actionsView = [/*{name: "Cancel", onClick: () => history.goBack()},*/
        {
            name: t('addPartner'), onClick: () => {
                setOpen(true)
            }, primary: true
        },]
    const actions = [
        {
            title: t('addPartner'),
            onClick: () => {
                setOpen(true)
            },
            icon: ReceiptRefundIcon,
            iconForeground: 'text-rose-700',
            iconBackground: 'bg-rose-50',
        },
        {
            title: t('suppliers'),
            href: '#',
            icon: BanknotesIcon,
            iconForeground: 'text-yellow-700',
            iconBackground: 'bg-yellow-50',
        },
        {
            title: t('buyers'),
            href: '#',
            icon: ClockIcon,
            iconForeground: 'text-teal-700',
            iconBackground: 'bg-teal-50',
        },
        {
            title: t('apiConnection'),
            href: '#',
            onClick: () => {
                setImportPartnersDialogOpen(true)
            },
            icon: AcademicCapIcon,
            iconForeground: 'text-primary-700',
            iconBackground: 'bg-primary-50',
        },
    ]

    useEffect(() => {
        loading && setLoading(false)
    }, [ordered])

    /*    useEffect(() => {
            console.log("on minimaxDialogOpen")
            !minimaxDialogOpen && fetchData({...params, offset: 0})
        }, [minimaxDialogOpen])*/

    function onSyncBtnClick(item) {
        !minimaxTaxNo && dispatch(fetchOrganizationIntegrations(activeOrganizationId))
        setMinimaxDialogOpen(true)
        setMinimaxTaxNo(item.taxNo)
    }

    function onSetOpenEditDialog(open) {
        !open && setEditPartner(null)
        !open && fetchData({...params, offset: 0})
    }

    function fetchData(_params) {
        setParams(_params)
        setLoading(true)
        //dispatch(fetchFinancingList(_params))
        dispatch(fetchPartners({..._params, limit: 10}))
    }

    function onLoadMore() {
        fetchData({...params, offset: ordered.length})
    }

    function onClose() {
        if (activeOrganizationId) fetchData({...params, offset: 0})
        setOpen(false)
    }

    return (
        <>
            <Toolbar/>
            <div className="mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8 space-y-16 py-16 pt-8 xl:space-y-20">
                <div className="px-4 sm:px-6 lg:flex-auto lg:px-0">
                    <ActionsBar actions={actions}/>
                    <h2 className="mt-8 text-lg leading-6 font-medium text-gray-900">
                        {t('partners')}
                    </h2>
                    <div
                        className="mt-8 bg-white sm:rounded-xl border border-gray-200 flex flex-col">
                        <TableView id="partners" columns={tableColumns} data={ordered} hasMore={hasMore}
                                   loading={loading}
                                   filters={tableFilters} searchOptions={searchOptions}
                                   onSearch={fetchData} onLoadMore={onLoadMore} defaultSort={defaultSort}
                                   rowActions={rowActions}/>
                    </div>
                </div>
            </div>
            <AddPartnerDialog open={open} setOpen={onClose}/>
            <EditPartnerDialog data={editPartner} open={!!editPartner} setOpen={onSetOpenEditDialog}/>
            <ImportPartnersDialog open={importPartnersDialogOpen} setOpen={setImportPartnersDialogOpen}/>
            <AccountingIntegrationSlide setOpen={setMinimaxDialogOpen} open={minimaxDialogOpen}
                                        provider={integrationsMap?.accounting} query={minimaxTaxNo}/>
        </>
    )
}
