import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import TableView from "../../components/tableView";
import {getFirestampDateString} from "../../utils";
import {financeApi} from "../../config";
import {BanknotesIcon,} from '@heroicons/react/24/outline'
import {useTranslation} from "react-i18next";
import {
    fetchIssued,
    fetchIssuedDrafts,
    receivableListHasMoreSelector,
    receivableListSelector
} from "../../store/receivables";
import {clientSelector, realActiveCidSelector} from "../../store/client";
import CreateNewFinancingRequestOverviewDialog from "../../components/createNewFinancingRequestOverviewDialog";
import {INVOICE_TYPE, PROMISSORY_NOTE_INVOICE_STATUS} from "../../consts";
import {TableFilterView, useTableFilters} from "../../components/tableViewHelpers";

const defaultSort = ["date", "desc"]

export default function ReceivablesTable({previewOnly}) {
    const {t} = useTranslation()

    const dispatch = useDispatch()
    const activeOrganizationId = useSelector(realActiveCidSelector)
    const organization = useSelector(clientSelector(activeOrganizationId))
    const ordered = useSelector(receivableListSelector)
    const hasMore = useSelector(receivableListHasMoreSelector)
    const [loading, setLoading] = useState(true)
    const [params, setParams] = useState(null)
    const drafts = !organization?.oid || organization?.status !== "ACTIVE"
    const [selectedInvoices, setSelectedInvoices] = useState([])
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const action = {
            label: t('financeInvoice'),
            icon: BanknotesIcon,
        },
        eligibleForPN = d => (d.status === PROMISSORY_NOTE_INVOICE_STATUS.ADDED || d.status === PROMISSORY_NOTE_INVOICE_STATUS.APPROVED) && !!organization?.features?.FINANCE_FACTORING_CREATE,
        tableSelectorFilters = [
            {
                name: "Select eligible for financing",
                filter: eligibleForPN
            },
        ]

    const rowActions = [
        {
            ...action,
            check: eligibleForPN,
            onClick: (item) => {
                setSelectedInvoices([item])
                setIsCreateDialogOpen(true)
            }
        }
    ]

    const tableFilters = useTableFilters([
        {
            type: "radio",
            id: "status",
            label: t('status'),
            options: [
                {label: t('all'), value: null},
                {label: t('ADDED'), value: "ADDED"},
                {label: t('PAID'), value: "PAID"},
            ],
        }
    ])


    const tableActions = [
        {
            ...action,
            check: dArr => {
                return dArr && dArr.length > 0 && dArr.every(eligibleForPN)
            },
            onClick: items => {
                setSelectedInvoices(items)
                setIsCreateDialogOpen(true)
            }
        }
    ]

    const tableColumns = [
        /*{v: d => d.id, label: "ID"},*/
        {v: d => d.number, label: "#", sort: "number"},
        {
            v: d => d.customer?.name,
            label: t('buyer'),
        },
        {
            v: d => getFirestampDateString(d.date),
            label: t('issueDate'),
            sort: "date"
        },
        {
            v: d => getFirestampDateString(d.dueDate),
            label: t('dueDate'),
            sort: "dueDate"
        },
        {
            v: d => <span className="whitespace-nowrap">{d.amount?.formatted}</span>,
            label: t('amount'),
            sort: "amount.value"
        },
        {v: d => t(d.status), label: t('status')},
    ]

    useEffect(() => {
        loading && setLoading(false)
    }, [ordered])

    function fetchData(_params) {
        _params = {...params, ..._params}
        setParams(_params)
        setLoading(true)
        dispatch(drafts ? fetchIssuedDrafts({..._params}) : fetchIssued({..._params}))
    }

    function onSearch(item) {
        fetchData(item || null)
    }

    function onLoadMore() {
        fetchData({offset: (params.offset || 0) + 10})
    }

    function onSubmit(transactionDate) {
        // create promissory notes from selected invoices

        const object = {
            type: "F",
            organizationId: activeOrganizationId,
            invoiceIds: selectedInvoices.map(inv => inv.id),
        }

        if (transactionDate) object.transactionDate = new Date(transactionDate)

        setIsSubmitting(true)
        financeApi.post("/promissory-notes/batch", object)
            .then(res => {
                setIsSubmitting(false)
                setIsCreateDialogOpen(false)
                fetchData(params)
                //history.push(`/u/receivables`)
            })
            .catch(error => {
                setIsSubmitting(false)
                alert("Error: " + (error?.response?.data?.message || "Unknown error"))
                console.log("error", error)
            })
    }

    return (
        <>
            {!previewOnly && (
                <div className="mt-8 flex justify-between items-center">
                    <h2 className=" text-lg leading-6 font-medium text-gray-900">
                        {t('invoices')}
                    </h2>
                    {tableFilters && (
                        <div>
                            <TableFilterView tableFilters={tableFilters}/>
                        </div>
                    )}
                </div>
            )}

            <div className="mt-8 bg-white sm:rounded-xl border border-gray-200 flex flex-col">
                <TableView id="receivables" columns={tableColumns} tableActions={tableActions}
                           data={previewOnly ? ordered.slice(0, 3) : ordered}
                           hasMore={hasMore} defaultSort={defaultSort} tableFilters={tableFilters}
                           onLoadMore={onLoadMore} onSearch={onSearch} showNavigation={!previewOnly}
                           loading={loading} rowActions={rowActions} selectorFilters={tableSelectorFilters}
                />
            </div>
            <CreateNewFinancingRequestOverviewDialog onSubmit={onSubmit} open={isCreateDialogOpen}
                                                     setOpen={setIsCreateDialogOpen} invoiceType={INVOICE_TYPE.ISSUED}
                                                     isSubmitting={isSubmitting}/>
        </>

    )
}
