import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {RegisterRoot} from "./Register";

export default function AppSentConfirmation() {
    const {t} = useTranslation()

    return (
        <RegisterRoot>
            <div>
                <div className="Register-Content-Header">
                    <Trans i18nKey='resetPasswordTitle'>
                        <h1 className="Register-Heading">{t('checkYourEmailTitle')}</h1>
                    </Trans>
                    <Trans i18nKey='resetPasswordSubtitle'>
                        <span className="Register-Subtitle block">{t('checkYourEmailText')}</span>
                    </Trans>
                    <div className="Register-Content-Body">

                    </div>
                </div>
            </div>
        </RegisterRoot>
    )

}
