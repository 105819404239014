import React, {Fragment} from "react";
import {Listbox, Switch, Transition} from '@headlessui/react'
import {classNames, useErrorHandler} from "../../utils";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/20/solid";
import {api} from "../../config";
import {getAuth} from "firebase/auth";
import useSWRImmutable from "swr/immutable";
import {useTranslation} from "react-i18next";

function NotificationsTab() {
    const {t} = useTranslation()
    const uid = getAuth().currentUser.uid
    const handleError = useErrorHandler()

    const people = [
        {id: 1, name: t('never'), value: 'never'},
        {id: 2, name: t('daily'), value: 'daily'},
        {id: 3, name: t('weekly'), value: 'weekly'},
        {id: 4, name: t('monthly'), value: 'monthly'},
    ]

    const userProfile = useSWRImmutable(uid ? 'user-profile+' + uid : null, () => {
        return api.get(`/users/${uid}`).then(res => res.data)
    })

    const accountSummary = !!userProfile.data?.accountSummarySubscription ? people.find(p => p.value === userProfile.data?.accountSummarySubscription) || people[0] : people[0]
    const newsEnabled = !!userProfile.data?.newsSubscription

    function onAccountSummaryChange(value) {
        if (value?.value !== userProfile.data?.accountSummarySubscription) {
            submit({accountSummary: value?.value})
        }
    }

    function onNewsChange(value) {
        if (value !== userProfile.data?.newsSubscription) {
            submit({news: value})
        }
    }

    function submit(obj) {
        api.put(`/users/${uid}`, obj)
            .then(res => {
                if (res.data?.success) {
                } else {
                    handleError(res.data)
                }
            })
            .catch(handleError)
            .finally(() => {
                userProfile.mutate()
            })
    }

    return (
        <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">{t('notifications')}</h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">{t('setIfAndHowOftenYoullReceiveNotificationsOnYourEma')}</p>

            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:flex-none sm:pr-6">
                        <p>
                            {t('accountSummary')}
                        </p>
                        <span className="text-sm text-gray-500">
                            {t('receiveASummaryOfActivityOnYourAccount')}
                        </span>
                    </dt>
                    <dd className="mt-4 flex w-full text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:items-center sm:justify-end">
                        <Listbox value={accountSummary} onChange={onAccountSummaryChange}
                                 disabled={userProfile.isLoading}>
                            {({open}) => (
                                <>
                                    <div className="relative">
                                        <Listbox.Button
                                            className="relative min-w-[116px] w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm">
                                            <span className="block truncate">{accountSummary.name}</span>
                                            <span
                                                className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
              </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options
                                                className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                {people.map((person) => (
                                                    <Listbox.Option
                                                        key={person.id}
                                                        className={({active}) =>
                                                            classNames(
                                                                active ? 'text-white bg-primary-600' : 'text-gray-900',
                                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                                            )
                                                        }
                                                        value={person}
                                                    >
                                                        {({selected, active}) => (
                                                            <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {person.name}
                        </span>

                                                                {selected ? (
                                                                    <span
                                                                        className={classNames(
                                                                            active ? 'text-white' : 'text-primary-600',
                                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                        )}
                                                                    >
                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                          </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </>
                            )}
                        </Listbox>
                    </dd>
                </div>
                <Switch.Group as="div" className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:flex-none sm:pr-6">
                        <Switch.Label as="p" passive>
                            {t('news')}
                        </Switch.Label>
                        <Switch.Description className="text-sm text-gray-500">
                            {t('receiveNewsAboutNeksterAndRelatedServices')}
                        </Switch.Description>
                    </dt>
                    <dd className="mt-4 flex w-full text-sm text-gray-900 sm:col-span-1 sm:mt-0 items-center">
                        <Switch
                            checked={newsEnabled}
                            onChange={onNewsChange}
                            disabled={userProfile.isLoading}
                            className={classNames(
                                newsEnabled ? 'bg-primary-600' : 'bg-gray-200',
                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:ml-auto'
                            )}
                        >
                                  <span
                                      aria-hidden="true"
                                      className={classNames(
                                          newsEnabled ? 'translate-x-5' : 'translate-x-0',
                                          'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                      )}
                                  />
                        </Switch>
                    </dd>
                </Switch.Group>
            </dl>

        </div>
    );
}

export default NotificationsTab
