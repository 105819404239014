import React, {useState} from "react";
import {formatAddress} from "../../utils";
import parsePhoneNumber from "libphonenumber-js";
import EditLanguageDialog from "./editLanguageDialog";
import RequestPasswordResetDialog from "./requestPasswordResetDialog";
import {auth} from "../../firebase";
import {signOut} from "firebase/auth";
import {useTranslation} from "react-i18next";
import useSWRImmutable from "swr/immutable";
import {api} from "../../config";


export default function AccountTab() {
    const {t} = useTranslation()

    const userProfile = useSWRImmutable(auth.currentUser?.uid ? 'user-profile+' + auth.currentUser?.uid : null, () => {
        return api.get(`/users/${auth.currentUser?.uid}`).then(res => res.data)
    })

    const [isEditLanguageDialogOpen, setIsEditLanguageDialogOpen] = useState(false)
    const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] = useState(false)

    function NotSet() {
        return <i className="text-gray-600 font-light">{t('notSet')}</i>
    }

    function logout() {
        signOut(auth).finally(() => window.location.reload())
    }

    return (
        <>
            <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">{t('profile')}</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">{t('yourPersonalContactInformation')}</p>

                <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                    <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                            {t('fullName')}
                        </dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                            <div className="text-gray-900">
                                {userProfile.data?.fullName || <NotSet/>}
                            </div>
                        </dd>
                    </div>
                    <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                            {t('address')}
                        </dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                            <div className="text-gray-900">
                                {formatAddress(userProfile.data?.address) || <NotSet/>}
                            </div>
                        </dd>
                    </div>
                    <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                            {t('email')}
                        </dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                            <div className="text-gray-900">
                                {userProfile.data?.email || <NotSet/>}
                            </div>
                        </dd>
                    </div>
                    <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                            {t('phone')}
                        </dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                            <div className="text-gray-900">
                                {userProfile.data?.phone ? (parsePhoneNumber(userProfile.data?.phone).formatInternational() ||
                                    <NotSet/>) : <NotSet/>}
                            </div>
                        </dd>
                    </div>
                </dl>
            </div>


            <div className="mt-16">
                <h2 className="text-base font-semibold leading-7 text-gray-900">{t('account')}</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">{t('manageYourAccountSettingsAndSecurity')}</p>


                <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                    <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                            {t('defaultLanguage')}
                        </dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                            <div className="text-gray-900">
                                {userProfile.data?.language === "sl" ? "Slovenščina" : userProfile.data?.language === "en" ? "English" :
                                    <NotSet/>}
                            </div>
                            <button type="button"
                                    onClick={() => setIsEditLanguageDialogOpen(true)}
                                    className="font-semibold text-primary-600 hover:text-primary-500">
                                {t('update')}
                            </button>
                        </dd>
                    </div>
                    <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                            {t('password')}
                        </dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                            <div className="text-gray-900">******</div>
                            <button type="button"
                                    onClick={() => setIsChangePasswordDialogOpen(true)}
                                    className="font-semibold text-primary-600 hover:text-primary-500">
                                {t('requestPasswordReset')}
                            </button>
                        </dd>
                    </div>
                    <div className="pt-6 sm:flex">
                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                            {t('signOut')}
                        </dt>
                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                            <div className="text-gray-900"/>
                            <button type="button"
                                    onClick={logout}
                                    className="font-semibold text-primary-600 hover:text-primary-500">
                                {t('signOut')}
                            </button>
                        </dd>
                    </div>
                </dl>
            </div>

            <EditLanguageDialog defaultValue={userProfile.data?.language || "sl"} open={isEditLanguageDialogOpen}
                                setOpen={(v) => {
                                    userProfile.mutate()
                                    setIsEditLanguageDialogOpen(v)
                                }}/>
            <RequestPasswordResetDialog email={userProfile.data?.email} open={isChangePasswordDialogOpen}
                                        setOpen={setIsChangePasswordDialogOpen}/>
        </>
    );
}
