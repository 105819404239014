import {Fragment, useEffect, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {classNames, formatAddress} from "../utils";
import {api} from "../config";
import {BuildingOfficeIcon} from "@heroicons/react/20/solid";
import TailSpinner from "./tailSpinner";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {realActiveCidSelector} from "../store/client";

function StepOne({isDisabled, value, setValue}) {
    const {t} = useTranslation()
    return (
        <div className="mt-6">
            <div className="">
                <label htmlFor="company"
                       className="block text-sm font-medium text-gray-700">
                    {t("companyNameOrTaxNumber")}
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        id="company"
                        onChange={e => setValue(e.target.value)}
                        disabled={isDisabled}
                        value={value}
                        className={classNames("block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm", isDisabled && value ? "bg-gray-100" : "bg-white")}
                    />
                </div>
            </div>
        </div>
    )
}

function StepTwo({isDisabled, companies = [], onSelect}) {
    const {t} = useTranslation()

    return (<div>
        <div className="mt-6 flow-root">
            <ul role="list" className="-my-5 divide-y divide-gray-200">
                {companies.map((item) => (<li key={item.oid} className="py-4">
                    <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                            <div className="h-8 w-8 rounded-full bg-gray-50 flex items-center justify-center">
                                <BuildingOfficeIcon className="h-5 w-5 text-gray-500"/>
                            </div>
                        </div>
                        <div className="min-w-0 flex-1">
                            <p className="truncate text-sm font-medium text-gray-900">{item.name}</p>
                            <p className="truncate text-sm text-gray-500">{formatAddress(item.address)}</p>
                        </div>
                        <div>
                            {item.loading ? (
                                <TailSpinner/>
                            ) : (
                                <button
                                    type="button"
                                    onClick={() => onSelect(item)}
                                    className={classNames(
                                        "inline-flex items-center rounded-full border px-2.5 py-0.5 text-sm font-medium leading-5",
                                        isDisabled ? "bg-white border-gray-100 text-gray-300 cursor-not-allowed" : "bg-white border-gray-300 text-gray-700 shadow-sm hover:bg-gray-50"
                                    )}
                                >
                                    {t("select")}
                                </button>
                            )}
                        </div>
                    </div>
                </li>))}
            </ul>
        </div>
        {/*<div className="mt-6">
                <a
                    href="#"
                    className="flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                >
                    View more
                </a>
            </div>*/}
    </div>)
}

function StepThree({
                       isDisabled,
                       contactPerson,
                       setContactPerson,
                       contactTitle,
                       setContactTitle,
                       contactPhone,
                       setContactPhone
                   }) {
    const {t} = useTranslation()

    return (
        <div className="mt-6">
            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                <div className="sm:col-span-3">
                    <label htmlFor="name"
                           className="block text-sm font-medium text-gray-700">
                        {t("contactPerson")}
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            id="name"
                            disabled={isDisabled}
                            onChange={e => setContactPerson(e.target.value)}
                            value={contactPerson}
                            required
                            autoComplete="contact name"
                            className={classNames("block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm", isDisabled ? "bg-gray-100" : "bg-white")}
                        />
                    </div>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="organization-title"
                           className="block text-sm font-medium text-gray-700">
                        {t("contactTitle")}
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            id="organization-title"
                            disabled={isDisabled}
                            onChange={e => setContactTitle(e.target.value)}
                            value={contactTitle}
                            required
                            autoComplete="contact organization-title"
                            className={classNames("block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm", isDisabled ? "bg-gray-100" : "bg-white")}
                        />
                    </div>
                </div>

                <div className="sm:col-span-6">
                    <label htmlFor="tel"
                           className="block text-sm font-medium text-gray-700">
                        {t("contactPhone")}
                    </label>
                    <div className="mt-1">
                        <input
                            type="tel"
                            id="tel"
                            disabled={isDisabled}
                            onChange={e => setContactPhone(e.target.value)}
                            value={contactPhone}
                            required
                            autoComplete="contact tel"
                            className={classNames("block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm", isDisabled ? "bg-gray-100" : "bg-white")}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default function AddPartnerDialog({query, taxNo, open, setOpen}) {
    const {t} = useTranslation()
    const cancelButtonRef = useRef(null)

    const activeOrganizationId = useSelector(realActiveCidSelector)

    const [step, setStep] = useState(1)
    const [companyQuery, setCompanyQuery] = useState("")
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)

    const [contactPerson, setContactPerson] = useState('')
    const [contactTitle, setContactTitle] = useState('')
    const [contactPhone, setContactPhone] = useState('')

    useEffect(() => {
        if (open) {
            setStep(1)
            setCompanyQuery(query || "")
            setContactPerson("")
            setContactTitle("")
            setContactPhone("")
            setCompanies([])
            setSelectedCompany(null)
            setIsSubmitting(false)
            setIsDisabled(false)
            if (taxNo || query) {
                setCompanyQuery(query)
                searchPublicCompanies(taxNo || query)
            }
        }
    }, [open])

    function searchPublicCompanies(q = "") {
        setIsSubmitting(true)
        api.get("/organizations/search?q=" + encodeURIComponent(q)).then(response => {
            setIsSubmitting(false)
            setCompanies(response?.data || [])
            setStep(2)
        }).catch(error => {
            setIsSubmitting(false)
            // handle error
            console.log("error", error)
        })
    }

    function createPartner(oid, companyName, taxNo, contactPerson, contactTitle, contactPhone) {
        setIsSubmitting(true)
        const obj = {
            oid: "" + oid,
            name: companyName || "",
            taxNo: taxNo || "",
            contactPerson: contactPerson,
            contactTitle: contactTitle,
            contactPhone: contactPhone,
        }

        return api.post(`/organizations/${activeOrganizationId}/partners`, obj)
            .then(res => {
                console.log("data", res.data)
            })
            .catch(error => {
                console.log("error", error)
                if (error.response?.data?.message) {
                    alert(error.response.data.message)
                } else {
                    alert(t("errorSubmittingYourRequest"))
                }
            })
            .finally(() => {
                setIsSubmitting(false)
                setOpen(false)
            })
    }

    function onSelectedItem(item) {
        //console.log("onSelectedItem", item)
        const oid = item.oid;
        setSelectedCompany(item)
        setIsDisabled(true)
        setCompanies(companies.map(c => {
            if (c.oid === oid) {
                return {...c, selected: true, loading: true}
            } else {
                return {...c, selected: false}
            }
        }))

        api.get("/organizations/" + activeOrganizationId + "/partners/" + oid).then(response => {
            //console.log("response", response.data)
            if (response.data?.contactInfoRequired) {
                setStep(3)
            } else {
                return createPartner(oid, "", "", "", "", "")
            }
        }).catch(error => {
            setIsSubmitting(false)
            // handle error
            console.log("error", error)
            alert(t("errorFetchingData"))
        }).finally(() => {
            setIsDisabled(false)
        })
    }

    function onSubmit(e) {
        e.preventDefault()

        switch (step) {
            case 1:
                searchPublicCompanies(companyQuery)
                break
            case 2:
                setCompanyQuery("")
                setStep(1)
                break
            case 3:
                if (!selectedCompany) {
                    setCompanyQuery("")
                    setStep(1)
                } else return createPartner(selectedCompany?.oid, selectedCompany?.shortName || selectedCompany?.name, selectedCompany?.taxData?.taxNumber, contactPerson, contactTitle, contactPhone)
        }
    }

    return (<Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel
                            as="form"
                            onSubmit={onSubmit}
                            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    {/*<div
                                            className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600"
                                                                     aria-hidden="true"/>
                                        </div>*/}
                                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                        <Dialog.Title as="h3"
                                                      className="text-lg font-medium leading-6 text-gray-900">
                                            {t("addPartner")}
                                        </Dialog.Title>

                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {step === 1 && t("letsFindYourPartner")}
                                                {step === 2 && t("selectYourPartner")}
                                                {step === 3 && t("contactInfoRequired")}
                                            </p>
                                        </div>

                                    </div>
                                </div>

                                {step === 1 && <StepOne isDisabled={isDisabled || isSubmitting}
                                                        value={companyQuery} setValue={setCompanyQuery}/>}
                                {step === 2 &&
                                    <StepTwo isDisabled={isDisabled} companies={companies} onSelect={onSelectedItem}/>}
                                {step === 3 && <StepThree
                                    isDisabled={isDisabled || isSubmitting}
                                    contactPerson={contactPerson}
                                    contactPhone={contactPhone}
                                    contactTitle={contactTitle}
                                    setContactPerson={setContactPerson}
                                    setContactPhone={setContactPhone}
                                    setContactTitle={setContactTitle}
                                />}

                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                    disabled={isSubmitting || isDisabled}
                                    type="submit"
                                    className={classNames("w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm", (isSubmitting || isDisabled) ? "bg-gray-300 cursor-default" : "bg-primary-600 text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500")}
                                >
                                    {isSubmitting && step !== 2 && <TailSpinner/>}
                                    {step === 1 && t("next")}
                                    {step === 2 && t("goBack")}
                                    {step === 3 && (isSubmitting ? t("submittingDots") : t("submit"))}
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => setOpen(false)}
                                    ref={cancelButtonRef}
                                >
                                    {t("cancel")}
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition.Root>)
}
