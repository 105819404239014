import React, {Fragment, useEffect, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {classNames, useErrorHandler} from "../utils";
import TailSpinner from "./tailSpinner";
import LoadingDialog from "./LoadingDialog";
import {api} from "../config";
import {UserGroupIcon} from "@heroicons/react/20/solid";

export default function AcceptInvitation({id}) {
    const handleError = useErrorHandler()
    const cancelButtonRef = useRef()
    const [isLoadingDialog, setIsLoadingDialog] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [invitation, setInvitation] = useState(null)

    useEffect(() => {
        //   sessionStorage.removeItem("inv")
        if (id) _loadDialog(id)
    }, [id])

    function onAcceptClick() {
        setIsSubmitting(true)
        api.post(`/organizations/referenced/invite/${id}`)
            .then(res => {
                window.sessionStorage.clear()
                window.location.reload()
            })
            .catch(handleError)
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    function _loadDialog(id) {
        setIsLoadingDialog(true)
        api.get(`/organizations/referenced/invite/${id}`)
            .then(res => {
                console.log("data", res.data)
                if (res.data?.success) {
                    setInvitation(res.data)
                    setIsDialogOpen(true)
                }
            })
            .catch(handleError)
            .finally(() => {
                setIsLoadingDialog(false)
            })
    }

    return (
        <>
            <Transition.Root show={isDialogOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setIsDialogOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">

                                    <div>
                                        <div
                                            className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                            <UserGroupIcon className="h-6 w-6 text-green-600" aria-hidden="true"/>
                                        </div>
                                        <div className="mt-3 text-center sm:mt-5">
                                            <Dialog.Title as="h3"
                                                          className="text-base font-semibold leading-6 text-gray-900">
                                                Invitation
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    You've been invited to <b>{invitation?.organization?.name}</b>. Do
                                                    you wish to accept the
                                                    invitation?
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">

                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => setIsDialogOpen(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            disabled={isSubmitting}
                                            onClick={onAcceptClick}
                                            className={classNames("w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm", (isSubmitting) ? "bg-gray-300 cursor-default" : "bg-primary-600 text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500")}
                                        >
                                            {isSubmitting && <TailSpinner/>}
                                            {isSubmitting ? "Accepting..." : "Accept"}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <LoadingDialog open={isLoadingDialog}/>
        </>
        /*<div>
            <Dialog
                onClose={this._onDialogAction.bind(this)}
                open={isDialogOpen && dialogType === DIALOG_NO_ACCEPT_WITH_ACCESS}>
                <DialogTitle>Accept Invite</DialogTitle>
                <DialogContent>
                    <p>You can not accept access to <b>{invitation.organizationName}</b> as you already have access
                        to another organization.</p>
                </DialogContent>
                <DialogFooter>
                    <DialogButton action='switch'>Switch account</DialogButton>
                    <div className="flex-spacer"/>
                    <DialogButton className="button--dismiss" action='dismiss'>Close</DialogButton>
                </DialogFooter>
            </Dialog>
            <Dialog
                onClose={this._onDialogAction.bind(this)}
                open={isDialogOpen && dialogType === DIALOG_ACCEPT}>
                <DialogTitle>Accept Invite</DialogTitle>
                <DialogContent>
                    <p>You have been invited to <b>{invitation.organizationName}</b>. Do you wish to accept the
                        invitation?</p>
                </DialogContent>
                <DialogFooter>
                    <DialogButton className="button--dismiss" action='dismiss'>Close</DialogButton>
                    <DialogButton action='accept' isDefault>Accept</DialogButton>
                </DialogFooter>
            </Dialog>
            <Dialog
                onClose={this._onDialogAction.bind(this)}
                open={isDialogOpen && dialogType === DIALOG_ALREADY_ACCESS}>
                <DialogTitle>Accept Invite</DialogTitle>
                <DialogContent>
                    <p>You already have access to <b>{invitation.organizationName}</b>.</p>
                </DialogContent>
                <DialogFooter>
                    <DialogButton action='switch'>Switch account</DialogButton>
                    <div className="flex-spacer"/>
                    <DialogButton className="button--dismiss" action='dismiss' isDefault>Close</DialogButton>
                </DialogFooter>
            </Dialog>
            <Dialog
                onClose={this._onDialogAction.bind(this)}
                open={isDialogOpen && dialogType === DIALOG_PROMPT}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <p>{dialogTextContent}</p>
                </DialogContent>
                <DialogFooter>
                    <DialogButton className="button--dismiss" action='dismiss'>Close</DialogButton>
                </DialogFooter>
            </Dialog>
            <LoadingDialog open={isLoadingDialog}/>
        </div>*/
    )
}
