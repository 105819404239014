import React from 'react';
import {createRoot} from 'react-dom/client';
import Root from './Root';
import './i18n';
import * as serviceWorker from './serviceWorker';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import {Provider} from 'react-redux';
import store from './store';
import './interceptors'
import {BrowserRouter} from "react-router-dom";

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <I18nextProvider i18n={i18next}>
        <Provider store={store}>
            <BrowserRouter>
                <Root/>
            </BrowserRouter>
        </Provider>
    </I18nextProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
