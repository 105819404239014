import {initializeApp} from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import {getFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth";
import {getToken, initializeAppCheck, ReCaptchaEnterpriseProvider} from 'firebase/app-check';
import {api, ENVIRONMENT, financeApi, FIREBASE_APPCHECK_DEBUG_MODE, RECAPTCHA, RECAPTCHA_DEBUG_KEY} from "./config";
import {catchErrors} from "./utils";
import i18n from "i18next";

const moveFirebaseConfig = ENVIRONMENT === "dev" ? {
    apiKey: "AIzaSyBSxsdrPMeozfLmKuHpO1zPXcLi-4OWXiE",
    authDomain: "nekster-dev.firebaseapp.com",
    projectId: "nekster-dev",
    storageBucket: "nekster-dev.appspot.com",
    messagingSenderId: "579662412670",
    appId: "1:579662412670:web:148e579bbee0d3246d912f"
} : ENVIRONMENT === "test" ? {
    apiKey: "AIzaSyBYxOKjPH9AO2HVeEv5QWlJl7zimbPfI8w",
    authDomain: "nekster-test.firebaseapp.com",
    projectId: "nekster-test",
    storageBucket: "nekster-test.appspot.com",
    messagingSenderId: "239734218617",
    appId: "1:239734218617:web:894b9a08aa276e1145d9a8"
} : ENVIRONMENT === "prod" ? {
    apiKey: "AIzaSyBPvgRHJFSbEWZek0KFU-T88nxWh39rmTc",
    authDomain: "nekster-prod.firebaseapp.com",
    projectId: "nekster-prod",
    storageBucket: "nekster-prod.appspot.com",
    messagingSenderId: "262645656552",
    appId: "1:262645656552:web:ebf749ba2a81c0c5fbd25d"
} : {}

// Initialize firebase instance
export const firebaseApp = initializeApp(moveFirebaseConfig);

window.FIREBASE_APPCHECK_DEBUG_TOKEN = FIREBASE_APPCHECK_DEBUG_MODE;
const appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaEnterpriseProvider(FIREBASE_APPCHECK_DEBUG_MODE ? RECAPTCHA_DEBUG_KEY : RECAPTCHA),
        isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
    } // ReCaptchaV3Provider or CustomProvider
)

// Initialize other services on firebase instance
export const firestore = getFirestore(firebaseApp) // <- needed if using
export const auth = getAuth(firebaseApp) // <- needed if using firestore
auth.settings.appVerificationDisabledForTesting = FIREBASE_APPCHECK_DEBUG_MODE
//auth.useDeviceLanguage();
auth.languageCode = i18n.language

i18n.on('languageChanged', lng => {
    //document.documentElement.setAttribute('lang', lng)
    auth.languageCode = lng
    console.log("Language changed to: " + lng)
})


export async function callApiWithAppCheckExample(config) {
    let appCheckTokenResponse;
    try {
        appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ false);

        financeApi.defaults.headers.common['X-Firebase-AppCheck'] = appCheckTokenResponse?.token;
        api.defaults.headers.common['X-Firebase-AppCheck'] = appCheckTokenResponse?.token;
        if (config) config.headers['X-Firebase-AppCheck'] = appCheckTokenResponse?.token;
    } catch (err) {
        // Handle any errors if the token was not retrieved.
        catchErrors(err)
    }
}
