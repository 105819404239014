import {api, financeApi} from "./config";
import {auth, callApiWithAppCheckExample} from "./firebase";
import i18n from "i18next";

export let tokenExpiresAt = 0

function setHeaders(user) {
    return user.getIdTokenResult()
        .then(res => {
            financeApi.defaults.headers.common['Authorization'] = "Bearer " + res.token;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.token;
            tokenExpiresAt = res.claims?.exp * 1000
        }).catch(err => {
            console.error(err)
            tokenExpiresAt = 0
        })
}

export async function authorizeRequest(user) {
    if (user) {
        return Promise.all([setHeaders(user), callApiWithAppCheckExample()])
    } else {
        financeApi.defaults.headers.common['Authorization'] = ""
        api.defaults.headers.common['Authorization'] = ""
        tokenExpiresAt = 0
    }
}

/*i18n.on('languageChanged', lng => {
    financeApi.defaults.headers.common['Accept-Language'] = lng
    api.defaults.headers.common['Accept-Language'] = lng
})*/

async function requestInterceptor(config) {
    // language
    config.headers['Accept-Language'] = i18n?.language || i18n?.options?.fallbackLng[0] || "en";

    // app check
    await callApiWithAppCheckExample(config)

    // refresh token
    if (tokenExpiresAt < Date.now()) {
        //console.log("token expired")
        auth.currentUser?.getIdTokenResult && await auth.currentUser?.getIdTokenResult(true).then(res => {
            financeApi.defaults.headers.common['Authorization'] = "Bearer " + res.token;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.token;
            tokenExpiresAt = res.claims.exp * 1000
            config.headers['Authorization'] = "Bearer " + res.token;
        }).catch(err => {
            console.error(err)
            tokenExpiresAt = 0
        })
    }
    return config;
}

function requestErrorInterceptor(error) {
    return Promise.reject(error);
}

function responseInterceptor(response) {
    return response;
}

function responseErrorInterceptor(error) {
    if (error.response?.status === 401) {
        window.location.reload()
    }
    return Promise.reject(error);
}


api.interceptors.request.use(requestInterceptor, requestErrorInterceptor);
financeApi.interceptors.request.use(requestInterceptor, requestErrorInterceptor);

api.interceptors.response.use(responseInterceptor, responseErrorInterceptor);
financeApi.interceptors.response.use(responseInterceptor, responseErrorInterceptor);
