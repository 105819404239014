import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {classNames} from "../../utils";

const inputName = "email"

export default function FinancingData({data, setData, error, setError, nextState, prevState, allData}) {
    const {t} = useTranslation()
    const navigate = useNavigate();

    useState(() => {
        if (!data?.list) {
            setData({...data, list: [["", ""]]})
        } else if (data.list.length > 1) {

        }
    }, [data])

    function onValueChange(e, entry, fieldIndex, idx) {
        const list = [...data?.list].filter(v => v[0] || v[1]),
            newEntry = [...entry]

        newEntry[fieldIndex] = e.target.value

        list[idx] = newEntry

        if (list[list.length - 1]?.join("") !== "") list.push(["", ""])


        setData({...data, list: list})
    }

    function onSubmit(e) {
        e.preventDefault();

        if (!data) {
            setError("Please select financing type")
        } else {
            navigate("/quote/" + nextState.nextStepName)
        }
    }

    return (
        <form onSubmit={onSubmit} className="w-full max-w-sm">
            {allData["financing"] === "RF" ? (
                <div className="">
                    <div>
                        <h3 className="text-base font-semibold leading-6 text-white">Volume of financing</h3>
                        <p className="mt-1 text-sm text-gray-50">Enter financing amount and number of days.</p>
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-y-2">

                        <div className={classNames(
                            "group grid grid-cols-2 gap-x-4"
                        )}>
                            <div>
                                <label className="block text-sm font-medium leading-6 text-white">
                                    Sum amount
                                </label>
                                <div className="relative mt-2 rounded-md shadow-sm">
                                    <input
                                        type="text"
                                        required
                                        value={data?.amount || ""}
                                        onChange={e => setData({...data, amount: e.target.value})}
                                        placeholder="e.g. 10.000,00"
                                        className={classNames(
                                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6",
                                        )}
                                    />
                                    <div
                                        className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                        <span className="text-gray-500 sm:text-sm" id="price-currency">EUR</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium leading-6 text-white">
                                    Days of extension
                                </label>
                                <div className="relative mt-2 rounded-md shadow-sm">
                                    <input
                                        type="text"
                                        required
                                        value={data?.extension || ""}
                                        onChange={e => setData({...data, extension: e.target.value})}
                                        placeholder="e.g. 60"
                                        className={classNames(
                                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6",
                                        )}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            ) : (
                <div className="">
                    <div>
                        <h3 className="text-base font-semibold leading-6 text-white">Debtor</h3>
                        <p className="mt-1 text-sm text-gray-50">Enter debtor's tax number and sum of the claim.</p>
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-y-2">
                        {data?.list?.map((entry, idx) => (
                            <div key={"a" + idx} className={classNames(
                                "group grid grid-cols-2 gap-x-4"
                            )}>
                                <div>
                                    {idx === 0 && (
                                        <label className="block text-sm font-medium leading-6 text-white">
                                            Tax number
                                        </label>
                                    )}
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            required={entry[0] || entry[1]}
                                            value={entry[0] || ""}
                                            onChange={e => onValueChange(e, entry, 0, idx)}
                                            placeholder="e.g. SI12345678"
                                            className={classNames(
                                                "transition-opacity block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6",
                                                (idx === 0 || entry[0] || entry[1]) ? "" : "opacity-50 group-hover:opacity-100 group-focus:opacity-100"
                                            )}
                                        />
                                    </div>
                                </div>
                                <div key={"b" + idx}>
                                    {idx === 0 && (
                                        <label className="block text-sm font-medium leading-6 text-white">
                                            Claim sum
                                        </label>
                                    )}
                                    <div className="relative mt-2 rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            required={entry[0] || entry[1]}
                                            value={entry[1] || ""}
                                            onChange={e => onValueChange(e, entry, 1, idx)}
                                            aria-describedby="price-currency"
                                            placeholder="e.g. 10.000,00"
                                            className={classNames(
                                                "transition-opacity block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6",
                                                (idx === 0 || entry[0] || entry[1]) ? "" : "opacity-50 group-hover:opacity-100 group-focus:opacity-100"
                                            )}
                                        />
                                        <div
                                            className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                            <span className="text-gray-500 sm:text-sm" id="price-currency">EUR</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}


            {error && (
                <p className="mt-2 text-sm text-red-600" id={inputName + "-error"}>
                    {error}
                </p>
            )}
            <div className="mt-8 flex space-x-4">
                <Link
                    to={"/quote/" + prevState.prevStepName}
                    className="rounded-md bg-white/10 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                >
                    Back
                </Link>
                <button
                    type="submit"
                    disabled={!data}
                    className={classNames(
                        "rounded-md bg-primary-700 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-700",
                        !data ? "opacity-75 cursor-not-allowed" : ""
                    )}
                >
                    Next
                </button>
            </div>
        </form>
    )
}
