import React from "react";
import {ScaleIcon} from "@heroicons/react/20/solid";
import {useTranslation} from "react-i18next";
import {classNames} from "../../utils";
import {Link} from "react-router-dom";
import Cashflow from "../cashflow/cashflow";
import {BanknotesIcon, ClipboardDocumentIcon, CurrencyDollarIcon, TruckIcon} from "@heroicons/react/24/outline";
import {useSelector} from "react-redux";
import {clientSelector, realActiveCidSelector} from "../../store/client";
import ActionsBar from "../../components/actionsBar";

const secondaryNavigation = [
    {name: 'Last 7 days', href: '#', current: true},
    {name: 'Last 30 days', href: '#', current: false},
    {name: 'All-time', href: '#', current: false},
]
const stats = [
    {
        percentage: '40',
        name: 'Financial debt',
        value: '54.312 €',
        subName: 'Debt to Ebida',
        subValue: '0.37'
    },
    {
        percentage: '60',
        name: 'Gross profit',
        value: '368.310 €',
        subName: 'Gross profit margin',
        subValue: '42 %'
    },
    {
        percentage: '70',
        name: 'Net profit',
        value: '213.401 €',
        subName: 'Net profit margin',
        subValue: '67 %'
    },
    {
        percentage: '60',
        name: 'Max financial debt',
        value: '57.019 €',
        subName: 'Avail. financial debt',
        subValue: '45.096 €'
    },
]

export default function Dashboard() {
    const {t} = useTranslation()
    const activeOrganizationId = useSelector(realActiveCidSelector)
    const organization = useSelector(clientSelector(activeOrganizationId))

    const cards = [{name: t('payables'), href: '#', icon: ScaleIcon, amount: '$30,659.45'}, {
        name: t('receivables'),
        href: '#',
        icon: ScaleIcon,
        amount: '$30,659.45'
    }, {name: t('limit'), href: '#', icon: ScaleIcon, amount: '$30,659.45'}, // More items...
    ]

    const actions = [
        {
            title: t('supplierFinancing'),
            summary: t('max') + " 32.846 € / " + t('used') + " 7.850 €",
            href: '/u/payables',
            icon: TruckIcon,
            iconForeground: 'text-rose-700',
            iconBackground: 'bg-rose-50',
        },
        {
            title: t("invoiceFinancing"),
            summary: t('max') + " 32.846 € / " + t('used') + " 7.850 €",
            href: '/u/receivables',
            icon: CurrencyDollarIcon,
            iconForeground: 'text-yellow-700',
            iconBackground: 'bg-yellow-50',
        },
        {
            title: t("bankLoans"),
            summary: t('max') + " 32.846 € / " + t('used') + " 7.850 €",
            href: '#',
            icon: BanknotesIcon,
            iconForeground: 'text-teal-700',
            iconBackground: 'bg-teal-50',
        },
        {
            title: t("commercialPapers"),
            summary: t("issuanceOfCommercialPapers"),
            href: '#',
            icon: ClipboardDocumentIcon,
            iconForeground: 'text-primary-700',
            iconBackground: 'bg-primary-50',
        },
        /*{
            title: "Obveznica",
            summary: "Izdajanje obveznic",
            href: '#',
            icon: AcademicCapIcon,
            iconForeground: 'text-primary-700',
            iconBackground: 'bg-primary-50',
        },*/
    ]

    return (
        <>
            <div className="relative isolate overflow-hidden">
                {/* Secondary navigation */}
                <header className="pb-4 pt-6 sm:pb-6">
                    <div
                        className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
                        <h1 className="text-base font-semibold leading-7 text-gray-900">{t('overview')}</h1>
                        <div
                            className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
                            <span className="text-gray-700">
                                {organization?.name}
                            </span>
                            {/*{secondaryNavigation.map((item) => (<a key={item.name} href={item.href}
                                                                   className={item.current ? 'text-primary-600' : 'text-gray-700'}>
                                {item.name}
                            </a>))}*/}

                        </div>
                        {/*<a
                            href="#"
                            className="ml-auto flex items-center gap-x-1 rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                        >
                            <PlusSmallIcon className="-ml-1.5 h-5 w-5" aria-hidden="true"/>
                            New invoice
                        </a>*/}
                    </div>
                </header>


                {/* Stats */}
                <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
                    <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
                        {stats.map((stat, statIdx) => (<div
                            key={stat.name}
                            className={classNames(
                                /*statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : '',*/
                                'flex items-baseline flex-wrap mx-auto justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8')}
                        >
                            {/*<dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
                            <dd
                                className={classNames(stat.changeType === 'negative' ? 'text-rose-600' : 'text-gray-700', 'text-xs font-medium')}
                            >
                                {stat.change}
                            </dd>
                            <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                                {stat.value}
                            </dd>*/}

                            <div className="relative">
                                <div className="absolute">
                                    <div className="transform-gpu pie rotate-[225deg]"
                                         style={{"--p": "75", "--c": "rgb(243 244 246)"}}/>
                                </div>
                                <div className="">
                                    <div className="transform-gpu pie animate rotate-[225deg]"
                                         style={{"--p": stat.percentage, "--c": "rgb(12 170 208)"}}>
                                        <div className="-rotate-[225deg]">
                                            <div
                                                className="flex flex-col justify-center items-center text-xs text-gray-500 gap-0.5">
                                                <span>{stat.name}</span>
                                                <span
                                                    className="text-primary-500 font-medium text-xl">{stat.value}</span>
                                                <div className="border-gray-200 border-b w-16 my-1"/>
                                                <span className="">{stat.subName}</span>
                                                <span className="text-black text-base">{stat.subValue}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>))}
                    </dl>
                </div>

                <div
                    className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
                    aria-hidden="true"
                >
                    <div
                        className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#0caad0] to-[#9089FC]"
                        style={{
                            clipPath: 'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
                        }}
                    />
                </div>
            </div>
            <div className="hidden">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <h2 className="mx-auto max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none">
                        {t('overview')}
                    </h2>
                </div>
                <div className="mt-6 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                        {/* Card */}
                        {cards.map((card) => (
                            <div key={card.name} className="overflow-hidden rounded-lg bg-white shadow">
                                <div className="p-5">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <card.icon className="h-6 w-6 text-gray-400" aria-hidden="true"/>
                                        </div>
                                        <div className="ml-5 w-0 flex-1">
                                            <dl>
                                                <dt className="truncate text-sm font-medium text-gray-500">{card.name}</dt>
                                                <dd>
                                                    <div
                                                        className="text-lg font-medium text-gray-900">{card.amount}</div>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-5 py-3">
                                    <div className="text-sm">
                                        <a href={card.href}
                                           className="font-medium text-cyan-700 hover:text-cyan-900">
                                            {t('viewAll')}
                                        </a>
                                    </div>
                                </div>
                            </div>))}
                    </div>
                </div>
            </div>
            <div className="space-y-16 py-16 xl:space-y-20">
                <div>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <ActionsBar actions={actions}/>
                    </div>
                </div>

                {organization?.features?.FINANCE_CASHFLOW && (
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                            <div className="flex items-center justify-between">
                                <h2 className="text-base font-semibold leading-7 text-gray-900"> {t('cashflow')}</h2>
                                <Link to="/u/cashflow"
                                      className="text-sm font-semibold leading-6 text-primary-600 hover:text-primary-500">
                                    {t("viewAll")}<span className="sr-only">, t('cashflow')</span>
                                </Link>
                            </div>
                            <div className="mt-6">
                                <Cashflow overview/>
                            </div>
                        </div>
                    </div>
                )}


            </div>
        </>
    )
}
