import React, {forwardRef} from "react";
import {useTranslation} from "react-i18next";
import {classNames} from "../utils";
import {ChevronRightIcon} from "@heroicons/react/20/solid";

export const TextField = forwardRef(({
                                         label,
                                         type,
                                         pattern,
                                         name,
                                         autoComplete,
                                         defaultValue,
                                         value,
                                         required,
                                         fetching = false,
                                         error = null,
                                         placeholder,
                                         disabled,
                                         hideSubmit = false,
                                         onChange,
                                         autoFocus = true,
                                         children,
                                     }, ref) => {
    const {t} = useTranslation('register')

    return (
        <div className="flex gap-2 relative w-full mb-6 sm:max-w-[288px] dark">
            <input
                autoFocus={autoFocus}
                ref={ref}
                style={{colorScheme: "dark"}}
                className={classNames(
                    "peer w-full border-0 rounded-t-sm !bg-primary-500 !autofill:bg-primary-500 py-1.5 text-white disabled:text-primary-200 placeholder:text-primary-200 shadow-sm focus:ring-0 sm:text-sm sm:leading-6",
                    hideSubmit ? "basis-full" : "basis-auto"
                )}
                onChange={onChange}
                type={type || "text"}
                pattern={pattern}
                name={name}
                disabled={disabled || fetching}
                aria-invalid={error ? "true" : undefined}
                value={value}
                defaultValue={defaultValue}
                autoComplete={autoComplete}
                /*onChange={handleChange}*/
                required={required}
                placeholder={placeholder || label}/>
            <div
                className="absolute inset-x-0 bottom-0 border-t border-primary-300 peer-focus:border-t-2 peer-focus:border-primary-600 peer-[[aria-invalid]]:border-red-600"
                aria-hidden="true"
            />
            {children}
            {!hideSubmit && (
                <button
                    id="submit-btn"
                    type="submit"
                    disabled={disabled || fetching}
                    className="basis-8 opacity-100 disabled:opacity-40 disabled:cursor-default flex items-center my-1 rounded-md bg-primary-400 p-1 text-white disabled:bg-primary-300 disabled:text-primary-50 shadow-sm hover:bg-primary-300 disabled:hover:bg-primary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                >
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                </button>
            )}
            {label && !error ? (
                <span
                    className="absolute inset-x-0 -mt-px pt-0.5 top-10 text-left text-primary-50 text-xs">{label}</span>
            ) : (
                <span
                    className={classNames(
                        "line-clamp-1 absolute inset-x-0 -mt-px pt-0.5 top-10 invisible peer-[[aria-invalid]]:visible text-left text-red-600 font-medium text-xs",
                        label ? "pt-4.5" : "pt-0.5"
                    )}>{t(error)}</span>
            )}
        </div>
    )
})
