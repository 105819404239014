import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {
    AdjustmentsVerticalIcon,
    BuildingOfficeIcon,
    ExclamationTriangleIcon,
    PlusIcon,
    TrashIcon,
    WrenchScrewdriverIcon
} from "@heroicons/react/20/solid";
import {
    clientSelector,
    fetchOrganizationIntegrations,
    organizationIntegrationsSelector,
    realActiveCidSelector,
    removeOrganizationIntegrations
} from "../../store/client";
import {useDispatch, useSelector} from "react-redux";
import AccountingIntegrationSlide from "./providers/accountingIntegrationSlide";

export default function IntegrationsTab() {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [minimaxDialogOpen, setMinimaxDialogOpen] = useState(false)
    const [goToFinishSetup, setGoToFinishSetup] = useState(false)
    const activeOrgId = useSelector(realActiveCidSelector)
    const integrationsMap = useSelector(organizationIntegrationsSelector)

    const activeOrganizationId = useSelector(realActiveCidSelector)
    const organization = useSelector(clientSelector(activeOrganizationId))
    const isDisabled = organization?.features?.FINANCE_INTEGRATIONS && organization?.status !== "ACTIVE"

    useEffect(() => {
        dispatch(fetchOrganizationIntegrations(activeOrgId))
    }, [])

    function fetchUpdates() {
        dispatch(fetchOrganizationIntegrations(activeOrgId))
    }

    useEffect(() => {
        !minimaxDialogOpen && dispatch(fetchOrganizationIntegrations(activeOrgId))
    }, [minimaxDialogOpen])

    useEffect(() => {
        if (integrationsMap?.accounting?.credentials?.provider === "minimax") {
            setGoToFinishSetup(!integrationsMap?.accounting?.credentials?.organizationId)
        }

    }, [activeOrgId, integrationsMap])

    return (
        <>
            {isDisabled && (
                <dl className="-mt-4 mb-6 space-y-6 text-sm leading-6">
                    <div className="rounded-md bg-orange-50 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <ExclamationTriangleIcon className="h-5 w-5 text-orange-400"
                                                         aria-hidden="true"/>
                            </div>
                            <div className="ml-3 flex-1 md:flex md:justify-between">
                                <p className="text-sm text-orange-700"><Trans
                                    i18nKey="certainFeaturesIncludingIntegrationsAreInitially"
                                    components={{
                                        b: <b/>
                                    }}
                                /></p>
                            </div>
                        </div>
                    </div>
                </dl>
            )}
            <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">{t('integrations')}</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">{t('connectWithOutsideServices')}</p>


                <div className="mt-6">
                    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">

                        <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                            <div className="flex w-full items-center justify-between space-x-6 p-6">
                                <div className="flex-1 truncate">
                                    <div className="flex items-center space-x-3">
                                        <h3 className="truncate text-sm font-medium text-gray-900">{t('accountingSoftware')}</h3>
                                        {integrationsMap?.accounting?.status === "active" ? (
                                            <span
                                                className="inline-block flex-shrink-0 rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">{t('active')}</span>
                                        ) : (
                                            <span
                                                className="inline-block flex-shrink-0 rounded-full bg-gray-100 px-2 py-0.5 text-xs font-medium text-gray-800">{t('inactive')}</span>
                                        )}

                                    </div>
                                    <p className="mt-1 truncate text-sm text-gray-500">{t('accountingSoftwareDescription')}</p>
                                </div>
                                <div className="h-10 w-10 flex rounded-full bg-gray-300 justify-center items-center">
                                    <BuildingOfficeIcon className="h-6 w-6 text-gray-500"/>
                                </div>
                            </div>
                            <div>
                                <div className="-mt-px flex divide-x divide-gray-200">
                                    <div className="flex w-0 flex-1">
                                        {integrationsMap?.accounting ? (
                                            <button
                                                disabled={isDisabled}
                                                type="button"
                                                onClick={() => setMinimaxDialogOpen(true)}
                                                className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900 hover:text-gray-700 disabled:cursor-default disabled:opacity-50 disabled:hover:text-gray-900"
                                            >
                                                {goToFinishSetup ?
                                                    <WrenchScrewdriverIcon className="h-5 w-5 text-gray-400"
                                                                           aria-hidden="true"/> :
                                                    <AdjustmentsVerticalIcon className="h-5 w-5 text-gray-400"
                                                                             aria-hidden="true"/>}
                                                {goToFinishSetup ? t('finishSetup') : t('manage')}
                                            </button>
                                        ) : (
                                            <button
                                                disabled={isDisabled}
                                                type="button"
                                                onClick={() => setMinimaxDialogOpen(true)}
                                                className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500 disabled:cursor-default disabled:opacity-50 disabled:hover:text-gray-700"
                                            >
                                                <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                                <span className="ml-3">{t('addIntegration')}</span>
                                            </button>
                                        )}
                                    </div>
                                    {integrationsMap?.accounting && (
                                        <div className="-ml-px flex w-0 flex-1">
                                            <button
                                                type="button"
                                                onClick={() => window.confirm(t('removeIntegrationConfirmation')) && dispatch(removeOrganizationIntegrations(activeOrgId, "accounting", window.confirm(t('confirmIfYouWithToDeleteSyncedData'))))}
                                                className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-red-700 hover:text-red-500"
                                            >
                                                <TrashIcon className="h-5 w-5 text-red-400" aria-hidden="true"/>
                                                <span className="ml-3">{t('remove')}</span>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>

            <AccountingIntegrationSlide setOpen={setMinimaxDialogOpen} open={minimaxDialogOpen}
                                        provider={integrationsMap?.accounting} fetchUpdates={fetchUpdates}/>
        </>
    );
}
