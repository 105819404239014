import {Fragment, useEffect, useState} from 'react'
import {Combobox, Dialog, Transition} from '@headlessui/react'
import {MagnifyingGlassIcon} from '@heroicons/react/20/solid'
import {BuildingOfficeIcon, FunnelIcon, XCircleIcon} from '@heroicons/react/24/outline'
import {classNames, formatAddress} from "../utils";
import {useTranslation} from "react-i18next";
import TailSpinner from "./tailSpinner";

export function InputPalette({
                                 recent,
                                 setRecentBacklog,
                                 onAutocomplete,
                                 suggestions,
                                 query,
                                 setQuery,
                                 onSearch,
                                 quickActions,
                                 placeholder,
                             }) {
    const {t} = useTranslation()

    const [isLoadingAutoComplete, setIsLoadingAutoComplete] = useState(false)
    const [autocompleteResults, setAutocompleteResults] = useState([])
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

    useEffect(() => {
        if (onAutocomplete) {
            setIsLoadingAutoComplete(true)
            const timerId = setTimeout(() => {
                setDebouncedSearchTerm(query);
            }, 500);

            return () => {
                clearTimeout(timerId);
            };
        }
    }, [query]);

    useEffect(() => {
        if (debouncedSearchTerm) {
            // Perform your query here using debouncedSearchTerm
            console.log('Querying database with:', debouncedSearchTerm);

            setIsLoadingAutoComplete(true)
            onAutocomplete(query)
                .then(res => {
                    console.log("onAutocomplete res", res)
                    setAutocompleteResults(res.map(it => ({id: "oid", name: "Company", value: it, autocomplete: true})))
                })
                .finally(() => {
                    console.log("onAutocomplete done")
                    setIsLoadingAutoComplete(false)

                })
        }
    }, [debouncedSearchTerm]);


    function onSelect(item) {
        if (item) {
            const value = item.recent || item.autocomplete ? item.value : query,
                selected = {...item, value},
                filtered = recent.filter(it => !(it.id === selected.id && it.value === value)) // remove existing identical item
            filtered.unshift({...selected, recent: true})
            if (filtered.length > 4) filtered.pop()
            if (!item.autocomplete) setRecentBacklog(filtered)
            onSearch && onSearch(selected)
        }
    }

    return <Combobox onChange={onSelect}>
        <div className="relative">
            <MagnifyingGlassIcon
                className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-900 text-opacity-40"
                aria-hidden="true"
            />
            <Combobox.Input
                className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                placeholder={placeholder || t('searchDots')}
                onChange={(event) => setQuery(event.target.value)}
            />
        </div>

        {(query === '' || suggestions?.length > 0 || autocompleteResults?.length > 0 || isLoadingAutoComplete) && (
            <Combobox.Options
                static
                className="max-h-80 scroll-py-2 divide-y divide-gray-500 divide-opacity-10 overflow-y-auto"
            >
                {onAutocomplete && query !== '' && (
                    <li className="p-2">
                        <ul className="text-sm text-gray-700">
                            {(!isLoadingAutoComplete && autocompleteResults.length ? autocompleteResults : []).map((item, idx) => (
                                <Combobox.Option
                                    key={idx}
                                    value={item}
                                    className={({active}) =>
                                        classNames(
                                            'flex cursor-default select-none items-center rounded-md px-3 py-2',
                                            active && 'bg-gray-900 bg-opacity-5 text-gray-900'
                                        )
                                    }
                                >
                                    {({active}) => (
                                        <>
                                            <BuildingOfficeIcon
                                                className={classNames(
                                                    'h-6 w-6 flex-none text-gray-900 text-opacity-40',
                                                    active && 'text-opacity-100'
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span className="ml-3 flex-auto truncate">
                                                <span className="flex flex-col">
                                                    <span
                                                        className="font-medium">{item.value?.name}</span>
                                                    <span
                                                        className="">{formatAddress(item.value?.address)}</span>
                                                                </span>
                                                </span>
                                            {active && <span
                                                className="ml-3 flex-none text-gray-500">{t('jumpToDots')}</span>}
                                        </>
                                    )}
                                </Combobox.Option>
                            ))}
                            {isLoadingAutoComplete && (
                                <li

                                    className={
                                        classNames(
                                            'flex cursor-default select-none items-center rounded-md px-3 py-2',
                                        )
                                    }
                                >
                                    <TailSpinner/>
                                </li>
                            )}
                        </ul>
                    </li>
                )}
                {((query === '' && recent.length > 0) || (query !== '' && suggestions?.length > 0)) && (
                    <li className="p-2">
                        {query === '' && recent.length > 0 && (
                            <h2 className="mt-4 mb-2 px-3 text-xs font-semibold text-gray-900">{t('recentSearches')}</h2>
                        )}
                        <ul className="text-sm text-gray-700">
                            {((query === '' ? recent : suggestions) || []).map(item => (
                                <Combobox.Option
                                    key={item.id}
                                    value={item}
                                    className={({active}) =>
                                        classNames(
                                            'flex cursor-default select-none items-center rounded-md px-3 py-2',
                                            active && 'bg-gray-900 bg-opacity-5 text-gray-900'
                                        )
                                    }
                                >
                                    {({active}) => (
                                        <>
                                            <FunnelIcon
                                                className={classNames(
                                                    'h-6 w-6 flex-none text-gray-900 text-opacity-40',
                                                    active && 'text-opacity-100'
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span className="ml-3 flex-auto truncate">
                                                                    {item.name + ((query !== "" || item.value) ? ":" : "")} {(query !== "" || item.value) &&
                                                <span
                                                    className="font-medium">{query || item.value}</span>}
                                                                </span>
                                            {active && <span
                                                className="ml-3 flex-none text-gray-500">{t('filterDots')}</span>}
                                        </>
                                    )}
                                </Combobox.Option>
                            ))}
                        </ul>
                    </li>
                )}
                {query === '' && false && (
                    <li className="p-2">
                        <h2 className="sr-only">{t('quickActions')}</h2>
                        <ul className="text-sm text-gray-700">
                            {quickActions.map((action) => (
                                <Combobox.Option
                                    key={action.shortcut}
                                    value={action}
                                    className={({active}) =>
                                        classNames(
                                            'flex cursor-default select-none items-center rounded-md px-3 py-2',
                                            active && 'bg-gray-900 bg-opacity-5 text-gray-900'
                                        )
                                    }
                                >
                                    {({active}) => (
                                        <>
                                            <action.icon
                                                className={classNames(
                                                    'h-6 w-6 flex-none text-gray-900 text-opacity-40',
                                                    active && 'text-opacity-100'
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span
                                                className="ml-3 flex-auto truncate">{action.name}</span>
                                            {/*<span
                                                                        className="ml-3 flex-none text-xs font-semibold text-gray-500">
                                    <kbd className="font-sans">⌘</kbd>
                                    <kbd className="font-sans">{action.shortcut}</kbd>
                                  </span>*/}
                                        </>
                                    )}
                                </Combobox.Option>
                            ))}
                        </ul>
                    </li>
                )}
            </Combobox.Options>
        )}

        {query !== '' && (suggestions?.length === 0 || (onAutocomplete && autocompleteResults?.length === 0 && !isLoadingAutoComplete)) && (
            <div className="py-14 px-6 text-center sm:px-14">
                <XCircleIcon className="mx-auto h-6 w-6 text-gray-900 text-opacity-40"
                             aria-hidden="true"/>
                <p className="mt-4 text-sm text-gray-900">
                    {t('weCouldntFindAnyResultsMatchingThatTermPleaseTryAg')}
                </p>
            </div>
        )}
    </Combobox>
}

export default function TableViewPalette({
                                             open,
                                             setOpen,
                                             onSearch,
                                             onAutocomplete,
                                             suggestions,
                                             quickActions,
                                             placeholder,
                                         }) {
    const [query, setQuery] = useState('')
    const [recent, setRecent] = useState([])
    const [recentBacklog, setRecentBacklog] = useState([])

    useEffect(() => {
        if (open) {
            setRecent(recentBacklog)
            setQuery('')
        }
    }, [open])

    function onBeforeSearch(items) {
        onSearch && onSearch(items)
        setOpen(false)
    }

    return (
        <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
            <Dialog as="div" className="relative z-20" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-20 overflow-y-auto p-4 sm:p-6 md:p-20">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel
                            className="mx-auto max-w-2xl transform divide-y divide-gray-500 divide-opacity-10 overflow-hidden rounded-xl bg-white bg-opacity-80 shadow-2xl ring-1 ring-black ring-opacity-5 backdrop-blur backdrop-filter transition-all">
                            <InputPalette onSearch={onBeforeSearch} suggestions={suggestions}
                                          onAutocomplete={onAutocomplete}
                                          quickActions={quickActions} placeholder={placeholder}
                                          query={query} setQuery={setQuery} recent={recent}
                                          setRecentBacklog={setRecentBacklog}/>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
