import {classNames} from "../helpers/helpers";
import React, {Fragment, useEffect, useLayoutEffect, useRef, useState} from "react";
import {Menu, Transition} from '@headlessui/react'
import TailSpinner from "./tailSpinner";
import {Bars4Icon, ChevronDownIcon, ChevronUpIcon, EllipsisVerticalIcon} from "@heroicons/react/20/solid";
import {Trans, useTranslation} from "react-i18next";
import {getWord} from "../utils";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {setTableParams, tableSelector} from "../store/table";
import SearchBox from "./searchBox";

const tableHeader = "sticky top-0 bg-gray-50 bg-opacity-75 backdrop-blur backdrop-filter" // or "relative"

export default function TableView({
                                      id,
                                      columns,
                                      onLoadMore,
                                      data,
                                      loading,
                                      hasMore,
                                      hasPrevious,
                                      hasNext,
                                      tableActions,
                                      rowActions,
                                      selectable = true,
                                      emptyValue = "-",
                                      selectorFilters,
                                      tableFilters,
                                      onSearch,
                                      searchOptions,
                                      defaultSort,
                                      setSelected,
                                      showNavigation = true,
                                      className,
                                  }) {
    const {t} = useTranslation()
    const checkbox = useRef()
    const dispatch = useDispatch()
    const {params} = useSelector(tableSelector(id))
    const [frozen, setFrozen] = useState(false)
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const [sorted, setSorted] = useState(defaultSort || ["", ""])

    useLayoutEffect(() => {
        const isIndeterminate = selectedItems.length > 0 && selectedItems.length < data?.length
        setChecked(selectedItems.length === data?.length && selectedItems.length > 0)
        setIndeterminate(isIndeterminate)
        if (checkbox?.current) checkbox.current.indeterminate = isIndeterminate
        if (setSelected) setSelected([...selectedItems])
    }, [checkbox, selectedItems])

    useEffect(() => {
        if (frozen) setFrozen(false)

        const newSelectedItems = [], selectedIds = selectedItems.reduce((res, cur) => {
            res[cur?.id] = true
            return res
        }, {})
        for (let i = 0; i < data.length; i++) {
            const it = data[i]
            if (selectedIds.hasOwnProperty(it?.id)) {
                newSelectedItems.push(it)
            }
        }
        setSelectedItems(newSelectedItems)
    }, [data])

    useLayoutEffect(() => {
        doSearch(sorted)
    }, [tableFilters?.checkedFilters])

    function toggleAll() {
        setSelectedItems(checked || indeterminate ? [] : (data || []))
        setChecked(!checked && !indeterminate && selectedItems.length > 0)
        setIndeterminate(false)
    }

    function onSelectorFilterClick(filter) {
        setSelectedItems(data.filter(filter.filter))
        setChecked(false)
    }


    function onSort(sortName) {
        let sortDirection = "asc"
        if (sorted[0] === sortName) sortDirection = sorted[1] === "asc" ? "desc" : "asc"
        doSearch([sortName, sortDirection])
    }

    function onBeforeSearch(search) {
        const checked = {
            //...checkedFilters,
            [search.id]: {id: search.id, value: search.value, label: search.name, valueLabel: search.value}
        }
        doSearch()
    }

    /*function removeFilter(filter) {
        return () => {
            //const checked = {}
            const checked = {...checkedFilters}
            delete checked[filter.id]
            doSearch(checked, ["", ""])
        }
    }*/

    function doSearch(sortPair) {
        sortPair = sortPair || sorted
        //console.log("doSearch", filters, sortPair)
        const filters = tableFilters?.checkedFilters || {}

        let _params = null
        if (onSearch) {
            const values = Object.values(filters);
            if (values.length > 0) values.forEach(({id, value}) => _params = {filter: [id, "==", value]})
            if (sortPair) _params = {..._params, orderBy: sortPair[0], orderByDirection: sortPair[1], offset: 0}
            onSearch(_params)
        } else {
            console.debug("onSearch is not defined")
        }
        setSorted(sortPair)
        setFrozen(!shallowEqual(_params, params))
        dispatch(setTableParams(id, _params))
    }

    return (
        <>
            <div className="flex flex-col">
                {searchOptions && (
                    <div className="px-6 py-4 flex items-center">
                        {searchOptions && (
                            <div className="mr-2">
                                <SearchBox searchOptions={searchOptions} onSearch={onBeforeSearch}/>
                            </div>
                        )}
                        {/* TODO: remove from table view */}
                        {/*{Object.values(checkedFilters).map((checked, idx) => (
                            <button
                                key={idx}
                                onClick={removeFilter(checked)}
                                type="button"
                                className="ml-1 inline-flex items-center px-3 py-2 border border-gray-200 text-xs font-medium rounded-full leading-4 text-gray-500 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            >
                                <XMarkIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true"/>
                                <span className="sr-only">{t('removeFilter')}</span>
                                {checked?.label}: {checked?.valueLabel}
                            </button>
                        ))}*/}
                    </div>
                )}
                <div className="relative">
                    <table className={classNames(
                        "min-w-full divide-y divide-gray-900/5",
                        className,
                    )}>
                        <thead className="bg-gray-50 sm:rounded-t-xl">
                        <tr>
                            {selectable && (
                                <th scope="col" className={classNames(
                                    tableHeader,
                                    "z-10 w-12 px-8 sm:w-16 sm:px-9 sm:rounded-tl-xl",
                                )}>
                                    <span>
                                        {selectorFilters && (
                                            <span className="group">
                                                <Menu as="span"
                                                      className="absolute left-2 sm:left-4 top-1/2 -mt-4 h-8 w-12 rounded-lg">
                                                    <Menu.Button
                                                        type="button"
                                                        className="group-hover:bg-gray-200 w-full h-full rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                                    >
                                                      <span className="sr-only">{t('options')}</span>
                                                       <ChevronDownIcon className="ml-7 h-4 w-4 text-gray-400"/>
                                                    </Menu.Button>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                    <Menu.Items
                                                        className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <div className="py-1 font-normal text-left">
                                                            {selectorFilters.map((filter, index) => (
                                                                <Menu.Item key={filter.name}>
                                                                    {({active}) => (
                                                                        <span
                                                                            onClick={() => onSelectorFilterClick(filter)}
                                                                            className={classNames(
                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                'block px-4 py-2 text-sm cursor-pointer'
                                                                            )}
                                                                        >
                                                                            {filter.name}
                                                                        </span>
                                                                    )}
                                                                </Menu.Item>
                                                            ))}
                                                        </div>
                                                    </Menu.Items>
                                                  </Transition>
                                                </Menu>
                                            </span>
                                        )}
                                        <input
                                            type="checkbox"
                                            className="absolute left-4 sm:left-6 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                                            ref={checkbox}
                                            checked={checked}
                                            onChange={toggleAll}
                                        />
                                    </span>
                                </th>
                            )}
                            {columns.map(({label, className, sort}, index, arr) => (
                                <th key={index} scope="col"
                                    className={classNames(
                                        tableHeader,
                                        !rowActions && columns.length === index - 1 ? "sm:rounded-tr-xl" : "",
                                        index === 0 ? selectable ? "z-10 py-3.5 pr-3" : "z-10 px-3 py-3.5 pl-6" : "z-10 px-3 py-3.5",
                                        "text-left text-sm font-semibold text-gray-900",
                                        selectable && index === 0 && selectedItems.length > 0 ? "" : "truncate",
                                        !rowActions?.length && arr.length - 1 === index ? "pr-4 sm:pr-6 sm:rounded-tr-xl" : "",
                                        className
                                    )}>
                                    {selectable && index === 0 && tableActions && selectedItems.length > 0 && (
                                        <div
                                            className="absolute top-0 flex h-12 items-center">
                                            {tableActions.length > 0 && (action => (
                                                <button
                                                    type="button"
                                                    disabled={action.check && action.check(selectedItems) === false}
                                                    onClick={action.check && action.check(selectedItems) ? () => action.onClick(selectedItems) : undefined}
                                                    className="hidden mr-3 2xl:inline-flex items-center rounded border border-primary-300 bg-primary-100 px-2.5 py-1.5 text-xs font-medium text-primary-700 shadow-sm hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-default"
                                                >
                                                    <action.icon className="-ml-0.5 mr-2 h-4 w-4"
                                                                 aria-hidden="true"/>
                                                    {action.label}
                                                </button>
                                            ))(tableActions[0])}
                                            <Menu as="div" className={classNames(
                                                "inline-block relative text-left",
                                                tableActions.length > 1 ? "" : "3xl:hidden"
                                            )}>
                                                <div>
                                                    <Menu.Button
                                                        className="2xl:hidden inline-flex w-full justify-center gap-x-1.5 rounded-md border border-primary-300 bg-primary-100 px-3 py-2 text-sm font-semibold text-primary-700 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                                                        <span className="sr-only">{t("openOptions")}</span>
                                                        <Bars4Icon className="h-5 w-5" aria-hidden="true"/>
                                                        <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400"
                                                                         aria-hidden="true"/>
                                                    </Menu.Button>
                                                    {tableActions.length > 1 && (
                                                        <Menu.Button
                                                            className="2xl:flex hidden flex-shrink-0 items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                                            <span className="sr-only">Open options</span>
                                                            <EllipsisVerticalIcon className="h-5 w-5"
                                                                                  aria-hidden="true"/>
                                                        </Menu.Button>
                                                    )}
                                                </div>

                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items
                                                        className="absolute left-0 2xl:left-auto 2xl:right-0 z-10 mt-2 w-56 origin-top-left 2xl:origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <div className="py-1">
                                                            {tableActions.map((action, i) => (
                                                                <Menu.Item
                                                                    key={action.label}
                                                                    className={classNames(
                                                                        i === 0 ? "2xl:hidden" : ""
                                                                    )}>
                                                                    {({active}) => (
                                                                        <button
                                                                            disabled={action.check && action.check(selectedItems) === false}
                                                                            onClick={action.check && action.check(selectedItems) ? () => action.onClick(selectedItems) : undefined}
                                                                            className="w-full flex items-center px-4 py-2 text-sm cursor-pointer bg-white hover:bg-gray-50 text-gray-900 disabled:cursor-not-allowed disabled:text-gray-400 disabled:hover:text-gray-400 disabled:hover:bg-white"
                                                                        >
                                                                            <action.icon
                                                                                className="-ml-0.5 mr-2 h-4 w-4"
                                                                                aria-hidden="true"/>
                                                                            {action.label}
                                                                        </button>
                                                                    )}
                                                                </Menu.Item>
                                                            ))}
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                    )}
                                    {sort && onSearch ? (
                                        <span onClick={() => onSort(sort)}
                                              className={classNames(
                                                  "group inline-flex text-gray-900 cursor-pointer",
                                                  selectable && index === 0 && tableActions && selectedItems.length > 0 ? "invisible" : ""
                                              )}>
                                            {label}
                                            {sorted?.[0] === sort ? (
                                                <span
                                                    className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                                                    {sorted[1] === 'asc' ? (
                                                        <ChevronUpIcon className="h-5 w-5" aria-hidden="true"/>
                                                    ) : (
                                                        <ChevronDownIcon className="h-5 w-5" aria-hidden="true"/>
                                                    )}
                                                </span>
                                            ) : (
                                                <span
                                                    className="text-gray-200 ml-2 flex-none rounded group-hover:text-gray-400 group-focus:text-gray-400">
                                                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true"/>
                                                </span>
                                            )}
                                        </span>
                                    ) : (
                                        <span className={classNames(
                                            "cursor-default",
                                            selectable && index === 1 && tableActions && selectedItems.length > 0 ? "invisible" : ""
                                        )}>{label}</span>
                                    )}
                                </th>
                            ))}
                            {rowActions && rowActions.length > 0 && (
                                <th scope="col" className={classNames(
                                    tableHeader,
                                    "z-10 py-3.5 pl-3 pr-4 sm:pr-6 sm:rounded-tr-xl",
                                )}>
                                    <span className="sr-only">{t('actions')}</span>
                                </th>
                            )}
                        </tr>
                        </thead>
                        <tbody className={classNames(
                            frozen ? "bg-gray-100 opacity-50" : "bg-white",
                            "divide-y divide-gray-100"
                        )}>
                        {data && data.map((item, index) => (
                            <tr key={item.id || index}
                                className={selectedItems.includes(item) ? 'bg-gray-50' : undefined}>
                                {selectable && (
                                    <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                                        {selectedItems.includes(item) && (
                                            <div className="absolute inset-y-0 left-0 w-0.5 bg-primary-600"/>
                                        )}
                                        <input
                                            type="checkbox"
                                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500 sm:left-6"
                                            value={item.id}
                                            checked={selectedItems.includes(item)}
                                            onChange={e =>
                                                setSelectedItems(
                                                    e.target.checked
                                                        ? [...selectedItems, item]
                                                        : selectedItems.filter(it => it !== item)
                                                )
                                            }
                                        />
                                    </td>
                                )}

                                {columns.map((col, i, arr) => (
                                    <td key={i}
                                        className={classNames(
                                            i === 0 ? selectable ? (selectedItems.includes(item) ? 'py-4 pr-3 text-primary-600' : 'py-4 pr-3 font-medium text-gray-900') : "px-3 py-4 pl-6 font-medium text-gray-900" : "px-3 py-4 text-gray-500",
                                            !rowActions?.length && arr.length - 1 === i ? "pr-4 sm:pr-6" : "",
                                            col.className
                                        )}>
                                        {col.href ? (
                                            <a
                                                className={classNames(
                                                    col.className || "",
                                                    "p-0 text-sm hover:text-gray-900 underline",
                                                )}
                                                rel="noreferrer"
                                                target="_blank"
                                                href={col.href(item)}>
                                                {col.v(item, index, data) || emptyValue}
                                            </a>
                                        ) : (
                                            <span
                                                className={classNames(
                                                    col.className || "",
                                                    "p-0 text-sm",
                                                )}>
                                                                {col.v(item, index, data) || emptyValue}
                                                            </span>
                                        )}
                                    </td>
                                ))}

                                {rowActions && rowActions.length > 0 && (
                                    <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 space-x-1">
                                        {rowActions.map((action, i) => (
                                            <button
                                                key={i}
                                                type="button"
                                                disabled={action.check && action.check(item) === false}
                                                onClick={action.check && action.check(item) ? () => action.onClick(item) : undefined}
                                                title={action.label}
                                                className={classNames(
                                                    "inline-flex items-center gap-x-1.5",
                                                    action.prominent ? "rounded py-1 px-2 shadow-sm text-xs font-semibold" : "rounded-full border border-transparent p-1 shadow-sm focus:outline-none",
                                                    action.check && action.check(item) ? "bg-primary-50 text-primary-600 hover:bg-primary-100 focus:ring-2 focus:ring-primary-400 focus:ring-offset-2 cursor-pointer" : "bg-gray-50 text-gray-200 cursor-default",
                                                )}
                                            >
                                                {action.icon && (
                                                    <action.icon className={classNames(
                                                        "",
                                                        action.prominent ? "-ml-0.5 h-4 w-4" : "h-5 w-5"
                                                    )}/>
                                                )}
                                                {action.prominent ? action.label : (
                                                    <span className="sr-only">{action.label}</span>
                                                )}
                                            </button>
                                        ))}
                                    </td>
                                )}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {showNavigation && (
                        <nav
                            className="h-12 py-3 flex items-center justify-between border-t border-gray-100 sm:px-6 sm:rounded-b-lg"
                            aria-label={t('pagination')}
                        >
                            <div className="flex space-x-4 items-center">
                                <p className="hidden sm:block text-xs text-gray-700">
                                    {data && data.length > 0 ? (
                                        <>
                                            <Trans
                                                i18nKey={getWord(data.length, 'showingResultsOne', 'showingResultsTwo', 'showingResultsThreeOrFour', 'showingResults')}
                                                values={{
                                                    length: data.length
                                                }}
                                                components={{
                                                    s: <span
                                                        className="font-medium"/>
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Trans
                                                i18nKey="showingNoResults"
                                                components={{
                                                    s: <span className="font-medium"/>
                                                }}
                                            />
                                        </>
                                    )}
                                </p>
                                {loading && (
                                    <div className="flex justify-center items-center mr-1.5">
                                        <TailSpinner className="opacity-40" tiny/>
                                    </div>
                                )}
                            </div>
                            <div className="flex justify-center items-center -mr-1.5 gap-x-2">
                                {hasMore && (
                                    <>
                                        {(hasNext || hasPrevious) && (
                                            <button
                                                type="button"
                                                disabled={!hasPrevious}
                                                onClick={() => hasPrevious && onLoadMore(false)}
                                                className={classNames(
                                                    "inline-flex items-center rounded-md border border-transparent bg-white px-2.5 py-1.5 text-xs font-medium text-gray-500",
                                                    hasPrevious ? "hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2" : "hover:bg-white focus:outline-none cursor-default"
                                                )}>
                                                {t('loadPrevious')}
                                            </button>
                                        )}
                                        <button
                                            type="button"
                                            onClick={() => onLoadMore(true)}
                                            className="inline-flex items-center rounded-md border border-transparent bg-white px-2.5 py-1.5 text-xs font-medium text-gray-500 hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                                            {(hasNext || hasPrevious) ? t('loadNext') : t('loadMore')}
                                            {/*{loading && <TailSpinner className="-mr-1 ml-2" tiny aria-hidden="true"/>}*/}
                                        </button>
                                    </>
                                )}
                            </div>
                        </nav>
                    )}
                </div>
            </div>
        </>
    )
}
