import React, {Fragment, useState} from "react";
import {CheckCircleIcon, PauseCircleIcon, XCircleIcon} from "@heroicons/react/24/solid";
import {classNames} from "../utils";
import {Menu, Transition} from "@headlessui/react";
import {AdjustmentsHorizontalIcon} from "@heroicons/react/20/solid";
import {useTranslation} from "react-i18next";

export const XCircleGray = <XCircleIcon className="w-5 h-5 text-gray-400"/>
export const CheckCircleGreen = <CheckCircleIcon className="w-5 h-5 text-green-400"/>
export const PauseCircleOrange = <PauseCircleIcon className="w-5 h-5 text-orange-400"/>

function getIcon(status) {
    status = status.toUpperCase()
    switch (status) {
        case "INACTIVE":
            return XCircleGray
        case "ACTIVE":
            return CheckCircleGreen
        case "PENDING":
            return PauseCircleOrange
    }
}

export function statusView(status = "", t) {
    return <span className="flex items-center space-x-2 px-1 py-1 rounded-full">{getIcon(status)}
        <span>{t(status.toLowerCase())}</span></span>
}

function syncStatusColor(status) {
    status = status.toUpperCase()
    switch (status) {
        case "ACTIVE":
            return ["bg-green-100 text-green-800", "text-green-400"]
        /*case "INACTIVE":
            return ["bg-gray-100 text-gray-800", "text-gray-400"]*/
    }
}

export function syncStatusView(status = "", t) {
    const color = syncStatusColor(status)
    if (!color) return
    return (
        <span
            className={classNames(
                "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium",
                color[0]
            )}><svg
            className={classNames(
                "-ml-0.5 mr-1.5 h-2 w-2",
                color[1]
            )} fill="currentColor" viewBox="0 0 8 8"><circle cx={4}
                                                             cy={4}
                                                             r={3}/></svg>
            {t(status.toLowerCase())}
      </span>
    )
}

export function useTableFilters(filters) {
    const [checkedFilters, setCheckedFilters] = useState({})

    function onFilterValueChange(filter, option) {
        return () => {
            const checked = {
                ...checkedFilters,
                [filter.id]: {
                    id: filter.id,
                    value: option.value === "default" ? null : (option.value || null),
                    label: filter.label,
                    valueLabel: option.label
                }
            }
            setCheckedFilters(checked)
        }
    }

    return {filters, checkedFilters, setCheckedFilters, onFilterValueChange}
}

export function TableFilterView({tableFilters}) {
    const {t} = useTranslation()

    if (!tableFilters) return null

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    className="inline-flex items-center rounded-md border border-transparent bg-primary-50 px-4 py-2 text-sm font-medium text-primary-500 hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                    <AdjustmentsHorizontalIcon className="-ml-1 mr-2 h-5 w-5"
                                               aria-hidden="true"/>
                    {t('allFilters')}
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {tableFilters.filters.map(filter => (
                        <div key={filter.id}>
                            <div className="px-4 py-3">
                                <p className="text-sm font-medium">{filter.label}</p>
                            </div>
                            <div className="py-1">
                                {filter.type === "radio" && (
                                    <fieldset className="mt-0">
                                        <legend className="sr-only">{filter.label}</legend>
                                        <div className="space-y-0 -mt-2">
                                            {filter.options.map((option, idx) => (
                                                <Menu.Item key={idx}>
                                                    {({active}) => (
                                                        <label className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            "flex items-center px-4 py-2 text-sm w-full cursor-pointer"
                                                        )}>
                                                            <input
                                                                checked={(tableFilters.checkedFilters[filter.id]?.value || null) === (option.value || null)}
                                                                value={option.value || "default"}
                                                                name={filter.label}
                                                                onChange={tableFilters.onFilterValueChange(filter, option)}
                                                                type="radio"
                                                                className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
                                                            />
                                                            <span
                                                                className="ml-3 font-medium">{option.label}</span>
                                                        </label>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </div>
                                    </fieldset>
                                )}
                            </div>
                        </div>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    )
}