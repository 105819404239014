import {createSlice} from '@reduxjs/toolkit';
import {financeApi} from "../config";
import {serializeQuery} from "../utils";

const initialState = {
    monthly: {
        serializedQuery: undefined,
        data: null
    },
};

export const slice = createSlice({
    name: 'cashflow',
    initialState,
    reducers: {
        setMonthly: (state, action) => {
            state.monthly = action.payload;
        },
    }
});

// ACTIONS

const {setMonthly} = slice.actions;

export const fetchMonthlyCashflow = (params) => async (dispatch, getState) => {
    const state = getState(), activeOrganizationId = state.client.realActiveCid,
        serializedQuery = serializeQuery(params)
    await financeApi.get("/cashflow/" + activeOrganizationId + "?" + serializedQuery).then(response => {
        //console.log("fetchInvoiceList", response.data)
        if (response.data) {
            dispatch(setMonthly({
                serializedQuery: params?.filter ? serializedQuery : undefined,
                data: response.data
            }));
        } else {
            dispatch(setMonthly({
                serializedQuery: params?.filter ? serializedQuery : undefined,
                data: null
            }));
        }
    }).catch(error => {
        console.log("error", error)
    })
}

// SELECTORS

export const cashflowSelector = state => state.cashflow.monthly?.data || null;

export default slice.reducer;
