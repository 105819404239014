import {Fragment, useEffect, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {useSelector} from "react-redux";
import {realActiveCidSelector} from "../../store/client";
import {classNames, useErrorHandler} from "../../utils";
import TailSpinner from "../../components/tailSpinner";
import {CheckIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {api} from "../../config";
import {useTranslation} from "react-i18next";

export default function EditUserDialog(props) {
    const {uid, open, setOpen, email} = props
    const {t} = useTranslation()
    const cancelButtonRef = useRef(null)

    const handleError = useErrorHandler()
    const activeOrganizationId = useSelector(realActiveCidSelector)

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [role, setRole] = useState(props?.role || "admin")

    const permissions = {
        owner: [
            [
                t("viewOrganization"),
                t("manageOrganization"),
                t("manageOrganizationUsers")
            ],
            [],
        ],
        admin: [
            [
                t("viewOrganization"),
                t("manageOrganization"),
                t("manageOrganizationUsers")
            ],
            [],
        ],
        editor: [
            [
                t("viewOrganization"),
                t("manageOrganization")
            ],
            [
                t("manageOrganizationUsers")
            ],
        ],
        viewer: [
            [
                t("viewOrganization")
            ],
            [
                t("manageOrganization"),
                t("manageOrganizationUsers")
            ],
        ]
    }

    useEffect(() => {
        if (open) {
            setIsSubmitting(false)
            setRole(props?.role || "admin")
        }
    }, [open])

    function onSubmit(e) {
        e.preventDefault()

        setIsSubmitting(true)

        const obj = {
            uid: uid,
            role: role,
        }

        api.put(`/organizations/${activeOrganizationId}/users`, obj)
            .then(res => {
                console.log("data", res.data)
                if (res.data?.success) {
                    setOpen(false)
                } else {
                    handleError(res.data)
                }
            })
            .catch(handleError)
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                as="form"
                                onSubmit={onSubmit}
                                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3"
                                                          className="text-lg font-medium leading-6 text-gray-900">
                                                {t('manageUserAccess')}
                                            </Dialog.Title>

                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    {t('customizePermissionsAndRolesForEachUser')}
                                                </p>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
                                        <div className="sm:col-span-4">
                                            <label htmlFor="email"
                                                   className="block text-sm font-medium text-gray-700">
                                                {t('email')}
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    type="text"
                                                    id="email"
                                                    disabled
                                                    defaultValue={email}
                                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm bg-gray-100"
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-4">
                                            <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                                                {t('role')}
                                            </label>
                                            <select
                                                id="role"
                                                name="role"
                                                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                                                value={role}
                                                onChange={(e) => setRole(e.target.value)}
                                            >
                                                <option value="owner" disabled>{t('owner')}</option>
                                                <option value="admin">{t('admin')}</option>
                                                <option value="editor">{t('editor')}</option>
                                                <option value="viewer">{t('viewer')}</option>
                                            </select>
                                            <div className="mt-4 flex flex-col text-xs font-medium">
                                                {permissions[role].map((list, listType) => list.map(permission => (
                                                    <div key={listType + permission} className={classNames(
                                                        "group inline-flex items-center mb-1",
                                                        listType === 0 ? "text-green-500" : "text-red-500"
                                                    )}>
                                                        {listType === 0 ? (
                                                            <CheckIcon
                                                                className="h-5 w-5 opacity-50"
                                                                aria-hidden="true"
                                                            />
                                                        ) : (
                                                            <XMarkIcon
                                                                className="h-5 w-5 opacity-50"
                                                                aria-hidden="true"
                                                            />
                                                        )}
                                                        <span className="ml-2">{permission}</span>
                                                    </div>
                                                )))}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        disabled={isSubmitting}
                                        type="submit"
                                        className={classNames("w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm", isSubmitting ? "bg-gray-300 cursor-default" : "bg-primary-600 text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500")}
                                    >
                                        {isSubmitting && <TailSpinner/>}
                                        {t('save')}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        {t('cancel')}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
