import React, {Fragment, useEffect, useRef, useState} from 'react';
import {classNames, parseNumberString, sloDateParser} from "../../helpers/helpers";
import {
    ArrowUpTrayIcon as UploadIcon,
    CheckIcon,
    ChevronUpDownIcon as SelectorIcon,
    ExclamationTriangleIcon,
    PlusSmallIcon as PlusSmIconSolid,
    XMarkIcon as XIcon
} from '@heroicons/react/20/solid'
import {Combobox, Transition} from '@headlessui/react'
import {useDispatch, useSelector} from "react-redux";
import {Navigate, useNavigate} from "react-router-dom";
import AddPartnerDialog from "../../components/addPartnerDialog";
import {clientSelector, realActiveCidSelector} from "../../store/client";
import {fetchPartners, partnerListSelector} from "../../store/partners";
import {financeApi} from "../../config";
import Toolbar from "../../components/toolbar";
import {dateFormatter} from "../../utils";
import CreateNewFinancingRequestOverviewDialog from "../../components/createNewFinancingRequestOverviewDialog";

/*const Config = {
    SHARED_API_URL: "",
    ADMIN_API_URL: "",
}*/


const addNewPartnerItem = {name: "Add new partner", isCustom: true}

function InvoiceRow({type, row, updateRow, deleteRow, canEdit}) {
    const [number, setNumber] = useState(row.number || "")
    const [date, setDate] = useState(row.date || "")
    const [dueDate, setDueDate] = useState(row.dueDate || "")
    const [extendedPaymentDate, setExtendedPaymentDate] = useState(row.extendedPaymentDate || "")
    const [amount, setAmount] = useState(row.amount || "")
    const [fileId, setFileId] = useState(row.fileId || "")

    const uploadRef = useRef()

    const [isUploading, setIsUploading] = useState(false)

    useEffect(() => {
        updateRow({_id: row._id, number, date, dueDate, extendedPaymentDate, amount, fileId})
    }, [number, date, dueDate, extendedPaymentDate, amount, fileId])


    function onUploadFile(e) {
        const file = e.target.files[0]
        //console.log("file", file)

        if (file) {
            setIsUploading(true)
            try {
                const data = new FormData()
                data.append('file', file)

                financeApi.post(`/files/upload`, data)
                    .then(res => {
                        console.log("data", res.data)
                        setFileId(res.data.id)
                        //setOpen(false)
                    })
                    .catch(error => {
                        // TODO: handle error
                        console.log("error", error)
                    })

                /*fetch(`${Config.SHARED_API_URL}/promissory-notes/${id}/debtor/signature`, {
                    method: 'POST',
                    headers, body: data
                })
                    /!*.then((response) => response.json())
                    *!/.then(() => {
                    setUploading(false)
                    fetchData(id)
                }).catch(e => {
                    setUploading(false)
                    if (e.status === 401) {
                        logout();
                    }
                })*/
            } catch (e) {
                setIsUploading(false)
                console.error(e)
            }
            if (uploadRef?.current) uploadRef.current.value = ""
        }
    }

    return (
        <div
            className={classNames(
                "grid",
                type === "reverse-factoring" ? "grid-cols-[minmax(0px,_1fr)_minmax(0px,_1fr)_minmax(0px,_1fr)_minmax(0px,_1fr)_minmax(0px,_1fr)_200px]" : "grid-cols-[minmax(0px,_1fr)_minmax(0px,_1fr)_minmax(0px,_1fr)_minmax(0px,_1fr)_200px]"
            )}>
            <div className="flex-1 p-2 w-full">
                <input
                    className={classNames(
                        "text-gray-900 flex-1 block w-full box-border border-0 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm",
                        !canEdit ? "bg-gray-100 text-gray-500" : "bg-gray-50"
                    )}
                    type="text"
                    disabled={!canEdit}
                    onChange={e => setNumber(e.target.value)} value={number}
                />
            </div>
            <div className="flex-1 p-2 w-full">
                <input
                    className={classNames(
                        "text-gray-900 flex-1 block w-full box-border border-0 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm",
                        !canEdit ? "bg-gray-100 text-gray-500" : "bg-gray-50"
                    )}
                    type="date"
                    disabled={!canEdit}
                    onChange={e => setDate(e.target.value)} value={date}
                />
            </div>
            <div className="flex-1 p-2 w-full">
                <input
                    className={classNames(
                        "text-gray-900 flex-1 block w-full box-border border-0 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm",
                        !canEdit ? "bg-gray-100 text-gray-500" : "bg-gray-50"
                    )}
                    type="date"
                    disabled={!canEdit}
                    onChange={e => setDueDate(e.target.value)} value={dueDate}
                />
            </div>
            {type === "reverse-factoring" && (
                <div className="flex-1 p-2 w-full">
                    <input
                        className={classNames(
                            "text-gray-900 flex-1 block w-full box-border border-0 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm bg-gray-50",
                        )}
                        type="date"
                        onChange={e => setExtendedPaymentDate(e.target.value)} value={extendedPaymentDate}
                    />
                </div>
            )}
            <div className="flex-1 p-2 w-full">
                <input
                    className={classNames(
                        "text-gray-900 flex-1 block w-full box-border border-0 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm",
                        !canEdit ? "bg-gray-100 text-gray-500" : "bg-gray-50"
                    )}
                    type="text"
                    disabled={!canEdit}
                    onChange={e => setAmount(e.target.value)} value={amount}
                />
            </div>
            <div className="flex items-center justify-end px-4">
                {canEdit && (
                    <>
                        <label
                            className={classNames(
                                "ml-3 text-sm",
                                canEdit ? "text-primary-600 hover:text-primary-900 cursor-pointer" : "text-gray-300 cursor-default"
                            )}
                        >
                            <input ref={uploadRef} type="file" className="sr-only" onChange={onUploadFile}/>
                            Attach invoice<span className="sr-only"></span>
                        </label>
                        <button
                            type="button"
                            disabled={!canEdit}
                            onClick={() => deleteRow(row._id)}
                            className={classNames(
                                "ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500",
                                canEdit ? "text-red-500 bg-red-200 hover:bg-gray-300" : "text-gray-300 bg-gray-50 cursor-default"
                            )}
                        >
                            <XIcon className="h-3 w-3" aria-hidden="true"/>
                        </button>
                    </>
                )}
                {/*{!canEdit && (
                    <a
                        href={"#/invoices/" + row._id}
                        className={classNames(
                            "ml-3 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500",
                            "text-gray-500 bg-gray-200 hover:bg-gray-300"
                        )}
                    >
                        <LinkIcon className="h-3 w-3" aria-hidden="true"/>
                    </a>
                )}*/}
            </div>
        </div>
    )
}

export default function NewFinancingRequest({match}) {
    const financingType = match.params.type

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const actionsView = [
        {name: "Cancel", onClick: () => navigate(-1)},
        {
            name: "Create", onClick: onCreateClicked, primary: true
        },
    ]

    const activeOrganizationId = useSelector(realActiveCidSelector)
    const organization = useSelector(clientSelector(activeOrganizationId))
    const orderedPartners = useSelector(partnerListSelector)

    const [isAddPartnerDialogOpen, setIsAddPartnerDialogOpen] = useState(false)
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)

    const [selectedOtherParty, setSelectedOtherParty] = useState(null)
    const [status, setStatus] = useState("")
    const [invoiceRows, setInvoiceRows] = useState([])

    const [canEdit, setCanEdit] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const importRef = useRef()

    const [addPartnerText, setAddPartnerText] = useState("")
    const [query, setQuery] = useState('')
    const filteredCompanies =
        query === ''
            ? orderedPartners
            : orderedPartners.filter((item) =>
                (item.name + " " + item.taxNo)
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )


    useEffect(() => {
        if (activeOrganizationId) dispatch(fetchPartners())
    }, [activeOrganizationId])

    function onSelectionChange(e) {
        /*if (e.target.value === addNewPartnerItem.name) {
            setIsAddPartnerDialogOpen(true)
        }*/

        setAddPartnerText(e.target.value)
        setQuery(e.target.value)
    }

    function onSelectionLeave(e) {
        setQuery("")
        if (selectedOtherParty?.isCustom) {
            setIsAddPartnerDialogOpen(true)
        }
    }

    async function onImport() {
        if (importRef?.current?.files?.length > 0) {
            try {
                const XLSX = await import("xlsx")

                const data = await importRef.current.files[0].arrayBuffer();

                const workbook = XLSX.read(data, {type: 'binary', cellDates: true, dateNF: 'dd/mm/yyyy;@'}),
                    sheetKeys = Object.keys(workbook.Sheets),
                    ws = workbook.Sheets[sheetKeys[0]]

                if (sheetKeys.length > 1) alert("You're importing a document with multiple sheets. Only the first sheet will be imported!")

                //XLSX.utils.sheet_add_aoa(ws, [["One", "two"]], {origin: 0})

                const jsa = XLSX.utils.sheet_to_json(ws, {header: 1, raw: false});

                const importedRows = []
                for (let i = 0; i < jsa.length; i++) {
                    const [_number, _issueDate, _dueDate, _extendedPaymentDate, _total] = jsa[i]
                    if (!_number) break
                    importedRows.push({
                        _id: "" + i + Date.now(),
                        number: _number,
                        issueDate: _issueDate ? dateFormatter(sloDateParser(_issueDate).getTime()) : "",
                        dueDate: _dueDate ? dateFormatter(sloDateParser(_dueDate).getTime()) : "",
                        extendedPaymentDate: _extendedPaymentDate ? dateFormatter(sloDateParser(_extendedPaymentDate).getTime()) : "",
                        total: (parseNumberString(_total) || "")
                    })
                }

                setInvoiceRows([
                    ...invoiceRows,
                    ...importedRows
                ])
            } catch (e) {
                console.error(e)
            }
            importRef.current.value = ""
        }
        /*if (!simpleExcel) await import("../libs/simple-excel/simple-excel.js").then(({SimpleExcel}) => {
            setSimpleExcel(SimpleExcel)
        })*/
    }

    function addRow() {
        setInvoiceRows([...invoiceRows, {
            _id: Date.now(),
            number: "",
            date: "",
            dueDate: "",
            extendedPaymentDate: "",
            amount: "",
            fileId: "",
        }])
    }

    function deleteRow(_id) {
        setInvoiceRows(invoiceRows.filter(row => row._id !== _id))
    }

    function updateRow(data) {
        setInvoiceRows(invoiceRows.map(row => row._id === data._id ? data : row))
    }

    function onCreateClicked(e) {
        e && e.preventDefault();

        //console.log("selectedOtherParty", selectedOtherParty)
        if (!selectedOtherParty?.id) return alert("Please select a partner")


        setIsCreateDialogOpen(true)
    }

    function onSubmit(transactionDate) {
        //e && e.preventDefault();

        const object = {
            type: financingType === "factoring" ? "F" : "RF",
            organizationId: activeOrganizationId,
            otherPartyPartnerId: selectedOtherParty.id,
            transactionDate: new Date(transactionDate),
            invoices: invoiceRows.map(r => ({
                number: r.number,
                date: r.date,
                dueDate: r.dueDate,
                extendedPaymentDate: r.extendedPaymentDate || null, // extended payment date?
                amount: parseFloat(r.amount),
                fileId: r.fileId,
            })),
        }

        //console.log("object", object)

        //return

        setIsSubmitting(true)
        financeApi.post(`/promissory-notes`, object)
            .then(res => {
                console.log("data", res.data)

                setIsSubmitting(false)
                navigate(`/u/financing`)
                //setOpen(false)
            })
            .catch(error => {
                // TODO: handle error
                setIsSubmitting(false)
                console.log("error", error)
            })
    }

    function renderTable(rows, canEdit) {
        return (
            <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">

                            <div className="min-w-full divide-y divide-gray-200">
                                <div className="bg-gray-50">
                                    <div
                                        className={classNames(
                                            "grid",
                                            financingType === "reverse-factoring" ?
                                                "grid-cols-[minmax(0px,_1fr)_minmax(0px,_1fr)_minmax(0px,_1fr)_minmax(0px,_1fr)_minmax(0px,_1fr)_200px]" :
                                                "grid-cols-[minmax(0px,_1fr)_minmax(0px,_1fr)_minmax(0px,_1fr)_minmax(0px,_1fr)_200px]"
                                        )}>
                                        <div
                                            className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Invoice number
                                        </div>
                                        <div
                                            className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Invoice date
                                        </div>
                                        <div
                                            className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Net due date
                                        </div>
                                        {financingType === "reverse-factoring" && (
                                            <div
                                                className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Extended payment date
                                            </div>
                                        )}
                                        <div
                                            className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Amount
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white divide-y divide-gray-200">
                                    <div className="divide-y divide-gray-200">
                                        {rows.map((row) => (
                                            <div key={row._id}>
                                                <InvoiceRow type={financingType} row={row} updateRow={updateRow}
                                                            deleteRow={deleteRow}
                                                            canEdit={canEdit} status={status}/>
                                            </div>
                                        ))}
                                    </div>
                                    {rows.length === 0 && (
                                        <div
                                            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-400 text-center">
                                            <span>No entries</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {(financingType !== "factoring" && financingType !== "reverse-factoring") ? (
                <Navigate to="/u/financing"/>
            ) : (
                <>
                    {/* Page title & actions */}
                    <Toolbar actionsView={actionsView}/>
                    <div className="mt-8 px-4 sm:px-6 lg:px-8">
                        {!organization?.oid && (
                            <div className="rounded-md bg-yellow-50 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400"
                                                                 aria-hidden="true"/>
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-yellow-800">Attention needed</h3>
                                        <div className="mt-2 text-sm text-yellow-700">
                                            <p>
                                                This action is disabled for your account as we are still verifying your
                                                account. We will notify you once your account has been approved.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="mt-8 sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h1 className="text-xl font-semibold text-gray-900">New Financing Request</h1>
                                <p className="mt-2 text-sm text-gray-700">
                                    Type {financingType === "factoring" ? "Factoring" : "Reverse Factoring"}
                                </p>
                            </div>
                        </div>
                        <div className="mt-8 pb-16 space-y-6">
                            <form onSubmit={onCreateClicked}>
                                <div className="sm:mt-5 space-y-6 sm:space-y-5">
                                    {/*<div
                                    className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                                    <label htmlFor="type"
                                           className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                                        Type
                                    </label>
                                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                                        <select
                                            id="type"
                                            name="type"
                                            disabled={true}
                                            value={type}
                                            className="text-gray-900 max-w-lg block focus:ring-gray-500 focus:border-gray-500 w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md border-gray-300 bg-gray-50 text-gray-600"
                                        >
                                            <option value="F">Factoring</option>
                                            <option value="RF">Reverse factoring</option>
                                        </select>
                                    </div>
                                </div>*/}


                                    <div className="flex items-center">
                                        <h4 className="text-md leading-6 font-medium text-gray-900">{financingType === "factoring" ? "Debtor (buyer)" : "Assignee (supplier)"}</h4>
                                    </div>

                                    <div>
                                        <Combobox value={selectedOtherParty} onChange={setSelectedOtherParty}>
                                            <div className="relative mt-1">
                                                <div
                                                    className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm text-left overflow-hidden cursor-default focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 sm:text-sm">
                                                    <Combobox.Input
                                                        autoComplete="off"
                                                        className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                        displayValue={item => (item?.shortName || item?.name || "")}
                                                        onChange={onSelectionChange}
                                                    />
                                                    <Combobox.Button
                                                        className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                        <SelectorIcon
                                                            className="h-5 w-5 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                    </Combobox.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                    afterLeave={onSelectionLeave}
                                                >
                                                    <Combobox.Options
                                                        className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                        {filteredCompanies.length === 0 && !addPartnerText ? (
                                                            <div
                                                                className="relative cursor-default select-none py-2 px-4 italic text-sm text-gray-500">
                                                                Start typing to search...
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {/*{addPartnerText && (
                                                                <Combobox.Option
                                                                    className={({active}) =>
                                                                        classNames(
                                                                            active ? 'text-white bg-gray-600' : 'text-gray-900',
                                                                            'cursor-default select-none relative py-2 pl-3 pr-9 pl-8'
                                                                        )
                                                                    }
                                                                    value={addNewPartnerItem}
                                                                >
                                                                    {({selected, active}) => (
                                                                        <>
                                                                            <div className="flex">
                                                                            <span
                                                                                className={classNames(
                                                                                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                                                                    active ? 'text-white' : 'text-gray-600'
                                                                                )}
                                                                            >
                        <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                      </span>
                                                                                <span
                                                                                    className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                            Add "{addPartnerText}"
                          </span>
                                                                                <span
                                                                                    className={classNames(active ? 'text-gray-200' : 'text-gray-500', 'ml-2 truncate')}>
                            Click to add a new partner
                          </span>
                                                                            </div>

                                                                            {selected ? (
                                                                                <span
                                                                                    className={classNames(
                                                                                        active ? 'text-white' : 'text-gray-600',
                                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                    )}
                                                                                >
                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                          </span>
                                                                            ) : null}
                                                                        </>
                                                                    )}
                                                                </Combobox.Option>
                                                            )}*/}
                                                                {filteredCompanies.map(item => (
                                                                    <Combobox.Option
                                                                        key={item.id}
                                                                        className={({active}) =>
                                                                            classNames(
                                                                                active ? 'text-white bg-gray-600' : 'text-gray-900',
                                                                                'cursor-default select-none relative py-2 pl-3 pr-9 pl-8'
                                                                            )
                                                                        }
                                                                        value={item}
                                                                    >
                                                                        {({selected, active}) => (
                                                                            <>
                                                                                <div className="flex">
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                            {item.name || item.shortName}
                          </span>
                                                                                    <span
                                                                                        className={classNames(active ? 'text-gray-200' : 'text-gray-500', 'ml-2 truncate')}>
                            {item.vatNo || item.taxNo}
                          </span>
                                                                                </div>

                                                                                {selected ? (
                                                                                    <span
                                                                                        className={classNames(
                                                                                            active ? 'text-white' : 'text-gray-600',
                                                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                        )}
                                                                                    >
                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                          </span>
                                                                                ) : null}
                                                                            </>
                                                                        )}
                                                                    </Combobox.Option>
                                                                ))}
                                                            </>
                                                        )}
                                                    </Combobox.Options>
                                                </Transition>
                                            </div>
                                        </Combobox>
                                    </div>

                                    <div className="pt-12 flex items-center">
                                        <h4 className="text-md leading-6 font-medium text-gray-900">Invoices</h4>
                                        {canEdit && (
                                            <>
                                                <button
                                                    type="button"
                                                    onClick={addRow}
                                                    className="ml-4 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                >
                                                    <PlusSmIconSolid className="w-4 w-4" aria-hidden="true"/>
                                                </button>
                                                <label
                                                    className="ml-4 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                >
                                                    <input ref={importRef} type="file" className="hidden"
                                                           onChange={onImport}/>
                                                    <UploadIcon className="h-4 w-4" aria-hidden="true"/>
                                                </label>
                                            </>
                                        )}

                                    </div>

                                    {renderTable(invoiceRows, canEdit)}

                                </div>


                            </form>
                        </div>
                    </div>
                    <AddPartnerDialog open={isAddPartnerDialogOpen} setOpen={setIsAddPartnerDialogOpen}/>
                    <CreateNewFinancingRequestOverviewDialog onSubmit={onSubmit} open={isCreateDialogOpen}
                                                             isSubmitting={isSubmitting}
                                                             setOpen={setIsCreateDialogOpen}/>
                </>
            )}
        </>
    )
};
