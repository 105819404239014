import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import TableViewPalette from "./tableViewPalette";

export default function SearchBox({onSearch, onAutocomplete, searchOptions, placeholder}) {
    const {t} = useTranslation()
    const [showPalette, setShowPalette] = useState(false)
    const focusTarget = React.useRef(null)

    function onFocused(e) {
        e.target.blur()
        setShowPalette(true)
    }

    if (!searchOptions) return null

    return (
        <>
            <label htmlFor="search" className="block text-sm font-medium text-gray-700 sr-only">
                {t('quickSearch')}
            </label>
            <div className="relative flex items-center">
                <div
                    ref={focusTarget}
                    className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                </div>
                <input
                    autoComplete={"off"}
                    autoFocus={false}
                    readOnly
                    type="text"
                    name="search"
                    id="search"
                    placeholder={t('searchDots')}
                    onClick={onFocused}
                    className="block w-full rounded-md border-gray-300 pl-10 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                />
                {/*<div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                                <kbd
                                    className="inline-flex items-center rounded border border-gray-200 px-2 font-sans text-sm font-medium text-gray-400">
                                    ⌘K
                                </kbd>
                            </div>*/}
            </div>
            <TableViewPalette setOpen={setShowPalette} open={showPalette} onSearch={onSearch}
                              onAutocomplete={onAutocomplete} placeholder={placeholder}
                              suggestions={searchOptions.suggestions} quickActions={searchOptions.quickAction}/>
        </>
    )
}
