import React from "react";
import {useTranslation} from "react-i18next";
import {classNames} from "../../utils";
import {NavLink, Route, Routes, useNavigate} from "react-router-dom";
import GeneralTab from "./generalTab";
import AccountTab from "./accountTab";
import IntegrationsTab from "./integrationsTab";
import NotificationsTab from "./notificationsTab";

export default function AppSettings({clientAccessError}) {
    const {t} = useTranslation()
    const navigate = useNavigate();

    const tabs = clientAccessError ? [
        {name: t('accountSettings'), href: '/account', current: false, element: <AccountTab/>},
    ] : [
        {name: t('general'), href: '/general', current: true, element: <GeneralTab/>},
        {name: t('accountSettings'), href: '/account', current: false, element: <AccountTab/>},
        {name: t('integrations'), href: '/integrations', current: false, element: <IntegrationsTab/>},
        {name: t('notifications'), href: '/notifications', current: false, element: <NotificationsTab/>},
        /*{name: 'Plan', href: '#', current: false},
        {name: 'Billing', href: '#', current: false},
        {name: 'Team Members', href: '#', current: false},*/
    ]

    return (
        <>
            <div className="hidden lg:block">
                <div className="mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8">
                    <div className="px-4 sm:px-6 lg:flex-auto lg:px-0">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                            <nav className="-mb-px flex space-x-8">
                                {tabs.map((tab) => (
                                    <NavLink
                                        key={tab.href}
                                        to={"/u/settings" + tab.href}
                                        className={({isActive, isPending}) =>
                                            classNames(
                                                isActive
                                                    ? 'border-primary-500 text-primary-600'
                                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                'whitespace-nowrap py-6 px-0 border-b-2 font-medium text-sm'
                                            )
                                        }
                                    >
                                        {tab.name}
                                    </NavLink>
                                ))}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:border-t lg:border-t-gray-900/5">
                <div className="mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8">
                    <div className="px-4 pt-8 pb-16 sm:px-6 lg:flex-auto lg:px-0 lg:pt-10 lg:pb-20">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                            <div className="lg:hidden">
                                <label htmlFor="selected-tab" className="sr-only">
                                    {t('selectATab')}
                                </label>
                                <select
                                    id="selected-tab"
                                    name="selected-tab"
                                    onChange={e => navigate("/u/settings" + e.target.value)}
                                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                                    defaultValue={tabs[0].name}
                                >
                                    {tabs.map((tab) => (
                                        <option key={tab.href} value={tab.href}>{tab.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mt-4">
                                <Routes>
                                    {tabs.map((tab) => (
                                        <Route key={tab.href} path={tab.href} element={tab.element}/>))}
                                </Routes>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
