import React, {useEffect, useRef, useState} from "react";
import {financeApi} from "../config";
import {classNames} from "../helpers/helpers";
import {ExclamationCircleIcon, XMarkIcon as XIcon} from "@heroicons/react/20/solid";
import {useTranslation} from "react-i18next";
import AmountInput from "./amountInput";
import {catchErrors} from "../utils";
import TailSpinner from "./tailSpinner";
import {CheckCircleIcon} from "@heroicons/react/24/solid";

const
    FGrid = "md:grid-cols-[minmax(0px,_1fr)_minmax(0px,_1fr)_minmax(0px,_1fr)_minmax(0px,_1fr)_minmax(0px,_1fr)_50px]",
    RFGrid = "md:grid-cols-[minmax(0px,_1fr)_minmax(0px,_1fr)_minmax(0px,_1fr)_minmax(0px,_1fr)_minmax(0px,_1fr)_minmax(0px,_1fr)_50px]"

function InvoiceRow({type, row, updateRow, deleteRow, showExtendedPaymentDate}) {
    const {t} = useTranslation();
    const [number, setNumber] = useState(row.number || "")
    const [date, setDate] = useState(row.date || "")
    const [dueDate, setDueDate] = useState(row.dueDate || "")
    const [extendedPaymentDate, setExtendedPaymentDate] = useState(row.extendedPaymentDate || "")
    const [amount, setAmount] = useState(row.amount || "")
    const [fileId, setFileId] = useState(row.fileId || "")
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState(row.fileName || "")
    const [error, setError] = useState("")

    const uploadRef = useRef()

    const [isUploading, setIsUploading] = useState(false)

    useEffect(() => {
        updateRow({
            _id: row._id,
            number,
            date,
            dueDate,
            //extendedPaymentDate,
            amount,
            fileId,
            _fileName: fileName,
            _isUploading: isUploading
        })
    }, [number, date, dueDate, amount, fileId, fileName, isUploading])

    function upload(_file) {
        if (_file) {
            setFileId("")
            setFile(_file)
            setFileName(_file.name)
            setError("")

            const data = new FormData()
            data.append('file', _file)

            setIsUploading(true)
            financeApi.post(`/files/upload`, data)
                .then(res => {
                    console.log("data", res.data)
                    setFileId(res.data.id)
                })
                .catch(error => {
                    const msg = catchErrors(error)
                    setError(msg)
                })
                .finally(() => {
                    setIsUploading(false)
                })

            if (uploadRef?.current) uploadRef.current.value = ""
        }
    }

    function onChangeFile(e) {
        upload(e.target.files[0])
    }

    function retryUpload(e) {
        e.preventDefault()
        upload(file)
    }

    return (
        <>
            <div
                className={classNames(
                    "grid bg-white grid-cols-1 py-2 md:py-0",
                    showExtendedPaymentDate ? RFGrid : FGrid
                )}>
                <div className="flex-1 px-4 py-2 md:p-2 w-full">
                    <label htmlFor="number" className="block text-sm font-medium text-gray-700 md:sr-only">
                        {t('invoiceNumber')}
                    </label>
                    <div className="mt-1 md:mt-0">
                        <input
                            id="number"
                            name="number"
                            className="flex-1 block w-full box-border border-0 bg-gray-100 text-gray-500 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm"
                            type="text"
                            onChange={e => setNumber(e.target.value)} value={number}
                            required
                        />
                    </div>
                </div>
                <div className="flex-1 px-4 py-2 md:p-2 w-full">
                    <label htmlFor="date" className="block text-sm font-medium text-gray-700 md:sr-only">
                        {t('invoiceDate')}
                    </label>
                    <div className="mt-1 md:mt-0">
                        <input
                            id="date"
                            name="date"
                            className="flex-1 block w-full box-border border-0 bg-gray-100 text-gray-500 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm"
                            type="date"
                            onChange={e => setDate(e.target.value)} value={date}
                            required
                        />
                    </div>
                </div>
                <div className="flex-1 px-4 py-2 md:p-2 w-full">
                    <label htmlFor="dueDate" className="block text-sm font-medium text-gray-700 md:sr-only">
                        {t('dueDate')}
                    </label>
                    <div className="mt-1 md:mt-0">
                        <input
                            id="dueDate"
                            name="dueDate"
                            className="flex-1 block w-full box-border border-0 bg-gray-100 text-gray-500 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm"
                            type="date"
                            onChange={e => setDueDate(e.target.value)} value={dueDate}
                            required
                        />
                    </div>
                </div>
                {showExtendedPaymentDate && (
                    <div className="flex-1 px-4 py-2 md:p-2 w-full">
                        <label htmlFor="extendedPaymentDate"
                               className="block text-sm font-medium text-gray-700 md:sr-only">
                            {t('extendedPaymentDate')}
                        </label>
                        <div className="mt-1 md:mt-0">
                            <input
                                id="extendedPaymentDate"
                                name="extendedPaymentDate"
                                className="flex-1 block w-full box-border border-0 bg-gray-100 text-gray-500 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm"
                                type="date"
                                onChange={e => setExtendedPaymentDate(e.target.value)} value={extendedPaymentDate}
                                required
                            />
                        </div>
                    </div>
                )}
                <div className="flex-1 px-4 py-2 md:p-2 w-full">
                    <label htmlFor="amount" className="block text-sm font-medium text-gray-700 md:sr-only">
                        {t('amount')}
                    </label>
                    <div className="mt-1 md:mt-0">
                        <AmountInput
                            id="amount"
                            name="amount"
                            className="flex-1 block w-full box-border border-0 bg-gray-100 text-gray-500 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm"
                            onChange={e => setAmount(e.target.value)} value={amount}
                            required
                        />
                    </div>
                </div>
                <div className="flex-1 px-4 py-2 md:p-2 w-full md:flex md:items-center overflow-hidden">
                    <span htmlFor="fileInput"
                          className="block text-sm font-medium text-gray-700 md:sr-only">{t('invoicePdf')}</span>
                    <div className="mt-1 md:mt-0 w-full overflow-hidden">
                        <label
                            className="w-full px-3 py-2 flex-1 flex items-center space-between w-full box-border border-0 bg-gray-100 text-gray-500 focus:ring-gray-400 focus:border-0 min-w-0 rounded-md sm:text-sm cursor-pointer"
                            title={fileName}>
                            <input id="fileInput"
                                   ref={uploadRef} type="file" multiple={false} className="sr-only"
                                   accept="application/pdf"
                                   onChange={onChangeFile}/>
                            <span
                                className="sm:text-sm truncate text-gray-500">{fileName ? fileName : "Select file"}</span>
                            <div>
                                {isUploading && (
                                    <TailSpinner tiny className="ml-2"/>
                                )}
                                {error && (
                                    <ExclamationCircleIcon className="ml-2 text-red-500 h-5 w-5"/>
                                )}
                                {fileId && (
                                    <CheckCircleIcon className="ml-2 text-green-500 h-5 w-5"/>
                                )}
                            </div>
                        </label>
                    </div>
                </div>
                <div className="flex items-center justify-end px-4 py-2 md:py-0">
                    <button
                        type="button"
                        onClick={() => deleteRow(row._id)}
                        className="inline-flex md:hidden items-center rounded border border-transparent bg-primary-100 px-2.5 py-1.5 text-xs font-medium text-primary-700 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    >
                        {t('deleteRow')}
                    </button>
                    <button
                        type="button"
                        onClick={() => deleteRow(row._id)}
                        className="hidden md:inline-flex items-center p-1 border border-transparent text-gray-500 bg-gray-200 hover:bg-red-200 hover:text-red-500 rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400"
                    >
                        <XIcon className="h-3 w-3" aria-hidden="true"/>
                    </button>
                </div>
            </div>
            {error && (
                <div className="px-4 pb-2 md:px-4 md:pb-2 text-sm bg-white text-red-600">
                    <span>An error occurred uploading your file (details: {error}).</span>{" "}
                    <a href="#" onClick={retryUpload} className="text-primary-600 hover:text-primary-900">
                        Retry?
                    </a>
                </div>
            )}
        </>
    )
}

export default function ImportedInvoicesTables({
                                                   rows,
                                                   financingType,
                                                   status,
                                                   deleteRow,
                                                   updateRow,
                                                   showExtendedPaymentDate
                                               }) {
    const {t} = useTranslation();
    return (
        <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-hidden sm:rounded-lg border shadow-sm">
                        <div className="min-w-full divide-y divide-gray-200">
                            <div className="bg-gray-50">
                                <div
                                    className={classNames(
                                        "grid",
                                        showExtendedPaymentDate ? RFGrid : FGrid
                                    )}>
                                    <div
                                        className="md:hidden px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {t('invoice')}
                                    </div>
                                    <div
                                        className="hidden md:block px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {t('invoiceNumber')}
                                    </div>
                                    <div
                                        className="hidden md:block px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {t('invoiceDate')}
                                    </div>
                                    <div
                                        className="hidden md:block px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {t('dueDate')}
                                    </div>
                                    {showExtendedPaymentDate && (
                                        <div
                                            className="hidden md:block px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            {t('extendedPaymentDate')}
                                        </div>
                                    )}
                                    <div
                                        className="hidden md:block px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {t('amount')}
                                    </div>
                                    <div
                                        className="hidden md:block px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {t('invoicePdf')}
                                    </div>
                                </div>
                            </div>
                            <div className="divide-y divide-gray-200">
                                {rows.map((row) => (
                                    <div key={row._id}>
                                        <InvoiceRow type={financingType} row={row} updateRow={updateRow}
                                                    deleteRow={deleteRow} status={status}
                                                    showExtendedPaymentDate={showExtendedPaymentDate}/>
                                    </div>
                                ))}
                                {rows.length === 0 && (
                                    <div
                                        className="px-6 py-4 bg-white whitespace-nowrap text-sm font-medium text-gray-400 text-center">
                                        <span>{t('noEntries')}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
