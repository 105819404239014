import React, {Fragment, useEffect, useState} from "react";
import TableView from "../../components/tableView";
import {classNames, formatAddress, formatDate, formatMoney, getDateString} from "../../utils";
import {
    ArrowSmallDownIcon,
    ArrowSmallUpIcon,
    BuildingLibraryIcon,
    BuildingOffice2Icon,
    BuildingOfficeIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    CreditCardIcon,
    CurrencyEuroIcon,
    SparklesIcon
} from "@heroicons/react/20/solid";
import {Menu, Transition} from "@headlessui/react";
import {ArrowDownOnSquareIcon, BookmarkIcon} from "@heroicons/react/24/outline";
import {api} from "../../config";
import TailSpinner from "../../components/tailSpinner";
// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper";

// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";
import {useTranslation} from "react-i18next";

const DEFAULT_DATASET = '[{"label":"Sredstva","id":"L00001","aop":"T001","data":[]},{"label":"Kapital","id":"L00056","aop":"T022","data":[]},{"label":"Celotni prihodki","id":"K0004","aop":"V1_9","data":[]},{"label":"Čisti poslovni izzid","id":"L00201","aop":"T071","data":[]},{"label":"Povprečno število zaposlenih","id":"L00248","aop":"T097","isString":true,"data":[]},{"label":"Delež finančnih obveznosti v sredstvih v %","id":"K0023","aop":"V2_18","isString":true,"data":[]},{"label":"Kratkoročni koeficient likvidnosti","id":"K0018","aop":"D_4","isString":true,"data":[]}]'

const accountsColumns = [
    {
        v: (d, i) => {
            return d.dateClosed ? <span className="text-gray-400">{d.number}</span> : d.number
        }, label: "Številka računa"
    },
    {
        v: (d, i) => {
            return d.dateClosed ? <span className="text-gray-400">{d.bankName || "-"}</span> : d.bankName || "-"
        }, label: "Banka"
    },
    {
        v: (d, i) => {
            return d.dateClosed ? <span className="text-gray-400">{d.note || "-"}</span> : d.note || "-"
        }, label: "Opomba"
    },
    {
        v: (d, i) => {
            return d.dateClosed ? <span
                className="text-gray-400">{d.dateOpened ? getDateString(d.dateOpened) : "/"}</span> : d.dateOpened ? getDateString(d.dateOpened) : "/"
        }, label: "Odprtje"
    },
    {
        v: (d, i) => {
            return d.dateClosed ?
                <span className="text-gray-400">{d.dateClosed ? getDateString(d.dateClosed) : "/"}</span> : d.dateClosed
        }, label: "Zaprtje"
    },
    {
        v: (d, i) => {
            return d.dateClosed ? <span className="text-gray-400">{d.bankCountry}</span> : d.bankCountry
        }, label: "Država"
    },
]

const items = [
    {name: 'Finančno poročilo 2020', href: '#'},
    {name: 'Finančno poročilo 2019', href: '#'},
]

export default function CreditRatingDetails({id, showRating}) {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [orderedReports, setOrderedReports] = useState([])
    const [tableColumns, setTableColumns] = useState([])
    const [stats, setStats] = useState([])
    const [orderedAccounts, setOrderedAccounts] = useState([])

    useEffect(() => {
        if (id) getReport()
        else setLoading(true)
    }, [id])

    function getReport() {
        setLoading(true)
        const _oid = "" + id;
        api.get("/organizations/" + _oid + "/report").then(response => {
            if (id !== _oid) return

            setData(response?.data || null)

            const labels = [],
                _tableColumns = [
                    {v: d => d.label || "-", label: ""},
                ],
                datasets = JSON.parse(DEFAULT_DATASET)

            if (response?.data?.reports?.length > 0) {
                const reports = response?.data.reports.slice(0, 3).sort((a, b) => parseInt(a.year) - parseInt(b.year))
                for (let i = 0; i < reports.length; i++) {
                    const report = reports[i],
                        reportItems = [...report?.data?.yearlyReport?.indicators, ...report?.data?.yearlyReport?.reportItems]
                    for (let j = 0; j < datasets.length; j++) {
                        const dataset = datasets[j]
                        dataset.data.push(dataset.isString ? reportItems?.find(d => d?.id === dataset.id)?.value : parseInt(reportItems?.find(d => d?.id === dataset.id)?.value) || "")
                    }
                    labels.push(report.year)
                }
            } else {
                console.warn("No reports found")
            }

            const ordered = datasets.map((dataset, index) => {
                return {
                    label: dataset.label,
                    data: dataset.data
                }
            })

            labels.forEach((label, i) => {
                _tableColumns.push({
                    v: (d, idx, data) => {
                        const value = d.data[i],
                            prevValue = i > 0 ? d.data[i - 1] : 0,
                            formatted = formatMoney(value, idx <= 3) || "0,00"

                        return prevValue === 0 || prevValue === value ? formatted : value > prevValue ?
                            <span className="flex items-center relative pl-0"><ArrowSmallUpIcon
                                className="w-5 h-5 absolute -left-4 text-primary-400"/>{formatted}</span> :
                            value < prevValue ? <span className="flex items-center relative pl-0"><ArrowSmallDownIcon
                                className="w-5 h-5 absolute -left-4 text-red-400"/>{formatted}</span> : formatted
                    }, label: label
                })
            })

            setTableColumns(_tableColumns)
            setOrderedReports(ordered)
            setOrderedAccounts(response?.data?.bankAccounts || [])
            setStats([
                {
                    icon: BuildingLibraryIcon,
                    name: 'Nekster ocena',
                    backgroundColor: (response?.data?.financialData?.neksterScore && response?.data?.financialData?.neksterScore !== "-") ? "bg-primary-500" : "bg-gray-300",
                    stat: response?.data?.financialData?.neksterScore || '-',
                    previousStat: '',
                    color: (response?.data?.financialData?.neksterScore && response?.data?.financialData?.neksterScore !== "-") ? "text-primary-500" : "text-gray-400",
                    change: '2.02%',
                    changeType: 'increase'
                },
                {
                    icon: CreditCardIcon,
                    name: 'Finančna ocena',
                    backgroundColor: (response?.data?.financialData?.financialScore && response?.data?.financialData?.financialScore !== "-") ? "bg-primary-500" : "bg-gray-300",
                    stat: response?.data?.financialData?.financialScore || '-',
                    previousStat: '',
                    color: (response?.data?.financialData?.financialScore && response?.data?.financialData?.financialScore !== "-") ? "text-primary-500" : "text-gray-400",
                    change: '2.02%',
                    changeType: 'increase'
                },
                {
                    icon: BuildingOffice2Icon,
                    name: 'Ocena panoge',
                    backgroundColor: (response?.data?.financialData?.categoryScore && response?.data?.financialData?.categoryScore !== "-") ? "bg-primary-500" : "bg-gray-300",
                    stat: response?.data?.financialData?.categoryScore || '-',
                    previousStat: '',
                    color: (response?.data?.financialData?.categoryScore && response?.data?.financialData?.categoryScore !== "-") ? "text-primary-500" : "text-gray-400",
                    change: '2.02%',
                    changeType: 'increase'
                },
                {
                    icon: CurrencyEuroIcon,
                    name: 'Plačilni indeks',
                    backgroundColor: (response?.data?.financialData?.paymentIndex || response?.data?.financialData?.paymentIndex !== "-") ? "bg-primary-500" : "bg-gray-300",
                    stat: response?.data?.financialData?.paymentIndex || '-',
                    previousStat: '',
                    color: (response?.data?.financialData?.paymentIndex || response?.data?.financialData?.paymentIndex !== "-") ? "text-primary-500" : "text-gray-400",
                    change: '2.02%',
                    changeType: 'increase'
                },
            ])
        }).catch(error => {
            // handle error
            console.log("error", error)
        }).finally(() => {
            setLoading(false)
        })
    }

    function ratioToPercentage(ratio) {
        // ratio string (0.88883) to percentage number without decimals
        return parseInt((parseFloat(ratio) * 100).toFixed(0))
    }

    return (
        <>
            {loading ? (
                <div className="flex justify-center items-center h-full">
                    <TailSpinner big/>
                </div>
            ) : (
                <div className="flex flex-col">
                    <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
                        <h2 className="sr-only" id="profile-overview-title">
                            Overview
                        </h2>
                        <div className="bg-white p-6 px-4 sm:px-6 lg:px-8">
                            <div className="sm:flex sm:items-center sm:justify-between">
                                <div className="sm:flex sm:space-x-5">
                                    {/*<div className="flex-shrink-0">
                                        <img className="mx-auto h-20 w-20 rounded-full bg-gray-50 object-scale-down p-4"
                                             src={user.imageUrl} alt=""/>
                                    </div>*/}
                                    <div className="flex-shrink-0">
                                        <div
                                            className="h-20 w-20 rounded-full bg-gray-100 flex items-center justify-center">
                                            <BuildingOfficeIcon
                                                className="h-10 w-10 text-gray-500"/>
                                        </div>
                                    </div>
                                    <div
                                        className="mt-4 flex flex-col justify-center text-center sm:mt-0 sm:pt-1 sm:text-left">
                                        {/*<p className="text-sm font-medium text-gray-600">Osnovni podatki</p>*/}
                                        <p className="text-xl font-bold text-primary-700 sm:text-2xl">{data?.name}</p>
                                        <p className="text-sm font-medium text-gray-600">{formatAddress(data?.address)}</p>
                                    </div>
                                </div>
                                <div className="mt-5 flex justify-center sm:mt-0">
                                    <button
                                        type="button"
                                        className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                    >
                                        Primerjava ocen
                                    </button>
                                    <button
                                        type="button"
                                        className="ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                    >
                                        <BookmarkIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true"/>
                                        Dodaj v portfelj
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 py-3">
                            <h3 className="text-xs font-medium leading-6 text-gray-500 px-4 sm:px-6 lg:px-8">
                                Lastništvo in povezave
                            </h3>
                            <div className="overflow-hidden w-full px-4 sm:px-6 lg:px-8">
                                <div className="mt-2 overflow-hidden">
                                    <Swiper
                                        navigation={true} modules={[Navigation]}
                                        spaceBetween={20}
                                        slidesPerView={1}
                                        breakpoints={{
                                            // when window width is >= 640px
                                            640: {
                                                slidesPerView: 3,
                                                spaceBetween: 20
                                            },
                                        }}
                                    >
                                        {(data.latestReport?.data?.details?.owners || []).map((owner, idx) => (
                                            <SwiperSlide key={idx}>
                                                <div className={classNames(
                                                    "col-span-1 flex rounded-md shadow-sm",
                                                    owner.initials === 0 ? "opacity-40" : ""
                                                )}>
                                                    <div
                                                        className="relative rounded-l-md overflow-hidden flex-shrink-0 flex items-center justify-center w-16 h-14 text-white text-sm font-medium"
                                                    >
                                    <span
                                        className={classNames(
                                            owner.bgColor,
                                            'absolute inset-0 opacity-10 bg-primary-500',
                                        )}/>
                                                        {owner.ownership?.[0]?.ratio ? (
                                                            <>
                                        <span
                                            style={{
                                                maxHeight: ratioToPercentage(owner.ownership?.[0]?.ratio) + "%"
                                                //maxHeight: ratioToPercentage(owner.ownership?.[0]?.ratio) > 50 ? "51%" : "49%"
                                            }}
                                            className={classNames(
                                                owner.bgColor,
                                                'absolute w-full h-full bottom-0 bg-primary-500 blur-[1px]',
                                            )}/>
                                                                <div
                                                                    style={{
                                                                        textShadow: ratioToPercentage(owner.ownership?.[0]?.ratio) > 50 ? "rgb(12,170,208) 0px -1px 4px" : "rgb(255,255,255) 0px 1px 4px"
                                                                    }}
                                                                    className={classNames(
                                                                        "z-10 font-medium",
                                                                        ratioToPercentage(owner.ownership?.[0]?.ratio) > 50 ? "text-primary-100" : "text-primary-500",
                                                                    )}>
                                                                    <span
                                                                        className="opacity-100">{ratioToPercentage(owner.ownership?.[0]?.ratio)}</span>
                                                                    <span className="opacity-60">&nbsp;%</span>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span
                                                                    className="text-gray-400">{ratioToPercentage(owner.ownership?.[0]?.ratio) + "%"}</span>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div
                                                        className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
                                                        <div className="flex-1 truncate px-4 py-2 text-xs">
                                                            <a href={owner.href}
                                                               className="font-medium text-gray-900 hover:text-gray-600">
                                                                {owner.companyName || owner.foreignCompany || ([owner.firstName, owner.lastName].join(" ") || "-")}
                                                            </a>
                                                            <p className="text-gray-500 text-3xs">Od {formatDate(owner.date)}</p>
                                                        </div>
                                                        {!owner.name && (
                                                            <div className="flex-shrink-0 pr-2">
                                                                <button
                                                                    type="button"
                                                                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                                                >
                                                                    <span className="sr-only">Open options</span>
                                                                    <ChevronRightIcon className="h-5 w-5"
                                                                                      aria-hidden="true"/>
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <ul role="list" className="flex gap-5">

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 mb-16">
                        <div>
                            <h3 className="mt-8 text-lg font-medium leading-6 text-gray-900">Bonitetna ocena</h3>
                            <dl className="relative mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                                {stats.map((item, idx) => (
                                    <div
                                        key={idx}
                                        className={classNames(
                                            showRating ? "" : "blur",
                                            "relative overflow-hidden bg-white px-4 pt-5 pb-12 rounded-xl border border-gray-200 sm:px-6 sm:pt-6",
                                        )}
                                    >
                                        <dt>
                                            <div className={classNames(
                                                "absolute rounded-md p-3",
                                                item.backgroundColor
                                            )}>
                                                <item.icon className="h-6 w-6 text-white" aria-hidden="true"/>
                                            </div>
                                            <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
                                        </dt>
                                        <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                                            <p className={classNames(
                                                "text-2xl font-semibold",
                                                item.color
                                            )}>{item.stat}</p>
                                            <p
                                                className={classNames(
                                                    //item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                                                    'ml-2 flex items-baseline text-sm font-semibold text-gray-400'
                                                )}
                                            >
                                                {item.previousStat}
                                            </p>
                                            {/*<p
                                        className={classNames(
                                            item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                                            'ml-2 flex items-baseline text-sm font-semibold'
                                        )}
                                    >
                                        {item.changeType === 'increase' ? (
                                            <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                                                         aria-hidden="true"/>
                                        ) : (
                                            <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                                                           aria-hidden="true"/>
                                        )}

                                        <span
                                            className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                                        {item.change}
                                    </p>*/}
                                            <div
                                                className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                                                <div className="text-sm">
                                                    <a href="#"
                                                       className="font-medium text-primary-600 hover:text-primary-500">
                                                        {' '}
                                                        Poglej več<span
                                                        className="sr-only"> {item.name} podrobnosti</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </dd>
                                    </div>
                                ))}
                                {!showRating && (
                                    <div className="absolute inset-0 flex justify-center items-center">
                                        <button
                                            type="button"
                                            className="inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                        >
                                            <SparklesIcon className="-ml-1 mr-2 h-4 w-4" aria-hidden="true"/>
                                            {t("enableFeature")}
                                        </button>
                                    </div>
                                )}
                            </dl>
                        </div>

                        <div className="mt-16 flex justify-between items-center">
                            <h2 className="text-lg leading-6 font-medium text-gray-900">
                                Finančni podatki
                            </h2>
                            <div>
                                <div className="inline-flex rounded-md shadow-sm">
                                    <button
                                        type="button"
                                        className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
                                    >
                                        <ArrowDownOnSquareIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true"/>
                                        Finančno poročilo 2021
                                    </button>
                                    <Menu as="div" className="relative -ml-px block">
                                        <Menu.Button
                                            className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500">
                                            <span className="sr-only">Open options</span>
                                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true"/>
                                        </Menu.Button>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                className="absolute right-0 z-20 mt-2 -mr-1 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="py-1">
                                                    {items.map((item, idx) => (
                                                        <Menu.Item key={idx}>
                                                            {({active}) => (
                                                                <a
                                                                    href={item.href}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                        'block px-4 py-2 text-sm'
                                                                    )}
                                                                >
                                                                    {item.name}
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                    ))}
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                        </div>

                        <div
                            className="mt-8 overflow-hidden bg-white sm:rounded-xl border border-gray-200 flex flex-col">
                            <TableView id="creditRanking1" columns={tableColumns} data={orderedReports} hasMore={false}
                                       loading={loading}
                                       selectable={false}/>
                        </div>

                        <h2 className="mt-16 text-lg leading-6 font-medium text-gray-900">
                            Transakcijski računi
                        </h2>
                        <div
                            className="mt-8 overflow-hidden bg-white sm:rounded-xl border border-gray-200 flex flex-col">
                            <TableView id="creditRanking2" columns={accountsColumns} data={orderedAccounts}
                                       hasMore={false}
                                       loading={loading}
                                       selectable={false}/>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
