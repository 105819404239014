import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {classNames, formatAddress} from "../../utils";
import {
    clientSelector,
    fetchClient,
    fetchOrganizationUsers,
    organizationUsersSelector,
    realActiveCidSelector
} from "../../store/client";
import TailSpinner from "../../components/tailSpinner";
import {EllipsisVerticalIcon, ExclamationTriangleIcon, InformationCircleIcon} from "@heroicons/react/20/solid";
import EditUserDialog from "./editUserDialog";
import InviteUserDialog from "./inviteUserDialog";
import EditBillingEmail from "./editBillingEmail";
import {Menu, Transition} from '@headlessui/react'
import ConfirmDialog from "../../components/confirmDialog";
import useSWR from "swr";
import {api} from "../../config";

const roles = {
    owner: 'text-green-700 bg-green-50 ring-green-600/20',
    admin: 'text-gray-700 bg-gray-50 ring-gray-600/20',
    editor: 'text-gray-700 bg-gray-50 ring-gray-600/20',
    viewer: 'text-gray-700 bg-gray-50 ring-gray-600/20',
}

export default function GeneralTab() {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const activeClientId = useSelector(realActiveCidSelector)
    const clientAccess = useSWR('client+' + activeClientId, () => api.get(`/organizations/${activeClientId}/access`)
        .then(res => res.data))

    const client = useSelector(clientSelector(activeClientId))
    const users = useSelector(organizationUsersSelector)
    const [isOrganizationDetailsLoading, setIsOrganizationDetailsLoading] = useState(false)
    //const [bankAccounts, setBankAccounts] = useState([])
    const bankAccount = client?.bankAccount

    //const [isIBANDialogOpen, setIsIBANDialogOpen] = useState(false)
    const [isEditBillingEmailDialogOpen, setIsEditBillingEmailDialogOpen] = useState(false)
    const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false)
    const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = useState(false)
    const [isInviteUserDialogOpen, setIsInviteUserDialogOpen] = useState(false)

    const NotSet = () => <i className="text-gray-600 font-light">{t('notSet')}</i>

    useEffect(() => {
        if (activeClientId) {
            setIsOrganizationDetailsLoading(true)
            dispatch(fetchClient(activeClientId))
            dispatch(fetchOrganizationUsers(activeClientId))
        }
    }, [activeClientId])

    useEffect(() => {
        if (!isEditUserDialogOpen) {
            dispatch(fetchOrganizationUsers(activeClientId))
        }
    }, [isEditUserDialogOpen])

    useEffect(() => {
        if (!isEditBillingEmailDialogOpen) {
            setIsOrganizationDetailsLoading(true)
            dispatch(fetchClient(activeClientId))
        }
    }, [isEditBillingEmailDialogOpen])

    useEffect(() => {
        isOrganizationDetailsLoading && setIsOrganizationDetailsLoading(false)
    }, [client])

    function onOpenIBANDialog() {
        // TODO: add functionality

        //dispatch(setDialogLoader(true))
        //setIsIBANDialogOpen(true)
        //setBankAccounts([])

        /*dispatch(getOrganizationIBANList((list, err) => {
            // TODO: display inside error toast

            setIsIBANDialogOpen(!!list)
            setBankAccounts(list || [])
            dispatch(setDialogLoader(false))
        }))*/
    }

    function onOpenUpdateBillingEmailDialog() {
        setIsEditBillingEmailDialogOpen(true)
    }

    function removeUserAccess(user) {
        console.log("removeUserAccess", user)
    }


    return (
        <>
            <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">{t('organization')}</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">{t('yourOrganizationDetails')}</p>

                <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                    {isOrganizationDetailsLoading || !client ? (
                        <div className="py-4 sm:flex justify-center">
                            <TailSpinner/>
                        </div>
                    ) : client?.status === "PENDING" ? (
                        <div className="rounded-md bg-primary-50 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <InformationCircleIcon className="h-5 w-5 text-primary-400"
                                                           aria-hidden="true"/>
                                </div>
                                <div className="ml-3 flex-1 md:flex md:justify-between">
                                    <p className="text-sm text-primary-700">{t('yourOrganizationIsPendingApproval')}</p>
                                </div>
                            </div>
                        </div>
                    ) : client?.status === "INACTIVE" ? (
                        <div className="rounded-md bg-red-50 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <ExclamationTriangleIcon className="h-5 w-5 text-red-400"
                                                             aria-hidden="true"/>
                                </div>
                                <div className="ml-3 flex-1 md:flex md:justify-between">
                                    <p className="text-sm text-red-700">{t('yourOrganizationHasBeenDisabledContactSupportForMo')}</p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="pt-6 sm:flex">
                                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                    {t('organizationName')}
                                </dt>
                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                    <div className="text-gray-900">
                                        {client?.name || <NotSet/>}
                                    </div>
                                </dd>
                            </div>
                            <div className="pt-6 sm:flex">
                                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                    {t('address')}
                                </dt>
                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                    <div className="text-gray-900">
                                        {formatAddress(client?.address) || <NotSet/>}
                                    </div>
                                </dd>
                            </div>
                            <div className="pt-6 sm:flex">
                                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                    {t('taxNumber')}
                                </dt>
                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                    <div className="text-gray-900">
                                        {client?.taxNo || <NotSet/>}
                                    </div>
                                </dd>
                            </div>
                            <div className="pt-6 sm:flex">
                                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                    {t('mainBankAccount')}
                                </dt>
                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                    <div className="text-gray-900">
                                        {bankAccount?.number ? ("" + bankAccount?.number).match(/.{1,4}/g).join(" ") :
                                            <NotSet/>}
                                        {(bankAccount?.bankName ? " (" + bankAccount?.bankName + ")" : "")}
                                    </div>
                                </dd>
                            </div>
                            <div className="pt-6 sm:flex">
                                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                    {t('billingEmail')}
                                </dt>
                                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                    <div className="text-gray-900">
                                        {client?.billingEmail || <NotSet/>}
                                    </div>
                                    {clientAccess.data?.role === "admin" || clientAccess.data?.role === "owner" || clientAccess.data?.role === "editor" ? (
                                        <button type="button"
                                                onClick={onOpenUpdateBillingEmailDialog}
                                                className="font-semibold text-primary-600 hover:text-primary-500">
                                            {t('update')}
                                        </button>
                                    ) : null}
                                </dd>
                            </div>
                        </>
                    )}
                </dl>
            </div>

            <div className="mt-16">
                <h2 className="text-base font-semibold leading-7 text-gray-900">{t('accessManagement')}</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">{t('manageWhoHasAccessToYourOrganization')}</p>


                <div className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                    <ul role="list" className="divide-y divide-gray-100">
                        {users.map((person, i) => (
                            <li key={i} className="flex items-center justify-between gap-x-6 py-5">
                                <div className="min-w-0">
                                    <div className="flex items-start gap-x-3">
                                        <p className="text-sm font-semibold leading-6 text-gray-900">{person.fullName}</p>
                                        <p
                                            className={classNames(
                                                roles[person.role],
                                                'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset capitalize'
                                            )}
                                        >
                                            {t(person.role)}
                                        </p>
                                        {person.disabled && (
                                            <p className="text-gray-600 bg-gray-50 ring-gray-500/10 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                                                Disabled
                                            </p>
                                        )}
                                    </div>
                                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                        {/*<p className="whitespace-nowrap">
                                        Added <time
                                        dateTime={person.dueDateTime}>{getFirestampDateString(person.createdAt)}</time>
                                    </p>
                                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                        <circle cx={1} cy={1} r={1}/>
                                    </svg>*/}
                                        <p className="truncate">{person.email}</p>
                                    </div>
                                </div>
                                <div className="flex flex-none items-center gap-x-4">
                                    {clientAccess.data?.role === "admin" || clientAccess.data?.role === "owner" ? (
                                        <Menu as="div" className="relative flex-none">
                                            <Menu.Button
                                                className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                                                <span className="sr-only">Open options</span>
                                                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true"/>
                                            </Menu.Button>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items
                                                    className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                                    <Menu.Item>
                                                        {({active}) => (
                                                            <span
                                                                onClick={() => setIsEditUserDialogOpen(person)}
                                                                className={classNames(
                                                                    active ? 'bg-gray-50' : '',
                                                                    'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                                                                )}
                                                            >
                                                        {t("edit")}<span className="sr-only">, {person.name}</span>
                                                    </span>
                                                        )}
                                                    </Menu.Item>
                                                    {/*<Menu.Item>
                                                    {({active}) => (
                                                        <span
                                                            onClick={() => setIsDeleteUserDialogOpen(person)}
                                                            className={classNames(
                                                                active ? 'bg-gray-50' : '',
                                                                'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                                                            )}
                                                        >
                                                        {t("delete")}<span className="sr-only">, {person.name}</span>
                                                    </span>
                                                    )}
                                                </Menu.Item>*/}
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    ) : null}
                                </div>
                            </li>
                        ))}
                    </ul>
                    {clientAccess.data?.role === "admin" || clientAccess.data?.role === "owner" ? (
                        <div className="flex border-t border-gray-100 pt-6">
                            <button
                                type="button"
                                onClick={() => setIsInviteUserDialogOpen(true)}
                                className="text-sm font-semibold leading-6 text-primary-600 hover:text-primary-500">
                                <span aria-hidden="true">+</span> {t('inviteUser')}
                            </button>
                        </div>
                    ) : null}
                </div>
            </div>

            <EditBillingEmail open={isEditBillingEmailDialogOpen} setOpen={setIsEditBillingEmailDialogOpen}/>
            <ConfirmDialog open={!!isDeleteUserDialogOpen} setOpen={setIsDeleteUserDialogOpen} isWarning
                           title={t("deleteUserPromptTitle")}
                           message={t("deleteUserPromptText")}
                           onConfirm={() => removeUserAccess(isDeleteUserDialogOpen)}/>
            <InviteUserDialog open={isInviteUserDialogOpen} setOpen={setIsInviteUserDialogOpen}/>
            <EditUserDialog uid={isEditUserDialogOpen?.uid} email={isEditUserDialogOpen?.email}
                            role={isEditUserDialogOpen?.role} open={!!isEditUserDialogOpen}
                            setOpen={setIsEditUserDialogOpen}/>
        </>
    )
}
