import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import React from "react";
import {classNames} from "../../utils";

const inputName = "email"

export default function Financing({data, setData, error, setError, nextState, prevState}) {
    const {t} = useTranslation()
    const navigate = useNavigate();

    const financingTypes = [
        {id: 'F', title: 'Factoring'},
        {id: 'RF', title: 'Reverse factoring'},
    ]

    function onSubmit(e) {
        e.preventDefault();

        if (!data) {
            setError("Please select financing type")
        } else {
            navigate("/quote/" + nextState.nextStepName)
        }
    }

    return (
        <form onSubmit={onSubmit} className="w-full max-w-sm">
            <label className="text-base font-semibold text-white">Financing type</label>
            <p className="text-sm text-gray-50">Select preferred financing type</p>
            <fieldset className="mt-4">
                <legend className="sr-only">Financing type</legend>
                <div className="space-y-4">
                    {financingTypes.map((notificationMethod) => (
                        <div key={notificationMethod.id} className="flex items-center">
                            <input
                                id={notificationMethod.id}
                                name={inputName}
                                type="radio"
                                onChange={e => setData(notificationMethod.id)}
                                checked={notificationMethod.id === data}
                                className="bg-primary-600 h-4 w-4 border-primary-500 text-primary-600 focus:ring-primary-600"
                            />
                            <label htmlFor={notificationMethod.id}
                                   className="ml-3 block text-sm font-medium leading-6 text-white">
                                {notificationMethod.title}
                            </label>
                        </div>
                    ))}
                </div>
            </fieldset>
            {error && (
                <p className="mt-2 text-sm text-red-600" id={inputName + "-error"}>
                    {error}
                </p>
            )}
            <div className="mt-8 flex space-x-4">
                <Link
                    to={"/quote/" + prevState.prevStepName}
                    className="rounded-md bg-white/10 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                >
                    Back
                </Link>
                <button
                    type="submit"
                    disabled={!data}
                    className={classNames(
                        "rounded-md bg-primary-700 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-700",
                        !data ? "opacity-75 cursor-not-allowed" : ""
                    )}
                >
                    Next
                </button>
            </div>
        </form>
    )
}
