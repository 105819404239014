import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    actions: []
};

export const slice = createSlice({
    name: 'actionsView',
    initialState,
    reducers: {
        setActions: (state, action) => ({
            actions: action.payload || [],
        }),
    }
});

// ACTIONS

const {setActions} = slice.actions;

export const setActionsView = actions => {
    return setActions(actions)
}

// SELECTORS

export const actionsViewSelector = state => state.actionsView.actions;

export default slice.reducer;
