import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {auth} from "../firebase";
import {RegisterRoot} from "./Register";
import {sendPasswordResetEmail} from "firebase/auth";

export default function AppLostPassword() {
    const {t} = useTranslation()
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function onSubmit(e) {
        e.preventDefault();
        sendPasswordResetEmail(auth, email).then(() => {
            setError("")
            setLoading(false)

            navigate("/sent-confirmation")
        }).catch(err => {
            console.error(err)
            if (err?.message) {
                setError(err.message)
            } else {
                setError("There was an error submitting your request")
            }
            setLoading(false)
        })
    }

    return (
        <RegisterRoot>
            <form onSubmit={onSubmit}>
                <div className="Register-Content-Header">
                    <Trans i18nKey='resetPasswordTitle'>
                        <h1 className="Register-Heading">{t('resetPasswordTitle')}</h1>
                    </Trans>
                    <Trans i18nKey='resetPasswordSubtitle'>
                        <span className="Register-Subtitle block">{t('resetPasswordText')}</span>
                    </Trans>
                    <div className="Register-Content-Body">
                        <div className="Register-Row">
                            <div className="Register-FormField Register-FormField--Next">
                                <input type="email" name="email"
                                       className="Register-Input focus:ring-0 focus:border-0"
                                       disabled={loading}
                                       value={email}
                                       onChange={e => setEmail(e.target.value)}
                                       placeholder={t('yourEmail')}
                                       required
                                       autoComplete="email"/>
                                <button disabled={loading} type="submit" data-loading={loading}
                                        className="Button Button--Next">
                                    <div className="Button__Loader"/>
                                </button>
                                <span
                                    className="Register-FormField-Helper Register-FormField-Helper--Error">{error}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </RegisterRoot>
    )
}
