import React, {useEffect, useState} from "react"
import {CheckCircleIcon} from '@heroicons/react/20/solid'
import {Link, Navigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Country from "./country";
import Tax from "./tax";
import TailSpinner from "../../components/tailSpinner";
import {classNames} from "../../utils";
import Phone from "./phone";
import Email from "./email";
import Financing from "./financing";
import FinancingData from "./financingData";
import Complete from "./complete";
import {RegisterContent, RegisterFooter, RegisterHeader} from "../Register";

function useNarrowSteps(inputData, stepName) {
    const defaultSteps = [
        {name: 'Company', step: 'country', status: 'current', component: Country, valid: d => !!d?.code},
        {
            name: 'Company', step: 'company', status: 'upcoming', component: Tax, valid: d => d?.taxNo && d?.name
        },
        {name: 'Contact', step: 'phone', status: 'upcoming', component: Phone, valid: d => !!d},
        {name: 'Contact', step: 'email', status: 'upcoming', component: Email, valid: d => !!d},
        {name: 'Financing', step: 'financing', status: 'upcoming', component: Financing, valid: d => !!d},
        {name: 'Financing', step: 'details', status: 'upcoming', component: FinancingData, valid: d => !!d},
        {name: 'Complete', step: 'complete', status: 'upcoming', component: Complete},
    ]

    const [modifiedNavigationSteps, setModifiedNavigationSteps] = useState([])
    const [modifiedSteps, setModifiedSteps] = useState(defaultSteps)
    const [modifiedCurrentStep, setModifiedCurrentStep] = useState(null)
    const [nextState, setNextState] = useState(null)
    const [prevState, setPrevState] = useState(null)
    const [error, setError] = useState(null)
    const [navigateTo, setNavigateTo] = useState(null)

    useEffect(() => {
        if (stepName !== modifiedCurrentStep?.step) {
            const navigationSteps = {}
            let reachedCurrent = false, currentStepObject = null, nextStep = null, currentName = "Business information",
                prevStateObject = null, navigateTo = null

            setModifiedSteps(defaultSteps.map(({name, step, component, valid}, idx) => {
                const isCurrent = step === stepName,
                    status = isCurrent ? 'current' : reachedCurrent ? 'upcoming' : 'complete',
                    it = {
                        name,
                        step,
                        status,
                        component
                    }

                if (!navigationSteps.hasOwnProperty(name)) {
                    navigationSteps[name] = {name, step, status}
                }

                if (isCurrent) {
                    navigationSteps[name] = {name, step, status}
                }

                if (isCurrent) {
                    currentName = name
                    reachedCurrent = true
                    currentStepObject = it

                    if (defaultSteps.length > idx + 1) {
                        nextStep = defaultSteps[idx + 1]
                    }
                }

                if (!reachedCurrent) {
                    // must be valid
                    if (!navigateTo && !valid(inputData[step])) {
                        navigateTo = prevStateObject?.prevStepName || defaultSteps[0].step
                    }

                    prevStateObject = {
                        prevStepName: it?.step,
                        capture: null,
                    }
                }
                return it
            }))

            setNavigateTo(navigateTo)
            setModifiedNavigationSteps(Object.values(navigationSteps))
            setModifiedCurrentStep(currentStepObject)
            setNextState({
                currentStepName: currentStepObject?.step,
                nextStepName: nextStep?.step,
                capture: null,
            })
            setPrevState(prevStateObject)
            setError(null)
        }

    }, [stepName])

    return {
        navigateTo: navigateTo,
        navigationSteps: modifiedNavigationSteps,
        steps: modifiedSteps,
        currentStep: modifiedCurrentStep,
        nextState, setNextState,
        prevState, setPrevState,
        error, setError
    }
}

export default function Quote() {
    const {t} = useTranslation()
    const {step} = useParams()
    const [inputData, setInputData] = useState({
        country: null,
        company: null,
        phone: "",
        email: "",
        financing: "",
        details: null
    })
    const [success, setSuccess] = useState(false)

    const {
        navigateTo,
        navigationSteps,
        currentStep,
        nextState,
        setNextState,
        prevState,
        setPrevState,
        error,
        setError,
    } = useNarrowSteps(inputData, step)

    useEffect(() => {
        const d = window.localStorage.getItem("quoteData")
        if (d) {
            try {
                const pd = JSON.parse(d)
                console.log("INIT DATA", pd)
                //if (typeof pd === "object") setInputData(pd)
            } catch (e) {

            }
        }
        return () => {
            //console.log("DELETING")
            window.localStorage.removeItem("quoteDate")
        }
    }, [])

    useEffect(() => {
        window.localStorage.setItem("quoteData", JSON.stringify(inputData))
        //console.log("DATA UPDATE", inputData)
    }, [inputData])

    if (navigateTo) return <Navigate to={"/quote/" + navigateTo}/>

    return (
        <div className="h-full flex flex-col">
            <RegisterHeader/>
            <RegisterContent>

                <div className="w-full h-full grid sm:grid-cols-8 max-h-full overflow-y-auto gap-x-2 text-left">


                    {success ? (
                        <div className="sm:col-span-8 flex flex-col justify-center items-center">
                            <h1 className="text-xl font-semibold text-white">Thank you!</h1>
                            <p className="text-white text-md">Check your inbox in a few minutes.</p>
                        </div>
                    ) : (
                        <>
                            <aside
                                className="relative hidden sm:flex sm:flex-col sm:col-span-2 w-full h-full justify-center items-start pl-8 md:pl-16">

                                <h1 className="text-lg font-semibold text-white text-center mb-8">Request a Quote</h1>

                                <nav className="flex justify-center" aria-label="Progress">
                                    <ol role="list" className="space-y-6">
                                        {navigationSteps.map((step) => (
                                            <li key={step.step}>
                                                {step.status === 'complete' ? (
                                                    <Link to={"/quote/" + step.step} className="group"><span
                                                        className="flex items-start"><span
                                                        className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"><CheckCircleIcon
                                                        className="h-full w-full text-primary-700 group-hover:text-primary-800"
                                                        aria-hidden="true"/></span><span
                                                        className="ml-3 text-sm font-medium text-primary-700 group-hover:text-primary-800">{step.name}</span></span>
                                                    </Link>
                                                ) : step.status === 'current' ? (
                                                    <Link to={"/quote/" + step.step} className="flex items-start"
                                                          aria-current="step"><span
                                                        className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                                                        aria-hidden="true"><span
                                                        className="absolute h-4 w-4 rounded-full bg-primary-200"/><span
                                                        className="relative block h-2 w-2 rounded-full bg-primary-600"/></span><span
                                                        className="ml-3 text-sm font-medium text-primary-800">{step.name}</span>
                                                    </Link>
                                                ) : (
                                                    <Link to={"/quote/" + step.step} className="group">
                                                        <div className="flex items-start">
                                                            <div
                                                                className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center"
                                                                aria-hidden="true">
                                                                <div
                                                                    className="h-2 w-2 rounded-full bg-primary-200 group-hover:bg-primary-50"/>
                                                            </div>
                                                            <p className="ml-3 text-sm font-medium text-primary-200 group-hover:text-primary-50">{step.name}</p>
                                                        </div>
                                                    </Link>
                                                )}
                                            </li>
                                        ))}
                                    </ol>
                                </nav>
                            </aside>

                            <main className="sm:col-span-4 flex flex-col justify-center items-center space-y-8 my-16">
                                <h1 className="block sm:hidden text-lg font-semibold text-white text-center mb-8">Request
                                    a Quote</h1>
                                {currentStep?.component && nextState?.currentStepName === currentStep.step && (
                                    <currentStep.component
                                        nextState={nextState}
                                        prevState={prevState}
                                        error={error}
                                        setError={setError}
                                        success={success}
                                        setSuccess={setSuccess}
                                        allData={inputData}
                                        data={inputData[currentStep.step]}
                                        setData={(d) => setInputData({
                                            ...inputData,
                                            [currentStep.step]: d
                                        })}/>)}
                                <div className="hidden w-full max-w-sm space-x-4">
                                    {prevState?.capture ? (
                                        <button
                                            type="button"
                                            onClick={() => prevState.capture(inputData[currentStep.step], {
                                                setData: (d) => setInputData({
                                                    ...inputData,
                                                    [currentStep.step]: d
                                                }), nextState, setNextState, prevState, setPrevState, setError
                                            })}
                                            className="rounded-md bg-white/10 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                                        >
                                            Back capture
                                        </button>
                                    ) : (prevState?.prevStepName && (
                                        <Link
                                            to={"/quote/" + prevState.prevStepName}
                                            className="rounded-md bg-white/10 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                                        >
                                            Back
                                        </Link>
                                    ))}
                                    {nextState?.capture ? (
                                        <button
                                            type="button"
                                            onClick={() => nextState.capture(inputData[currentStep.step], {
                                                setData: (d) => setInputData({
                                                    ...inputData,
                                                    [currentStep.step]: d
                                                }), nextState, setNextState, prevState, setPrevState, setError
                                            })}
                                            disabled={nextState.loading}
                                            className={classNames(
                                                "inline-flex justify-center rounded-md bg-primary-700 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-700",
                                                nextState.loading ? "opacity-75 cursor-not-allowed" : ""
                                            )}
                                        >
                                            {nextState.loading && (
                                                <TailSpinner/>
                                            )}
                                            Next capture
                                        </button>
                                    ) : (nextState && (
                                        <>
                                            {inputData[currentStep.step] ? (
                                                <Link
                                                    to={"/quote/" + nextState.nextStepName}
                                                    className={classNames(
                                                        "rounded-md bg-primary-700 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-700"
                                                    )}
                                                >
                                                    Next
                                                </Link>
                                            ) : (
                                                <button
                                                    type="button"
                                                    disabled
                                                    className={classNames(
                                                        "rounded-md bg-primary-700 py-2 px-3 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-700 opacity-75 cursor-not-allowed"
                                                    )}
                                                >
                                                    Next
                                                </button>
                                            )}
                                        </>
                                    ))}
                                </div>
                            </main>
                        </>
                    )}
                </div>
            </RegisterContent>
            <RegisterFooter/>
        </div>
    )
}

