import React, {Fragment, useEffect, useRef, useState} from 'react';
import {classNames, parseNumberString, sloDateParser} from "../helpers/helpers";
import {
    ArrowUpTrayIcon as UploadIcon,
    CheckIcon,
    ChevronUpDownIcon as SelectorIcon,
    PlusSmallIcon as PlusSmIconSolid
} from '@heroicons/react/20/solid'
import {Combobox, Transition} from '@headlessui/react'
import {useDispatch, useSelector} from "react-redux";
import {Navigate, useNavigate} from "react-router-dom";
import {clientSelector, realActiveCidSelector} from "../store/client";
import {fetchPartners, partnerListSelector} from "../store/partners";
import {financeApi} from "../config";
import Toolbar from "../components/toolbar";
import {dateFormatter, importXLSX} from "../utils";
import CreateNewFinancingRequestOverviewDialog from "../components/createNewFinancingRequestOverviewDialog";
import ImportedInvoicesTables from "../components/importedInvoicesTable";
import {useTranslation} from "react-i18next";
import ActionLimitedNotice from "../components/actionLimitedNotice";
import ImportInvoicesOverviewDialog from "../components/importInvoicesOverviewDialog";
import {INVOICE_TYPE} from "../consts";


export default function ImportInvoices({
                                           financingType,
                                           invoiceType,
                                           setExtendedPaymentDate,
                                           navigateToOnSuccess,
                                           canCreate
                                       }) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const activeOrganizationId = useSelector(realActiveCidSelector)
    const organization = useSelector(clientSelector(activeOrganizationId))
    const orderedPartners = useSelector(partnerListSelector)

    const [isAddPartnerDialogOpen, setIsAddPartnerDialogOpen] = useState(false)
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
    const [isImportDialogOpen, setIsImportDialogOpen] = useState(false)

    const [selectedOtherParty, setSelectedOtherParty] = useState(null)
    const [status, setStatus] = useState("")
    const [invoiceRows, setInvoiceRows] = useState([])

    const [isSubmitting, setIsSubmitting] = useState(false)

    const importRef = useRef()

    const [addPartnerText, setAddPartnerText] = useState("")
    const [query, setQuery] = useState('')
    const filteredCompanies =
        query === ''
            ? orderedPartners
            : orderedPartners.filter((item) =>
                (item.name + " " + item.taxNo)
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    useEffect(() => {
        if (activeOrganizationId) dispatch(fetchPartners({filter: ["hasOid", "==", true]}))
    }, [activeOrganizationId])

    function onSelectionChange(e) {
        setAddPartnerText(e.target.value)
        setQuery(e.target.value)
    }

    function onSelectionLeave(e) {
        setQuery("")
        if (selectedOtherParty?.isCustom) {
            setIsAddPartnerDialogOpen(true)
        }
    }

    async function onImport() {
        if (importRef?.current?.files?.length > 0 && importRef.current.files[0] && importRef.current.files[0].arrayBuffer) {
            try {
                const data = await importRef.current.files[0].arrayBuffer(),
                    [importedRows, errors] = setExtendedPaymentDate ? await importXLSX(
                        data,
                        (obj, value) => obj["number"] = value,
                        (obj, value) => obj["date"] = value ? dateFormatter(sloDateParser(value).getTime()) : "",
                        (obj, value) => obj["dueDate"] = value ? dateFormatter(sloDateParser(value).getTime()) : "",
                        (obj, value) => obj["extendedPaymentDate"] = value ? dateFormatter(sloDateParser(value).getTime()) : "",
                        (obj, value) => obj["amount"] = parseNumberString(value) || "",
                    ) : await importXLSX(
                        data,
                        (obj, value) => obj["number"] = value,
                        (obj, value) => obj["date"] = value ? dateFormatter(sloDateParser(value).getTime()) : "",
                        (obj, value) => obj["dueDate"] = value ? dateFormatter(sloDateParser(value).getTime()) : "",
                        (obj, value) => obj["amount"] = parseNumberString(value) || "",
                    )

                if (errors.length > 0) {
                    for (let i = 0; i < errors.length; i++) {
                        const {type, code, message} = errors[i]
                        //console.log(type, code, message)
                        switch (code) {
                            case "importXLSX.multipleSheets":
                                alert(message)
                                break;
                            default:
                                alert("Error: " + message)
                        }
                    }
                }

                setInvoiceRows([
                    ...invoiceRows,
                    ...importedRows
                ])
            } catch (e) {
                console.error(e)
                alert("Error importing file: " + e.message)
            }
            importRef.current.value = ""
        }
    }

    function addRow() {
        setInvoiceRows([...invoiceRows, setExtendedPaymentDate ? {
            _id: Date.now(),
            number: "",
            date: "",
            dueDate: "",
            extendedPaymentDate: "",
            amount: "",
            fileId: "",
        } : {
            _id: Date.now(),
            number: "",
            date: "",
            dueDate: "",
            amount: "",
            fileId: "",
        }])
    }

    function deleteRow(_id) {
        setInvoiceRows(invoiceRows.filter(row => row._id !== _id))
    }

    function updateRow(data) {
        setInvoiceRows(invoiceRows.map(row => row._id === data._id ? data : row))
    }

    function onImportClicked(e) {
        e && e.preventDefault();
        if (!selectedOtherParty?.id) return alert("Please select a partner")
        setIsImportDialogOpen(true)
    }

    function onCreateClicked(e) {
        e && e.preventDefault();
        if (!selectedOtherParty?.id) return alert("Please select a partner")
        setIsCreateDialogOpen(true)
    }

    function onSubmit(userSetDate, createInvoicesOnly) {
        let stillUploading = false
        const object = {
            organizationId: activeOrganizationId,
            otherPartyPartnerId: selectedOtherParty.id,
            invoices: invoiceRows.map(r => {
                if (r._isUploading) stillUploading = true
                return setExtendedPaymentDate ? {
                    invoiceType: invoiceType,
                    number: r.number,
                    date: r.date,
                    dueDate: r.dueDate,
                    extendedPaymentDate: r.extendedPaymentDate,
                    amount: parseFloat(r.amount),
                    paidAmount: 0,
                    fileId: r.fileId,
                } : {
                    invoiceType: invoiceType,
                    number: r.number,
                    date: r.date,
                    dueDate: r.dueDate,
                    amount: parseFloat(r.amount),
                    paidAmount: 0,
                    fileId: r.fileId,
                }
            }),
        }

        if (stillUploading) return alert("Please wait for the uploads to complete before submitting your request.")

        if (createInvoicesOnly) object.invoiceType = invoiceType
        else {
            if (financingType === "factoring") {
                object.type = "F"
            } else if (financingType === "reverse-factoring") {
                object.type = "RF"
            } else {
                return alert("Invalid financing type")
            }
        }


        if (userSetDate) {
            if (invoiceType === INVOICE_TYPE.ISSUED) object.transactionDate = new Date(userSetDate)
            if (invoiceType === INVOICE_TYPE.RECEIVED) object.extendedPaymentDate = new Date(userSetDate)
        }

        setIsSubmitting(true)
        financeApi.post(createInvoicesOnly ? "/promissory-notes/import" : "/promissory-notes", object)
            .then(res => {
                setIsSubmitting(false)
                setIsCreateDialogOpen(false)
                navigateToOnSuccess && navigate(navigateToOnSuccess)
            })
            .catch(error => {
                setIsSubmitting(false)
                alert("Error: " + (error?.response?.data?.message || "Unknown error"))
                console.log("error", error)
            })
    }

    return (
        <>
            {(financingType !== "factoring" && financingType !== "reverse-factoring") ? (
                <Navigate to={navigateToOnSuccess || "/u/"}/>
            ) : (
                <>
                    {/* Page title & actions */}
                    <Toolbar/>
                    <div className="relative isolate overflow-hidden">
                        {/* Secondary navigation */}
                        <header className="pb-4 pt-6 sm:pb-6">
                            <div
                                className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
                                <h1 className="text-base font-semibold leading-7 text-gray-900">{t('importInvoices')}</h1>
                                <div className="ml-auto">
                                    <button
                                        type="button"
                                        onClick={onImportClicked}
                                        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    >
                                        {t('importInvoicesAction')}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={onCreateClicked}
                                        className="ml-3 inline-flex items-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                                    >
                                        {invoiceType === INVOICE_TYPE.ISSUED ? t('importAndFinance') : t('importAndExtend')}
                                    </button>
                                </div>
                            </div>
                        </header>
                        <div className="mt-8 pb-16 space-y-8">
                            {!organization?.oid || organization?.status !== "ACTIVE" && (
                                <div className="mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8">
                                    <div className="px-4 sm:px-6 lg:flex-auto lg:px-0">
                                        <ActionLimitedNotice/>
                                    </div>
                                </div>
                            )}
                            <div className="mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8">
                                <div className="px-4 sm:px-6 lg:flex-auto lg:px-0">
                                    <form onSubmit={onCreateClicked}>
                                        <div className="space-y-8">
                                            <div>
                                                <label
                                                    className="pb-4 block text-sm font-medium leading-6 text-gray-900">{financingType === "factoring" ? t('buyer') : t('seller')}</label>
                                                <Combobox value={selectedOtherParty} onChange={setSelectedOtherParty}>
                                                    <div className="relative mt-1">
                                                        <div
                                                            className="relative w-full bg-white border border-gray-300 shadow-sm rounded-md text-left overflow-hidden cursor-default focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 sm:text-sm">
                                                            <Combobox.Input
                                                                autoComplete="off"
                                                                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                                                                displayValue={item => (item?.shortName || item?.name || "")}
                                                                onChange={onSelectionChange}
                                                            />
                                                            <Combobox.Button
                                                                className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                                <SelectorIcon
                                                                    className="h-5 w-5 text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                            </Combobox.Button>
                                                        </div>
                                                        <Transition
                                                            as={Fragment}
                                                            leave="transition ease-in duration-100"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                            afterLeave={onSelectionLeave}
                                                        >
                                                            <Combobox.Options
                                                                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                                                {filteredCompanies.length === 0 && !addPartnerText ? (
                                                                    <div
                                                                        className="relative cursor-default select-none py-2 px-4 italic text-sm text-gray-500">
                                                                        Start typing to search...
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        {/*{addPartnerText && (
                                                                <Combobox.Option
                                                                    className={({active}) =>
                                                                        classNames(
                                                                            active ? 'text-white bg-gray-600' : 'text-gray-900',
                                                                            'cursor-default select-none relative py-2 pl-3 pr-9 pl-8'
                                                                        )
                                                                    }
                                                                    value={addNewPartnerItem}
                                                                >
                                                                    {({selected, active}) => (
                                                                        <>
                                                                            <div className="flex">
                                                                            <span
                                                                                className={classNames(
                                                                                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                                                                    active ? 'text-white' : 'text-gray-600'
                                                                                )}
                                                                            >
                        <PlusIcon className="h-5 w-5" aria-hidden="true"/>
                      </span>
                                                                                <span
                                                                                    className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                            Add "{addPartnerText}"
                          </span>
                                                                                <span
                                                                                    className={classNames(active ? 'text-gray-200' : 'text-gray-500', 'ml-2 truncate')}>
                            Click to add a new partner
                          </span>
                                                                            </div>

                                                                            {selected ? (
                                                                                <span
                                                                                    className={classNames(
                                                                                        active ? 'text-white' : 'text-gray-600',
                                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                    )}
                                                                                >
                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                          </span>
                                                                            ) : null}
                                                                        </>
                                                                    )}
                                                                </Combobox.Option>
                                                            )}*/}
                                                                        {filteredCompanies.map(item => (
                                                                            <Combobox.Option
                                                                                key={item.id}
                                                                                className={({active}) =>
                                                                                    classNames(
                                                                                        active ? 'text-white bg-gray-600' : 'text-gray-900',
                                                                                        'cursor-default select-none relative py-2 pl-3 pr-9 pl-8'
                                                                                    )
                                                                                }
                                                                                value={item}
                                                                            >
                                                                                {({selected, active}) => (
                                                                                    <>
                                                                                        <div className="flex">
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                            {item.name || item.shortName}
                          </span>
                                                                                            <span
                                                                                                className={classNames(active ? 'text-gray-200' : 'text-gray-500', 'ml-2 truncate')}>
                            {item.vatNo || item.taxNo}
                          </span>
                                                                                        </div>

                                                                                        {selected ? (
                                                                                            <span
                                                                                                className={classNames(
                                                                                                    active ? 'text-white' : 'text-gray-600',
                                                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                                )}
                                                                                            >
                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                          </span>
                                                                                        ) : null}
                                                                                    </>
                                                                                )}
                                                                            </Combobox.Option>
                                                                        ))}
                                                                    </>
                                                                )}
                                                            </Combobox.Options>
                                                        </Transition>
                                                    </div>
                                                </Combobox>
                                            </div>

                                            <div>
                                                <div className="pb-4 flex items-center">
                                                    <label
                                                        className="block text-sm font-medium leading-6 text-gray-900">{t('invoices')}</label>
                                                    <button
                                                        type="button"
                                                        onClick={addRow}
                                                        className="ml-4 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                    >
                                                        <PlusSmIconSolid className="w-4 w-4" aria-hidden="true"/>
                                                    </button>
                                                    <label
                                                        className="ml-4 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-gray-500 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                    >
                                                        <input ref={importRef} type="file" className="hidden"
                                                               onChange={onImport}/>
                                                        <UploadIcon className="h-4 w-4" aria-hidden="true"/>
                                                    </label>
                                                </div>
                                                <ImportedInvoicesTables financingType={financingType} rows={invoiceRows}
                                                                        deleteRow={deleteRow} updateRow={updateRow}
                                                                        setExtendedPaymentDate={setExtendedPaymentDate}
                                                                        status={status}/>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>


                    <CreateNewFinancingRequestOverviewDialog onSubmit={onSubmit} open={isCreateDialogOpen}
                                                             invoiceType={invoiceType}
                                                             setOpen={setIsCreateDialogOpen}
                                                             isSubmitting={isSubmitting}/>
                    <ImportInvoicesOverviewDialog onSubmit={onSubmit} open={isImportDialogOpen} n={invoiceRows.length}
                                                  setOpen={setIsImportDialogOpen} isSubmitting={isSubmitting}/>
                </>
            )}
        </>
    )
};
