import React, {useEffect} from "react";
import {useQuery} from "../utils";
import {auth} from "../firebase";
import Spinner from "../components/Spinner";
import {useNavigate} from "react-router-dom";
import {signInWithCustomToken} from "firebase/auth";

export function SignInAs() {
    const query = useQuery()
    const navigate = useNavigate();

    useEffect(() => {
        const token = query.get("token")
        signInWithCustomToken(auth, token).then(res => {
            navigate("/")
        }).catch(err => {
            console.error(err)
            navigate("/")
        })
    }, [])

    return (
        <Spinner fullHeight/>
    )
}

