import React, {useEffect, useState} from 'react'
import {HomeIcon} from '@heroicons/react/20/solid'
import {classNames} from "../utils";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Toolbar({actionsView = []}) {
    const {t} = useTranslation()
    const [breadcrumbs, setBreadcrumbs] = useState([])
    const location = useLocation()
    //const actionsView = useSelector(actionsViewSelector)

    useEffect(() => {
        switch (location?.pathname) {
            case "/u/dashboard":
                setBreadcrumbs([
                    {name: t('home'), href: '/u/dashboard', current: true},
                ])
                break;
            case "/u/payables/import":
                setBreadcrumbs([
                    {name: t('payables'), href: '/u/payables', current: false},
                    {
                        name: t('import'),
                        href: '/u/payables/import',
                        current: true
                    },
                ])
                break;
            case "/u/receivables/import":
                setBreadcrumbs([
                    {name: t('receivables'), href: '/u/receivables', current: false},
                    {
                        name: t('import'),
                        href: '/u/receivables/import',
                        current: true
                    },
                ])
                break;
            case "/u/organization":
                setBreadcrumbs([
                    {name: t('creditCheck'), href: '/u/organization', current: true},
                ])
                break;
            case "/u/financial-analytics":
                setBreadcrumbs([
                    {name: t('financialAnalytics'), href: '/u/financial-analytics', current: true},
                ])
                break;
            case "/u/financing":
                setBreadcrumbs([
                    {name: t('financing'), href: '/u/financing', current: true},
                ])
                break;
            case "/u/financing/factoring":
                setBreadcrumbs([
                    {name: t('financing'), href: '/u/financing', current: false},
                    {
                        name: t('newFinancingRequest'),
                        href: '/u/financing/factoring',
                        current: true
                    },
                ])
                break;
            case "/u/financing/reverse-factoring":
                setBreadcrumbs([
                    {name: t('financing'), href: '/u/financing', current: false},
                    {
                        name: t('newFinancingRequest'),
                        href: '/u/financing/reverse-factoring',
                        current: true
                    },
                ])
                break;
            /*case "/u/account":
                setBreadcrumbs([{name: 'Settings', href: '/u/account', current: true},])
                break;*/
            default:
                const _s = location?.pathname.split('/'), _last = _s[_s.length - 1], _pretty = _last.replace(/-/g, '-')
                setBreadcrumbs([{name: t(_pretty), href: location?.pathname, current: true},])
                break;
        }
    }, [location?.pathname])

    return (
        <div
            className="border-b border-b-gray-900/5 bg-white hidden">
            <div
                className="mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8 py-4">
                <div className="px-6 lg:flex-auto lg:px-0 sm:flex sm:items-center sm:justify-between">
                    <nav className="flex" aria-label={t('breadcrumbs')}>
                        <ol role="list" className="flex items-center space-x-4">
                            <li>
                                <div>
                                    <Link to="/u/" className="text-gray-400 hover:text-gray-500">
                                        <HomeIcon className="flex-shrink-0 h-4 w-4" aria-hidden="true"/>
                                        <span className="sr-only">{t('home')}</span>
                                    </Link>
                                </div>
                            </li>
                            {breadcrumbs.map(page => (<li key={page.name}>
                                <div className="flex items-center">
                                    <svg
                                        className="flex-shrink-0 h-5 w-5 text-gray-300"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        aria-hidden="true"
                                    >
                                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z"/>
                                    </svg>
                                    {page.current ? (
                                        <span
                                            className="ml-4 text-xs font-medium text-gray-500 cursor-default"
                                            aria-current="page"
                                        >
                                                {page.name}
                                            </span>
                                    ) : (
                                        <Link
                                            to={page.href}
                                            className="ml-4 text-xs font-medium text-gray-500 hover:text-gray-700"
                                        >
                                            {page.name}
                                        </Link>
                                    )}
                                </div>
                            </li>))}
                        </ol>
                    </nav>
                    {actionsView?.length > 0 && (
                        <div className="mt-4 sm:mt-0 sm:ml-4 flex sm:block justify-end">
                            {actionsView.map((action, index) => (<button
                                key={action.name}
                                type="button"
                                onClick={action.onClick}
                                className={classNames(
                                    "inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-xs font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500",
                                    action.primary ? "text-white bg-primary-600 hover:bg-primary-700" : "text-gray-700 bg-white hover:bg-gray-50",
                                    index === 0 ? "ml-3 sm:ml-0" : "sm:ml-3")
                                }
                            >
                                {action.icon && (
                                    <action.icon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true"/>)}
                                {action.name}
                            </button>))}
                            {/*<button
                                type="button"
                                className="order-1 ml-3 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:order-0 sm:ml-0"
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:order-1 sm:ml-3"
                            >
                                Create
                            </button>*/}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
