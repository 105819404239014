import {useTranslation} from "react-i18next";
import React, {useState} from 'react'
import {classNames} from "../../utils";
import {ExclamationCircleIcon} from "@heroicons/react/20/solid";
import {Link, useNavigate} from "react-router-dom";
import TailSpinner from "../../components/tailSpinner";
import {INVEST_API} from "../../config";

const inputName = "tax"

function onPrevClick(data, {setData, nextState, setNextState, prevState, setPrevState, setError}) {
    setData(data?.taxNo ? {taxNo: data?.taxNo} : null)
    setPrevState({
        ...prevState,
        capture: null,
    })
    setNextState({
        ...nextState,
        capture: onNextClick,
        loading: false,
    })
}


function onNextClick(data, {setData, nextState, setNextState, prevState, setPrevState, setError}) {
    //console.log("TRAP CLICK", data)


    /*setTimeout(() => {
        setNextState({
            ...nextState,
            capture: null,
            loading: false,
        })
    }, 1000)*/
}

export default function Tax({data, setData, nextState, prevState, error, setError}) {
    const navigate = useNavigate();
    const {t} = useTranslation()

    const [loading, setLoading] = useState(false)

    function onSubmit(e) {
        e.preventDefault();

        if (loading) return

        if (!data.taxNo) {
            setError("Please enter tax number")
            return
        }

        if (data.oid) {
            navigate("/quote/" + nextState.nextStepName)
            return
        }

        setLoading(true)

        fetch(INVEST_API + 'companies?tax_no=' + encodeURIComponent(data.taxNo), {
            method: 'get',
            headers: {
                'Accept': 'application/json, text/plain, */*',
            },
        }).then(res => {
            return res.json();
        }).then(res => {
            const companyData = {};
            if (typeof res === "object") {
                companyData["oid"] = res.Id;
                companyData["name"] = res.name;
                companyData["taxNo"] = res.taxNo;
                companyData["vatNo"] = res.vatNo;

                companyData["businessCategoryCode"] = res.businessCategoryCode;
                companyData["registrationNo"] = res.registrationNo;
                companyData["isVatLiable"] = res.vatLiable === true;

                if (res.hasOwnProperty("address")) {
                    companyData["address1"] = res.address["streetAndNumber"];
                    companyData["postCode"] = res.address["postCode"];
                    companyData["city"] = res.address["city"];

                    if (res.address["country"] === "SLOVENIJA") {
                        companyData["country"] = {
                            name: "Slovenia",
                            code: "SI",
                        };
                    }

                    // todo: add country
                }
                setData(companyData)
            } else setData({...data, oid: "notFound"})
        }).catch(err => {
            //this.setState({error: window.trans('company-vat-not-found'), fetching: false});
            console.error(err)
            setData({...data, oid: "notFound"})
        }).finally(() => {
            setLoading(false)
        })
    }

    function clearData() {
        setData({taxNo: data?.taxNo || ""})
    }

    return (
        <form onSubmit={onSubmit} className="w-full max-w-sm">
            {data?.oid ? (
                <div className="">
                    <div>
                        <h3 className="text-base font-semibold leading-6 text-white">Company details</h3>
                        <p className="mt-1 text-sm text-gray-50">Check your company details and then continue.</p>
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-6">
                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-white">
                                Company name
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    defaultValue={data?.name || ""}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-6">
                            <label htmlFor="address" className="block text-sm font-medium leading-6 text-white">
                                Address
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="address"
                                    id="address"
                                    defaultValue={data?.address1 || ""}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="postCode" className="block text-sm font-medium leading-6 text-white">
                                Post code
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="postCode"
                                    id="postCode"
                                    defaultValue={data?.postCode || ""}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="city" className="block text-sm font-medium leading-6 text-white">
                                City
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    defaultValue={data?.city || ""}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <label htmlFor={inputName} className="block text-sm font-medium leading-6 text-white">
                        Tax number
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                        <input
                            type="text"
                            name={inputName}
                            id={inputName}
                            className={classNames(
                                "block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6",
                                error ? "pr-10 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500" : "text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-primary-600"
                            )}
                            placeholder="SI12345678"
                            value={data?.taxNo || ""}
                            onChange={e => setData({taxNo: e.target.value})}
                            aria-invalid={!!error}
                            aria-describedby={!!error ? inputName + "-error" : undefined}
                            required
                        />
                        {error && (
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                            </div>
                        )}
                    </div>
                    {error && (
                        <p className="mt-2 text-sm text-red-600" id={inputName + "-error"}>
                            {error}
                        </p>
                    )}
                </>
            )}

            <div className="mt-8 flex space-x-4">
                {data?.oid ? (
                    <button
                        type="button"
                        onClick={clearData}
                        className="rounded-md bg-white/10 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                    >
                        Back
                    </button>
                ) : (
                    <Link
                        to={"/quote/" + prevState.prevStepName}
                        className="rounded-md bg-white/10 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                    >
                        Back
                    </Link>
                )}
                <button
                    type="submit"
                    disabled={!data || loading}
                    className={classNames(
                        "inline-flex rounded-md bg-primary-700 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-700",
                        !data || loading ? "opacity-75 cursor-not-allowed" : "",
                    )}
                >
                    {loading && <TailSpinner/>}
                    {loading ? "Loading..." : "Next"}
                </button>
            </div>
        </form>
    )
}
